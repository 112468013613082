import React, { useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Text,
} from '@chakra-ui/react';
import { FileExcelValid, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { importPhoneCampagin, useUpdatePhone, usecreatePhone } from 'services/ips';

const FormImportPhone = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createPhone = usecreatePhone();
  const updatePhone = useUpdatePhone();
  const inputImportRef = useRef();
  const [file, setFile] = useState();

  const handleImportPhone = async e => {
    if (e?.target?.files?.[0]) {
      const phoneFile = e.target.files[0];
      const extensionFile = phoneFile?.name?.split('.')?.pop();
      if (FileExcelValid.includes(extensionFile)) {
        setFile(phoneFile);
        return;
      }
      toast.showMessageSuccess('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
    }
  };

  const handleSubmit = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('phoneFile', file);
      formData.append('campaign_id', companyDetail?._id);
      await importPhoneCampagin(formData)
        .then(() => {
          toast.showMessageSuccess('Tải lên số điện thoại thành công');
          refetch();
          onClose(ModalType.Import);
        })
        .catch(() => {
          toast.showMessageError('Tải lên số điện thoại thất bại');
        });
        return;s
    }
    toast.showMessageError('Vui lòng chọn file');
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Import);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Import số điện thoại</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Box display={'flex'} alignItems={'center'} gap={5}>
              <Text>File:</Text>
              <Text>{file?.name}</Text>
              <Button
                onClick={() => {
                  inputImportRef?.current?.click();
                }}
              >
                Chọn file
              </Button>
              <input type="file" hidden ref={inputImportRef} onChange={handleImportPhone} />
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Import);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createPhone.isPending || updatePhone.isPending} onClick={handleSubmit}>
              Tải lên
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormImportPhone;
