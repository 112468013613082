import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Flex, FormControl, FormLabel, Heading, IconButton, SimpleGrid, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import { useHistory } from 'react-router-dom';
import IconBox from 'components/Icons/IconBox';
import { CookieStorage } from 'utils/cookie-storage';
import { FiPhoneCall, FiPhoneMissed } from 'react-icons/fi';
import { HiRectangleGroup } from 'react-icons/hi2';
import { MdOutlineDevices, MdGroups2, MdAttachMoney, MdOutlineOnlinePrediction, MdWifiCalling3, MdCampaign, MdContactPhone } from 'react-icons/md';
import { useUserState } from 'context/UserContext';
import { useQueryGetStatisticReportDashboard } from 'services/statistic';
import { FORMAT_DATE_SHORT, Roles } from 'constants/common';
import { formatCurrency } from 'utils/helpers';
import { Select } from 'chakra-react-select';
import { mappingOptionSelect } from 'utils/mapping';
import DatePicker from 'components/DatePicker/DatePicker';
import { FaWallet } from 'react-icons/fa';
import { useQueryGetCompany } from 'services/company';
import moment from 'moment';
import { useQueryGetExtensions } from 'services/ips';
import { useChangeStatusExtensionMutation, useQueryInforExtension } from 'services/user';
import { socket } from 'config/socket';
import { BsDot, BsFillPhoneVibrateFill } from 'react-icons/bs';
import InputController from 'components/Form/InputController';
import { useForm } from 'react-hook-form';
import { CallManual } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallManualMutation } from 'services/hotline-manual';
import { toast } from 'components/Toast';

const initFilter = {
  companyId: null,
  groupId: null,
  groupId: null,
  extension: null,
  type: null,
};

export default function Dashboard() {
  const textColor = useColorModeValue('gray.700', 'white');
  const isLoggedIn = CookieStorage.isAuthenticated();
  const history = useHistory();
  const sidebarBg = 'hsl(208.33deg 100% 96.31% / 80%)';
  const { userInfo } = useUserState();
  const [hotlineFilter, setHotlineFilter] = useState(initFilter);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
  });
  const [searchTitle, setSearchTitle] = useState('');

  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });
  const { data: statistic, refetch: refetchStatistic } = useQueryGetStatisticReportDashboard({ ...filter }, { enabled: isLoggedIn ? true : false });
  const { refetch } = useQueryGetExtensions({}, { enabled: isLoggedIn && (userInfo?.role !== Roles.STAFF && userInfo?.role !== Roles.LEADER) });
  const changeStatusExtension = useChangeStatusExtensionMutation();
  const callManualMutation = useCallManualMutation();

  const handleSearch = () => {
    setFilter({
      ...filter,
      // searchKeyword: searchTitle,
      ...(hotlineFilter?.groupId && { companyId: hotlineFilter?.groupId?.value }),
      ...(filterDate?.start && { date: moment(filterDate?.start).format(FORMAT_DATE_SHORT) }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setHotlineFilter(initFilter);
    setSearchTitle('');
    setFilterDate({
      start: null,
    });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
    if (userInfo?.role !== Roles.STAFF && userInfo?.role !== Roles.LEADER) {
      refetch();
    }
  }, [isLoggedIn, history]);

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const handleChangeStatus = status => {
    changeStatusExtension.mutate(
      {
        status: status,
      },
      {
        onSuccess: res => {
          if(userInfo?.role === Roles.STAFF) refetchProfile();
          toast.showMessageSuccess('Thay đổi trạng thái máy gọi thành công.');
        },
      }
    );
  };
  
  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetchStatistic?.();
  };

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(CallManual),
  });
  const { data, refetch: refetchProfile } = useQueryInforExtension({}, { enabled: userInfo?.role === Roles.STAFF });
  const [callInfo, setCallInfo] = useState({
    startTime: 0,
    endTime: 0,
  });
  const [callDuration, setCallDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const endCall = () => {
    if(userInfo?.role === Roles.STAFF) refetchProfile();
    const endTime = Date.now();
    setCallInfo(prevState => ({ ...prevState, endTime }));
    setCallDuration({
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  };

  const getColor = (enabled, is_logged_in, is_oncall) => {
    if (is_oncall) return 'orange';
    if (!enabled) return 'gray';
    if (enabled) return '#CEDF9F';
    if (enabled && !is_logged_in) return 'red';
    if (enabled && is_logged_in) return 'green';
  };

  const onSubmit = async values => {
    callManualMutation.mutate(
      {
        phone: values?.phone,
      },
      {
        onSuccess: () => handleSuccess(`Gọi thủ công thành công`),
      }
    );
  };

  useEffect(() => {
    let interval = 0;
    socket.on('send_start_call', res => {
      if (userInfo?._id === res.user) {
        if(userInfo?.role === Roles.STAFF) refetchProfile();
        const startTime = Date.now();
        setCallInfo({ startTime, endTime: 0 });
        interval = setInterval(() => {
          const now = Date.now();
          const elapsedTime = now - startTime;

          const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
          const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

          setCallDuration({ hours, minutes, seconds });
        }, 1000);
      }
    });
    socket.on('send_end_call', res => {
      clearInterval(interval);
      endCall();
    });

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
        <Box display={{ xl: 'block' }}>
          <Box
            backdropFilter="blur(21px)"
            boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
            bg={sidebarBg}
            borderRadius={'10px'}
            py={'32px'}
            px={'16px'}
          >
            <Card borderRadius="16px" bgColor="white">
              <Heading fontWeight={700} fontSize={18} color={textColor}>
                Chào mừng đến với SMS VOICE OTP! {userInfo?.username}
              </Heading>
            </Card>
            {userInfo?.role === Roles.STAFF && (
              <Card p="16px" my="24px" bg="#fff" w={'100%'}>
                <Text display={'flex'} gap={4} alignItems={'center'} flexWrap={'wrap'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Máy gọi
                  </Text>
                  <MdContactPhone size={30} />
                  <Flex
                    ml={2}
                    flexDirection="row"
                    gap={4}
                    alignItems={'end'}
                    bg="blue.500"
                    color="white"
                    rounded={8}
                    w="fit-content"
                    px={4}
                    py={3}
                  >
                    <Flex mr={5} flexDirection={'column'}>
                      <Text mt={5}>Máy gọi: {data?.data?.extension}</Text>
                      <Flex mt={4} gap={2} alignItems={'center'}>
                        <Text>Thời lượng:</Text>
                        <Text color="orange">
                          {callDuration.hours}:{callDuration.minutes}:{callDuration.seconds}
                        </Text>
                      </Flex>
                      <Text display={'flex'} alignItems={'center'} gap={2}>
                        <Text minW={'100px'}>Trạng thái:</Text>{' '}
                        <BsDot size={50} color={getColor(data?.data?.enabled, data?.data?.is_logged_in, data?.data?.is_oncall)} />
                      </Text>
                    </Flex>
                    <Flex flexDirection={'column'} justifyContent={'center'} gap={1}>
                      <IconButton bg="transparent" onClick={() => handleChangeStatus(false)}>
                        <FiPhoneMissed cursor="pointer" size={20} />
                      </IconButton>
                      <Text>Thiết lập trạng thái bận</Text>
                    </Flex>
                    <Flex flexDirection={'column'} justifyContent={'center'} gap={1}>
                      <IconButton bg="transparent" onClick={() => handleChangeStatus(true)}>
                        <BsFillPhoneVibrateFill cursor="pointer" size={20} />
                      </IconButton>
                      <Text>Thiết lập trạng thái trực tuyến</Text>
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" height={'100%'} justifyContent={'start'} alignItems={'start'}>
                    <Text fontWeight={600} pl={5}>
                      Chú thích:
                    </Text>
                    <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                      <BsDot size={50} color={'#CEDF9F'} />
                      <Text>Trạng thái đã sẵn sàng</Text>
                    </Flex>
                    <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                      <BsDot size={50} color={'green'} />
                      <Text>Trạng thái trực tuyến đã kết nối</Text>
                    </Flex>
                    <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                      <BsDot color={'red'} size={50} />
                      <Text>Trạng thái trực tuyến chưa kết nối</Text>
                    </Flex>
                    <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                      <BsDot color={'orange'} size={50} />
                      <Text>Trạng thái đang có cuộc gọi</Text>
                    </Flex>
                    <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                      <BsDot color={'gray'} size={50} />
                      <Text>Trạng thái bận</Text>
                    </Flex>
                  </Flex>
                </Text>
                <Flex boxShadow="lg" rounded="md" p={4} justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'10px'}>
                  <Stack>
                    <Flex gap={'20px'} alignItems={'end'}>
                      <InputController type="number" control={control} name="phone" label="Số điện thoại" />
                      <Button
                        colorScheme="blue"
                        ml={3}
                        // isLoading={createHotlineAutodialerCampaignMutation.isPending}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Gọi
                      </Button>
                    </Flex>
                  </Stack>
                </Flex>
              </Card>
            )}
            <Card bgColor="white" mt={5}>
              <Flex flexWrap={'wrap'} gap={'20px'}>
                {/* <FormControl w={'300px'}>
                  <FormLabel>Tìm kiếm</FormLabel>
                  <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                </FormControl> */}
                {userInfo?.role === Roles.SUPER_ADMIN && (
                  <FormControl w={'300px'}>
                    <FormLabel>Chi nhánh</FormLabel>
                    <Select
                      isClearable
                      menuShouldBlockScroll
                      value={hotlineFilter?.groupId}
                      onChange={e => {
                        setHotlineFilter({
                          ...hotlineFilter,
                          groupId: e,
                        });
                      }}
                      options={mappingOptionSelect(company?.data, 'name', '_id')}
                    ></Select>
                  </FormControl>
                )}
                {/* {userInfo?.role !== Roles.STAFF && (
                  <FormControl w={'300px'}>
                    <FormLabel>Máy chủ</FormLabel>
                    <Select
                      isClearable
                      menuShouldBlockScroll
                      value={hotlineFilter?.extension}
                      onChange={e => {
                        setHotlineFilter({
                          ...hotlineFilter,
                          extension: e,
                        });
                      }}
                      options={mappingOptionSelect(extensions?.data, 'extension', '_id')}
                    ></Select>
                  </FormControl>
                )} */}
                {/* <FormControl w={'300px'}>
                  <FormLabel>Thể loại</FormLabel>
                  <Select
                    isClearable
                    menuShouldBlockScroll
                    value={hotlineFilter?.type}
                    onChange={e => {
                      setHotlineFilter({
                        ...hotlineFilter,
                        type: e,
                      });
                    }}
                    options={[
                      {
                        label: 'Gọi thủ công',
                        value: 'CALL_MANUAL',
                      },
                      {
                        label: 'Gọi trực tuyến',
                        value: 'AUTO_VOICE_ANSWER',
                      },
                      {
                        label: 'Gọi voice AI',
                        value: 'AUTO_VOICE_FILE',
                      },
                      {
                        label: 'Gọi voice văn bản',
                        value: 'AUTO_VOICE_TEMPALTE',
                      },
                    ]}
                  ></Select>
                </FormControl> */}
                <Flex alignItems={'center'} gap={5}>
                  <FormControl display="flex" flexDirection="column" maxW="300px" mr="12px">
                    <FormLabel marginRight={0} fontSize={14} isTruncated>
                      Ngày
                    </FormLabel>
                    <DatePicker
                      styleInput={{
                        minWidth: '300px',
                      }}
                      selectedDate={filterDate.start}
                      onChange={date => onChangeDate('start')(date)}
                    />
                  </FormControl>
                  <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                    <Text fontSize="md" fontWeight="normal" cursor="pointer">
                      Cài lại
                    </Text>
                  </Button>
                  <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                    <Text fontSize="md" fontWeight="normal" cursor="pointer">
                      Tìm kiếm
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </Card>
            <SimpleGrid columns={{ sm: 1, md: 3, xl: 3 }} spacing="24px" mb="20px" mt="20px">
              {userInfo?.role === Roles.SUPER_ADMIN && (
                <StatisticalData
                  title="Tổng số chi nhánh"
                  value={statistic?.data?.countCompany || 0}
                  icon={
                    <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                      <HiRectangleGroup size={'45'} />
                    </IconBox>
                  }
                />
              )}
              {userInfo?.role !== Roles.STAFF && (
                <StatisticalData
                  title="Tổng số tổng đài viên"
                  value={statistic?.data?.countManager || 0}
                  icon={
                    <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                      <MdGroups2 size={'45'} />
                    </IconBox>
                  }
                />
              )}
              {[Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) && (
                <StatisticalData
                  title="Tổng máy gọi"
                  value={statistic?.data?.countExtension || 0}
                  icon={
                    <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                      <MdOutlineDevices size={'45'} />
                    </IconBox>
                  }
                />
              )}
              {[Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) && (
                <StatisticalData
                  title="Số máy gọi đang trực tuyến"
                  value={statistic?.data?.countExtensionOnline || 0}
                  icon={
                    <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                      <MdOutlineOnlinePrediction size={'45'} />
                    </IconBox>
                  }
                />
              )}
              {[Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) && (
                <StatisticalData
                  title="Số máy gọi đang gọi"
                  value={statistic?.data?.countExtensionIsCall || 0}
                  icon={
                    <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                      <MdWifiCalling3 size={'45'} />
                    </IconBox>
                  }
                />
              )}
              {[Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) && (
                <StatisticalData
                  title="Tổng số nhiệm vụ"
                  value={statistic?.data?.countCampaign || 0}
                  icon={
                    <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                      <MdCampaign size={'45'} />
                    </IconBox>
                  }
                />
              )}
              {[Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) && (
                <StatisticalData
                  title="Số dư"
                  value={statistic?.data?.balance || 0}
                  icon={
                    <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                      <FaWallet size={'45'} />
                    </IconBox>
                  }
                />
              )}
              {/* <StatisticalData
                title="Tổng số điện thoại"
                value={statistic?.data?.countPhone || 0}
                icon={
                  <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                    <FiPhone size={'45'} />
                  </IconBox>
                }
              /> */}
              <StatisticalData
                title="Tổng số cuộc gọi thành công"
                value={statistic?.data?.countPhoneSuccess || 0}
                icon={
                  <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                    <FiPhoneCall size={'45'} />
                  </IconBox>
                }
              />
              <StatisticalData
                title="Tổng số cuộc gọi bị hủy"
                value={statistic?.data?.countPhoneCancel || 0}
                icon={
                  <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                    <FiPhoneMissed size={'45'} />
                  </IconBox>
                }
              />
              {[Roles.ADMIN, Roles.SUPER_ADMIN].includes(userInfo?.role) && (
                <StatisticalData
                  title="Cước phí trong ngày"
                  value={statistic?.data?.feeToday > 0 ? formatCurrency(statistic?.data?.feeToday) : 0}
                  icon={
                    <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} mr={'16px'}>
                      <MdAttachMoney size={'45'} />
                    </IconBox>
                  }
                />
              )}
            </SimpleGrid>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

const StatisticalData = props => {
  const { title, value, icon } = props;
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Card minH="125px" bgColor="white" borderRadius="8px">
      <Text fontSize="xl" color={textColor} fontWeight="bold">
        {title}
      </Text>
      <Flex direction="row" mt={'16px'} ml={'32px'}>
        <Center>
          {icon}
          <Text fontSize="5xl" color={textColor} fontWeight="900">
            {value}
          </Text>
        </Center>
      </Flex>
    </Card>
  );
};
