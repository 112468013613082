import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType, ROOT_API } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { useExportHistoriesHotlineAutodialer, useQueryGetHistoriesHotlineAutodialer } from 'services/ips';
import DetailModal from './components/DetailModal';
import { useParams } from 'react-router-dom';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import axios from 'axios';

const initFilter = {
  companyId: null,
  status: null,
  extension: null,
};

function HistoryHotlineAutodialer() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [hotlineFilter, setHotlineFilter] = useState(initFilter);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const { id } = useParams();
  const exportHistoriesHotlineAutodialer = useExportHistoriesHotlineAutodialer(
    {
      campaign_id: id,
    },
    { responseType: 'arraybuffer' }
  );

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isDetailModalOpen, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Detail]: onOpenDetailModal,
    }),
    [onOpenDetailModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Detail]: onCloseDetailModal,
    }),
    [onCloseDetailModal]
  );

  const { data: hotlines, refetch } = useQueryGetHistoriesHotlineAutodialer(
    { ...filter, searchKeyword: filter.searchKeyword, campaign_id: id },
    { enabled: isLoggedIn && !!id }
  );

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      ...(hotlineFilter?.companyId && { companyId: hotlineFilter?.companyId?.value }),
      ...(hotlineFilter?.status && { status: hotlineFilter?.status?.value }),
      ...(hotlineFilter?.extension && { campaign_id: hotlineFilter?.extension?.value }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setHotlineFilter(initFilter);
    setSearchTitle('');
  };

  const onDownloadTemplate = async () => {
    if (id) {
      const res = await axios.get(ROOT_API + '/api/v1/hotline/export-history/auto-dialer' + '?campaign_id=' + id, {
        headers: { 'Content-Type': 'application/json', xToken: CookieStorage.getAccessToken() },
        responseType: 'arraybuffer',
      });
      if (res.status === 200) {
        downloadFile(res?.data, 'history-hotline-autodialer');
      }
    }
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử gọi trực tiếp
                </Text>
                <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onDownloadTemplate}>
                  Export dữ liệu
                </Button>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex direction={'column'} gap={'20px'} flexWrap={'wrap'}>
                    <Flex flexWrap={'wrap'} gap={'20px'}>
                      <FormControl w={'300px'}>
                        <FormLabel>Tìm kiếm</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl>
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={hotlines?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={hotlines?.pagination?.page + 1}
              pageLength={hotlines?.pagination?.pageSize}
              totalRecords={hotlines?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isDetailModalOpen && <DetailModal companyDetail={sizeEditing} isOpen={isDetailModalOpen} onClose={handleCloseModal} />}
    </Flex>
  );
}

export default HistoryHotlineAutodialer;
