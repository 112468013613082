import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { daysOfWeek, FileExcelValid, ModalType, RatioOption, Roles } from 'constants/common';
import { toast } from 'components/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectController from 'components/Form/SelectController';
import { useQueryGetCompany } from 'services/company';
import { CookieStorage } from 'utils/cookie-storage';
import { mappingOptionSelect } from 'utils/mapping';
import { useCreateHotlineAutodialerCampaignMutation, useUpdateCampaignMutation } from 'services/campaign';
import { useUserState } from 'context/UserContext';
import { HotlineAutodialerFormValidateAdminV2, HotlineAutodialerFormValidateV2 } from 'utils/validation';
import { isEmpty } from 'lodash';
import { importPhoneHotlineList } from 'services/ips';
import DatePickerController from 'components/Form/DatePickerController';
import { convertToDateTimestamp, convertToUTCDateString, formatTime } from 'utils/helpers';
import { useQueryGroupListAssign } from 'services/group';

const FormCampaign = ({ itemEdit, isOpen, onClose, refetch }) => {
  const { userInfo } = useUserState();
  const cancelRef = React.useRef();
  const createHotlineAutodialerCampaignMutation = useCreateHotlineAutodialerCampaignMutation();
  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(userInfo?.role === Roles.SUPER_ADMIN ? HotlineAutodialerFormValidateV2 : HotlineAutodialerFormValidateAdminV2),
  });
  const updateCampaignMutation = useUpdateCampaignMutation();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const inputImportRef = useRef();
  const [errorFile, setErrorFile] = useState('');
  const [file, setFile] = useState(null);
  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });
  const { data: templates } = useQueryGroupListAssign(
    { company_id: itemEdit?.company?._id || watch('company_id')?.value || userInfo?.company },
    { enabled: isLoggedIn && (!!itemEdit?.company?._id || !!watch('company_id')?.value || !!userInfo?.company) }
  );
  
  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    const startTime = values?.startDate && convertToDateTimestamp(convertToUTCDateString(values?.startDate));
    const endTime = values?.endDate && convertToDateTimestamp(convertToUTCDateString(values?.endDate));
    const listDaySelected = watch('dayofweek');
    const payloadTime = formatTime(startTime, endTime, listDaySelected);
    if (isEmpty(itemEdit)) {
      if (!file) {
        setErrorFile('Vui lòng chọn file số điện thoại');
        return;
      }
      createHotlineAutodialerCampaignMutation.mutate(
        {
          campaign_name: values?.campaign_name,
          concurrent_calc: Number(values?.concurrent_calc),
          company_id: values?.company_id?.value || userInfo?.company,
          // schedules: payloadTime,
          ...(values?.dayofweek && values?.dayofweek?.length > 0 && { schedules: payloadTime }),
          group_id: values?.group_id?.value,
        },
        {
          onSuccess: async res => {
            if (file && res.data?._id) {
              const formData = new FormData();
              formData.append('phoneFile', file);
              formData.append('list_id', res.data?._id);
              await importPhoneHotlineList(formData)
                .then(() => {
                  console.log('Success');
                })
                .catch(() => {
                  toast.showMessageError('Tải lên số điện thoại thất bại');
                  return;
                });
            }
            handleSuccess(`Tạo chiến dịch gọi trực tiếp thành công`);
          },
        }
      );
      return;
    }
    updateCampaignMutation.mutate(
      {
        campaign_name: values?.campaign_name,
        campaign_id: itemEdit?._id,
        concurrent_calc: Number(values?.concurrent_calc),
        schedules: payloadTime,
        group_id: values?.group_id?.value,
      },
      {
        onSuccess: () => handleSuccess(`Cập nhật chiến dịch gọi trực tiếp thành công`),
      }
    );
  };

  const handleImportPhone = async e => {
    if (e?.target?.files?.[0]) {
      const phoneFile = e.target.files[0];
      const extensionFile = phoneFile?.name?.split('.')?.pop();
      if (FileExcelValid.includes(extensionFile)) {
        setFile(phoneFile);
        setErrorFile('');
        return;
      }
      setFile(null);
      setErrorFile('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
      return;
    }
    setFile(null);
    setErrorFile('Vui lòng choọn file số điện thoại');
  };

  useEffect(() => {
    let matchingDays = [];
    let startTime = null;
    let endTime = null;
    if (itemEdit?.schedules?.[0]) {
      matchingDays = daysOfWeek.filter(day => {
        const startTimeKey = `${day.value}_start_time`;
        const endTimeKey = `${day.value}_end_time`;
        if (itemEdit?.schedules?.[0]?.[startTimeKey] !== 28800000000000) {
          startTime = itemEdit?.schedules?.[0]?.[startTimeKey];
        }
        if (itemEdit?.schedules?.[0]?.[endTimeKey] !== 75600000000000) {
          endTime = itemEdit?.schedules?.[0]?.[endTimeKey];
        }
        return itemEdit?.schedules?.[0]?.[startTimeKey] !== 28800000000000 || itemEdit?.schedules?.[0]?.[endTimeKey] !== 75600000000000;
      });
    }
    if (!isEmpty(itemEdit)) {
      const groupSelected = templates?.data?.find(item => item?._id === itemEdit?.group?._id);
      reset({
        campaign_name: itemEdit?.campaign_name,
        concurrent_calc: itemEdit?.concurrent_calc,
        company_id: {
          label: itemEdit?.company?.name,
          value: itemEdit?._id,
        },
        group_id: {
          label: groupSelected?.name,
          value: groupSelected?._id,
        },
        dayofweek: matchingDays,
        ...(startTime && { startDate: new Date(startTime) }),
        ...(endTime && { endDate: new Date(endTime) }),
      });
    }
  }, [itemEdit, templates?.data]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Tạo thông tin chiến dịch</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="campaign_name"
              label="Tên chiến dịch"
              styleContainer={{
                marginBottom: '12px',
              }}
            />
            <InputController type="number" control={control} isRequired name="concurrent_calc" label="Số lượng cuộc gọi đồng thời" />
            {/* <SelectController
              control={control}
              name="ratio"
              label="Số lượng cuộc gọi đổ về"
              options={RatioOption}
              isRequired
              styleContainer={{ pt: 4 }}
            /> */}
            {userInfo?.role === Roles.SUPER_ADMIN && (
              <SelectController
                isDisabled={!isEmpty(itemEdit)}
                control={control}
                name="company_id"
                label="Company"
                options={mappingOptionSelect(company?.data, 'name', '_id')}
                isRequired
                styleContainer={{ pt: 4 }}
              />
            )}
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              styleContainer={{
                marginTop: '10px',
              }}
              isRequired
              label="Nhóm"
              name="group_id"
              placeholder="Chọn nhóm"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(templates?.data, 'name', '_id')}
            />
            <SelectController
              styleContainer={{
                marginTop: '10px',
              }}
              isRequired
              isMulti
              label="Ngày hiệu lực"
              name="dayofweek"
              placeholder="Chọn ngày hiệu lực"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={daysOfWeek}
            />
            <Flex gap={5}>
              <DatePickerController
                styleContainer={{
                  marginTop: '10px',
                  width: '100%',
                }}
                isRequired
                isMulti
                label="Thời gian bắt đầu"
                name="startDate"
                placeholder="Chọn thời gian bắt đầu"
                control={control}
                controlClassName={'!min-h-[39px]'}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
              <DatePickerController
                styleContainer={{
                  marginTop: '10px',
                  width: '100%',
                }}
                isRequired
                isMulti
                label="Thời gian kết thúc"
                name="endDate"
                placeholder="Chọn thời gian kết thúc"
                control={control}
                controlClassName={'!min-h-[39px]'}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
            </Flex>
            {isEmpty(itemEdit) && (
              <>
                <Box mt={5} display={'flex'} alignItems={'center'} gap={5}>
                  <Text>File số điện thoại:</Text>
                  <Text>{file?.name}</Text>
                  <Button
                    onClick={() => {
                      inputImportRef?.current?.click();
                    }}
                  >
                    Chọn file
                  </Button>
                  <input type="file" hidden ref={inputImportRef} onChange={handleImportPhone} />
                </Box>
                {errorFile && (
                  <Text color="red.500" fontSize={12} mt={2}>
                    {errorFile}
                  </Text>
                )}
              </>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createHotlineAutodialerCampaignMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {isEmpty(itemEdit) ? 'Tạo' : 'Chỉnh sửa'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormCampaign;
