import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import ModalShowDetailMember from './components/ModalShowDetailMember';
import FormPhone from './components/FormPhone';
import FormAssignPhoneUser from './components/FormAssignPhoneUser';
import { useQueryGetGroups } from 'services/group';

function Group() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [searchTitle, setSearchTitle] = useState('');

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isCreateModalAssignOpen, onOpen: onOpenCreateAssignModal, onClose: onCloseCreateAssignModal } = useDisclosure();
  const { isOpen: isFormFormCreateOpen, onOpen: onOpenFormCreate, onClose: onCloseFormCreateModal } = useDisclosure();
  const {
    isOpen: isOpenViewMemberDetailModal,
    onOpen: onOpenViewMemberDetailModal,
    onClose: onCloseViewMemberDetalModal,
  } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Preview]: onOpenViewMemberDetailModal,
      [ModalType.Add]: onOpenFormCreate,
      [ModalType.Assign]: onOpenCreateAssignModal,
    }),
    [onOpenFormCreate, onOpenViewMemberDetailModal, onOpenCreateAssignModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseFormCreateModal,
      [ModalType.Assign]: onCloseCreateAssignModal,
      [ModalType.Preview]: onCloseViewMemberDetalModal,
    }),
    [onCloseFormCreateModal, onCloseCreateAssignModal, onCloseViewMemberDetalModal]
  );

  const { data: groups, refetch } = useQueryGetGroups({ ...filter }, { enabled: isLoggedIn });

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setSearchTitle('');
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Quản lý nhóm
                </Text>
                <Flex alignItems={'center'} gap={4}>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenFormCreate}>
                    Tạo nhóm
                  </Button>
                </Flex>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm theo tên nhóm</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={groups?.data || []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={groups?.pagination?.page}
              pageLength={groups?.pagination?.pageSize}
              totalRecords={groups?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isOpenViewMemberDetailModal && (
        <ModalShowDetailMember isOpen={isOpenViewMemberDetailModal} onClose={handleCloseModal} memberDetail={sizeEditing} />
      )}
      {isFormFormCreateOpen && (
        <FormPhone companyDetail={sizeEditing} isOpen={isFormFormCreateOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isCreateModalAssignOpen && (
        <FormAssignPhoneUser companyDetail={sizeEditing} isOpen={isCreateModalAssignOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
    </Flex>
  );
}

export default Group;
