import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Tooltip } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType, Roles } from 'constants/common';
import moment from 'moment';
import { toast } from 'components/Toast';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useActionCampaignMutation, useDeleteCampaignMutation } from 'services/campaign';
import { MdAssignmentTurnedIn, MdCall, MdOutlineHistory } from 'react-icons/md';
import { CiBoxList, CiImport } from 'react-icons/ci';
import { useHistory } from 'react-router-dom';
import { VscDebugStart } from 'react-icons/vsc';
import { FaRegStopCircle } from 'react-icons/fa';
import { useUserState } from 'context/UserContext';
import { useSendSmsMutation } from 'services/email-template';

const SizeTable = ({ categorysData, refetch, handleUpdateCategory }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteCampaignMutation = useDeleteCampaignMutation();
  const history = useHistory();
  const actionCampaignMutation = useActionCampaignMutation();
  const { userInfo } = useUserState();
  const sendSmsMutation = useSendSmsMutation();

  const handleDeleteSize = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    deleteCampaignMutation.mutate(
      { campaign_id: campaign?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleCall = async (campaign) => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn gửi sms cho chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    sendSmsMutation.mutate(
      {campaign_id: campaign._id},
      {
        onSuccess: () => {
          toast.showMessageSuccess('Gửi sms cho chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Gửi sms cho chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleStart = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn bắt đầu chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    actionCampaignMutation.mutate(
      { campaign_id: campaign?._id, type: 'start' },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Bắt đầu chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Bắt đầu chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleStop = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn dừng chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    actionCampaignMutation.mutate(
      { campaign_id: campaign?._id, type: 'stop' },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Dừng chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Dừng chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('campaign_name', {
        header: 'Tên',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <>
            <Flex alignItems="center" margin={'auto'}>
              {userInfo?.role !== Roles.LEADER && (
                <Tooltip label="Phân bổ" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Assign)}>
                    <MdAssignmentTurnedIn cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
              )}
              {([Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) || userInfo?._id === info?.row?.original?.createBy?._id || userInfo?._id === info?.row?.original?.user?._id) && (
                <>
                  <Tooltip label="Danh sách số điện thoại" aria-label="A tooltip" rounded={'6px'}>
                    <IconButton
                      bg="transparent"
                      onClick={() => {
                        history.push(`/admin/list-phone/${info?.row?.original?._id}`);
                      }}
                    >
                      <CiBoxList cursor="pointer" boxSize={4} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip label="Lịch sử" aria-label="A tooltip" rounded={'6px'}>
                    <IconButton
                      bg="transparent"
                      onClick={() => {
                        history.push(`/admin/histories-sms/${info?.row?.original?._id}`);
                      }}
                    >
                      <MdOutlineHistory cursor="pointer" boxSize={4} />
                    </IconButton>
                  </Tooltip>
                  {categorysData?.campaign_uuid && (
                    <>
                      <Tooltip label="Bắt đầu" aria-label="A tooltip" rounded={'6px'}>
                        <IconButton bg="transparent" onClick={() => handleStart(info?.row?.original)}>
                          <VscDebugStart cursor="pointer" boxSize={4} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip label="Dừng" aria-label="A tooltip" rounded={'6px'}>
                        <IconButton bg="transparent" onClick={() => handleStop(info?.row?.original)}>
                          <FaRegStopCircle cursor="pointer" boxSize={4} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip label="Gọi mẫu voice" aria-label="A tooltip" rounded={'6px'}>
                    <IconButton bg="transparent" onClick={() => handleCall(info?.row?.original)}>
                      <MdCall cursor="pointer" boxSize={4} />
                    </IconButton>
                  </Tooltip>
                  {([Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) || ([Roles.LEADER].includes(userInfo?.role) && userInfo?._id === info?.row?.original?.createBy?._id)) && (
                    <>
                      {/* <Tooltip label="Import số điện thoại" aria-label="A tooltip" rounded={'6px'}>
                        <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Import)}>
                          <CiImport cursor="pointer" boxSize={4} />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip label="Cập nhật" aria-label="A tooltip" rounded={'6px'}>
                        <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Add)}>
                          <EditIcon cursor="pointer" boxSize={4} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
                        <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original)}>
                          <DeleteIcon color="red" cursor="pointer" boxSize={4} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
            </Flex>
          </>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
