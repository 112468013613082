import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType, Roles } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { useListMemberMutation } from 'services/purchase-history';
import ModalShowDetailMember from './components/ModalShowDetailMember';
import AdjustCoinModal from './components/AdjustCoinModal';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import { useQueryGetQuotation } from 'services/quotation';
import FormQuotation from './components/FormQuotation';
import { useUserState } from 'context/UserContext';

function Quotation() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [idMember, setIdMember] = useState();
  const [searchTitle, setSearchTitle] = useState('');
  const [companyFilter, setCompanyFilter] = useState();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const {
    isOpen: isOpenViewMemberDetailModal,
    onOpen: onOpenViewMemberDetailModal,
    onClose: onCloseViewMemberDetalModal,
  } = useDisclosure();
  const { isOpen: isOpenAdjustCoinModal, onOpen: onOpenAdjustCoinModal, onClose: onCloseAdjustCoinModal } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Preview]: onOpenViewMemberDetailModal,
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.Adjust]: onOpenAdjustCoinModal,
    }),
    [onOpenCreateModal, onOpenViewMemberDetailModal, onOpenAdjustCoinModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.Preview]: onCloseViewMemberDetalModal,
      [ModalType.Adjust]: onCloseAdjustCoinModal,
    }),
    [onCloseCreateModal, onCloseAdjustCoinModal]
  );

  const { data: members, refetch } = useQueryGetQuotation({ ...filter, searchKeyword: filter.searchKeyword }, { enabled: isLoggedIn });
  const exportTemplateMemberMutation = useListMemberMutation(
    {
      ...filter,
      isExport: true,
      searchKeyword: searchTitle,
      companyFilter: companyFilter?.value,
      responseType: 'arraybuffer',
    },
    {
      responseType: 'arraybuffer',
    }
  );

  const getDetailMemberById = useMemo(() => {
    return members?.data.find(member => member._id === idMember);
  }, [idMember]);

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
    setIdMember(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      companyId: companyFilter?.value,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setCompanyFilter(null);
    setSearchTitle('');
  };

  const onDownloadTemplate = () => {
    exportTemplateMemberMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'member-template');
        toast.showMessageSuccess('Tải mẫu hội viên thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu hội viên thất bại');
      },
    });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Quản lý báo giá
                </Text>
                {(userInfo?.role === Roles.ADMIN || userInfo?.role === Roles.SUPER_ADMIN) && (
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                    Tạo mẫu báo giá
                  </Button>
                )}
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm báo giá</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={members?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={members?.pagination?.page}
              pageLength={members?.pagination?.pageSize}
              totalRecords={members?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isOpenViewMemberDetailModal && (
        <ModalShowDetailMember isOpen={isOpenViewMemberDetailModal} onClose={handleCloseModal} memberDetail={sizeEditing} />
      )}
      {isOpenAdjustCoinModal && (
        <AdjustCoinModal isOpen={isOpenAdjustCoinModal} memberDetail={getDetailMemberById} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isCreateModalOpen && (
        <FormQuotation companyDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
    </Flex>
  );
}

export default Quotation;
