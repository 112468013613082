import { Box, Flex, IconButton, Table, Tag, TagLabel, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, Roles, StatusHotLineHistory, StatusVoice } from 'constants/common';
import moment from 'moment';
import { formatSecondsToHHMMSS, obfuscatePhoneNumber } from 'utils/helpers';
import { capitalize, upperCase } from 'lodash';
import { useUserState } from 'context/UserContext';
import { FaDownload } from 'react-icons/fa';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };

  const columns = useMemo(
    () => [
      // columnHelper.accessor('user', {
      //   header: 'Người dùng',
      //   cell: info => {
      //     if (info.getValue()?.username && info.getValue()?.email) {
      //       return info.getValue()?.username + ' - ' + info.getValue()?.email;
      //     }
      //     return '--';
      //   },
      // }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('campaign', {
        header: 'Chiến dịch',
        cell: info => info.getValue()?.campaign_name || '--',
      }),
      columnHelper.accessor('hotline', {
        header: 'Số tổng đài',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('phoneNumber', {
        header: 'Mobile',
        cell: info =>
          userInfo?.showPhone ? info.getValue() : userInfo?.role === Roles.STAFF ? obfuscatePhoneNumber(info.getValue()) : info.getValue(),
      }),
      columnHelper.accessor('callstatus', {
        header: 'Trạng thái',
        cell: info =>
          info.getValue() ? (
            <Tag
              whiteSpace={'nowrap'}
              borderRadius="full"
              variant="solid"
              colorScheme={upperCase(info.getValue()) === StatusVoice.ANSWERED ? 'green' : 'gray'}
            >
              <TagLabel whiteSpace={'nowrap'}>{capitalize(StatusHotLineHistory[info.getValue()?.replace(/-/g, '_')])}</TagLabel>
            </Tag>
          ) : (
            <Tag whiteSpace={'nowrap'} borderRadius="full" variant="solid" colorScheme={'gray'}>
              <TagLabel whiteSpace={'nowrap'}>{'Không xác định'}</TagLabel>
            </Tag>
          ),
      }),
      columnHelper.accessor('billsec', {
        header: 'Thời lượng trả lời',
        cell: info => {
          const result = formatSecondsToHHMMSS(info.getValue() || 0);
          return result?.hours + ':' + result?.minutes + ':' + result?.seconds || '--';
        },
      }),
      columnHelper.accessor('duration', {
        header: 'Tổng thời gian',
        cell: info => {
          const result = formatSecondsToHHMMSS(info.getValue() || 0);
          return result?.hours + ':' + result?.minutes + ':' + result?.seconds || '--';
        },
      }),
      columnHelper.accessor('timeRing', {
        header: 'Ngày tạo',
        cell: info => info.getValue() ? moment(info.getValue()?.replace(/([+-]\d{2}:\d{2})$/, '')).format(FORMAT_DATE) : '--',
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info =>
          info?.row?.original?.billsec > 0 &&
          info?.row?.original?.callstatus === 'answered' && (
            <Flex alignItems="center" margin={'auto'}>
              <Tooltip label="Tải record file" aria-label="A tooltip" rounded={'6px'}>
                <IconButton
                  bg="transparent"
                  onClick={() => {
                    info?.row?.original?.pathRecordFile && window.open(info?.row?.original?.pathRecordFile, '_blank').focus();
                  }}
                >
                  <FaDownload cursor="pointer" boxSize={4} />
                </IconButton>
              </Tooltip>
            </Flex>
          ),
      }),
      // columnHelper.accessor('action', {
      //   header: 'Thao tác',
      //   cell: info => (
      //     <Flex alignItems="center" margin={'auto'}>
      //       <Tooltip label="Xem chi tiết" aria-label="A tooltip" rounded={'6px'}>
      //         <IconButton bg="transparent" onClick={() => handleRowClick(info?.row?.original, ModalType.Detail)}>
      //           <BiMessageDetail cursor="pointer" boxSize={4} />
      //         </IconButton>
      //       </Tooltip>
      //     </Flex>
      //   ),
      // }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
