import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Tooltip } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ViewIcon } from '@chakra-ui/icons';
import { EditIcon } from '@chakra-ui/icons';
import { FORMAT_DATE, ModalType } from 'constants/common';
import moment from 'moment';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('app_id', {
        header: 'App ID',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name,
      }),
      columnHelper.accessor('state', {
        header: 'Địa chỉ',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('secret_key', {
        header: 'Secret key',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày cập nhật',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            <Tooltip label="Chi tiết" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleRowClick(info?.row?.original, ModalType.Preview)}>
                <ViewIcon cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
            <Tooltip label="Cập nhật" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleRowClick(info?.row?.original, ModalType.Add)}>
                <EditIcon cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
