import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ModalType, Roles } from 'constants/common';
import { toast } from 'components/Toast';
import { AssignCompanyExtensionFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { useAssignCompanyExtensionMutation, useQueryGetCompany } from 'services/company';
import { useUserState } from 'context/UserContext';
import { CookieStorage } from 'utils/cookie-storage';

const FormAssignPhoneUser = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const { userInfo } = useUserState();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AssignCompanyExtensionFormValidate),
  });
  const assignCompanyExtensionMutation = useAssignCompanyExtensionMutation()
  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });
  
  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Assign);
  };
  
  const onSubmit = async values => {
    const payload = {
      extensionId: companyDetail?._id,
      companyId: values.companyId?.value,
    }
    assignCompanyExtensionMutation.mutate(
      payload,
      {
        onSuccess: () => handleSuccess(`Gán doanh nghiệp cho số máy chủ thành công`),
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(companyDetail?.company)) {
      reset({
        companyId: {
          label: companyDetail?.company?.name,
          value: companyDetail?._id,
        },
      });
    }
  }, [companyDetail?.company]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Assign);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Gán doanh nghiệp cho số máy chủ</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Doanh nghiệp"
              name="companyId"
              placeholder="Chọn doanh nghiệp"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(company?.data, 'name', '_id')}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Assign);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={assignCompanyExtensionMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormAssignPhoneUser;
