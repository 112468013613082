import { Card, CardBody, CardHeader, Flex, List, ListIcon, ListItem, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryGetStatisticReportExtension } from 'services/statistic';
import { MdCheckCircle } from 'react-icons/md';
import { processCallStatus } from 'utils/mapping';
import ReportTable from './ReportTable';

function ReportExtension() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const { id } = useParams();
  const { data, refetch } = useQueryGetStatisticReportExtension({ extension: id }, { enabled: !!id });

  const handleUpdateItem = () => {};

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Báo cáo số máy chủ
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <List spacing={3} mb={8}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Số lượng cuộc ghi âm: {data?.data?.countRecord}
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Số lượng cuộc gọi trả lời: {data?.data?.countCall}
            </ListItem>
          </List>
          <Stack overflow={'auto'}>
            <ReportTable categorysData={data?.data?.callstatus ? processCallStatus(data?.data?.callstatus) : []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
          </Stack>
          {/* <Flex justifyContent={'flex-end'}>
            <Pagination
              page={extensions?.pagination?.page}
              pageLength={extensions?.pagination?.pageSize}
              totalRecords={extensions?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex> */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ReportExtension;
