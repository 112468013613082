import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Switch, Tooltip } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ViewIcon } from '@chakra-ui/icons';
import { FORMAT_DATE, ModalType, Roles } from 'constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { useChangeStatusQuotation, useDeleteQuotation } from 'services/quotation';
import { toast } from 'components/Toast';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { useUserState } from 'context/UserContext';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();
  const changeStatusProductMutation = useChangeStatusQuotation();
  const deleteCompanyMutation = useDeleteQuotation();
  const { userInfo } = useUserState();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };

  const handleDeleteSize = async company => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa dịch vụ này không?');
    if (!confirmDelete) {
      return;
    }
    deleteCompanyMutation.mutate(
      { id: company?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa dịch vụ thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa dịch vụ không thành công');
          refetch?.();
        },
      }
    );
  };

  const onActionProduct = (productId, title) => {
    changeStatusProductMutation.mutate(
      { id: productId },
      {
        onSuccess: () => {
          toast.showMessageSuccess(`${title} thành công`);
          refetch?.();
        },
        onError: () => {
          toast.showMessageError(`${title} thất bại`);
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: 'Chủ đề',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('description', {
        header: 'Mô tả',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('price', {
        header: 'Giá',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('isPublished', {
        header: 'Phát hành',
        cell: info =>
          (userInfo?.role === Roles.ADMIN || userInfo?.role === Roles.SUPER_ADMIN) ? (
            <Switch
              size="md"
              defaultChecked={info.getValue()}
              onChange={e => onActionProduct(info?.row?.original?._id, 'Thay đổi trạng thái phát hành của dịch vụ')}
            />
          ) : (
            'Đã phát hành'
          ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày cập nhật',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            <Tooltip label="Chi tiết" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleRowClick(info?.row?.original, ModalType.Preview)}>
                <ViewIcon cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>

            {(userInfo?.role === Roles.ADMIN || userInfo?.role === Roles.SUPER_ADMIN) && (
              <>
                <Tooltip label="Cập nhật" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleRowClick(info?.row?.original, ModalType.Add)}>
                    <AiFillEdit cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
                <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original)}>
                    <AiFillDelete color="red" cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
