import React from 'react';
import { Card, CardBody, CardHeader, Divider, Flex, FormLabel, Text, useColorModeValue } from '@chakra-ui/react';
import { useChangeStatusExtensionMutation, useQueryInforExtension } from 'services/user';
import { toast } from 'components/Toast';
import { Switch } from '@chakra-ui/react';
import { Roles } from 'constants/common';
import { useUserState } from 'context/UserContext';

function InforExtension() {
  const { userInfo } = useUserState();
  const textColor = useColorModeValue('white', 'white');
  const { data, refetch } = useQueryInforExtension({}, { enabled: userInfo?.role === Roles.STAFF });
  const changeStatusExtension = useChangeStatusExtensionMutation();

  const handleChangeStatus = () => {
    changeStatusExtension.mutate(undefined, {
      onSuccess: () => {
        if(userInfo?.role === Roles.STAFF) refetch();
        toast.showMessageSuccess('Thay đổi trạng thái máy gọi thành công.');
      },
    });
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card w={'100%'} py="10px">
        <CardHeader p="6px 20px 10px 20px">
          <Flex direction="column">
            <Text fontSize="larger" fontWeight="bold">
              Thông tin máy gọi
            </Text>
          </Flex>
        </CardHeader>
        <Divider color={textColor} />
        <CardBody display={'flex'} flexDirection="column" gap={4}>
          <Flex alignItems={'center'} gap={6}>
            <FormLabel m={0} htmlFor="isChecked" fontWeight={600}>
              Máy gọi:
            </FormLabel>
            <Text>{data?.data?.extension}</Text>
          </Flex>
          <Flex alignItems={'center'} gap={6}>
            <FormLabel m={0} htmlFor="isChecked" fontWeight={600}>
              Trạng thái máy gọi:
            </FormLabel>
            <Switch id="isChecked" isChecked={data?.data?.enabled} onChange={handleChangeStatus} />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default InforExtension;
