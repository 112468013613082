import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType, RatioOption, Roles } from 'constants/common';
import { toast } from 'components/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectController from 'components/Form/SelectController';
import { useQueryGetCompany } from 'services/company';
import { CookieStorage } from 'utils/cookie-storage';
import { mappingOptionSelect } from 'utils/mapping';
import { useCreateHotlineAutodialerCampaignMutation, useUpdateCampaignMutation } from 'services/campaign';
import { useUserState } from 'context/UserContext';
import { HotlineAutodialerFormValidate } from 'utils/validation';
import { isEmpty } from 'lodash';

const FormCampaign = ({ itemEdit, isOpen, onClose, refetch }) => {
  const { userInfo } = useUserState();
  const cancelRef = React.useRef();
  const createHotlineAutodialerCampaignMutation = useCreateHotlineAutodialerCampaignMutation();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(HotlineAutodialerFormValidate),
  });
  const updateCampaignMutation = useUpdateCampaignMutation();
  const isLoggedIn = CookieStorage.isAuthenticated();

  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    if (isEmpty(itemEdit)) {
      createHotlineAutodialerCampaignMutation.mutate(
        {
          campaign_name: values?.campaign_name,
          concurrent_calc: Number(values?.concurrent_calc),
          company_id: values?.company_id?.value,
          ratio: Number(values?.ratio?.value),
        },
        {
          onSuccess: () => handleSuccess(`Tạo chiến dịch gọi trực tiếp thành công`),
        }
      );
      return;
    }
    updateCampaignMutation.mutate(
      {
        campaign_name: values?.campaign_name,
        campaign_id: itemEdit?._id,
        concurrent_calc: Number(values?.concurrent_calc),
        ratio: Number(values?.ratio?.value),
      },
      {
        onSuccess: () => handleSuccess(`Cập nhật chiến dịch gọi trực tiếp thành công`),
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(itemEdit)) {
      reset({
        campaign_name: itemEdit?.campaign_name,
        concurrent_calc: itemEdit?.concurrent_calc,
        ratio: {
          label: itemEdit?.ratio,
          value: itemEdit?.ratio,
        },
        company_id: {
          label: itemEdit?.company?.name,
          value: itemEdit?._id,
        },
      });
    }
  }, [itemEdit]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Tạo thông tin chiến dịch</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="campaign_name"
              label="Tên chiến dịch"
              styleContainer={{
                marginBottom: '12px',
              }}
            />
            <InputController
              type="number"
              control={control}
              isRequired
              name="concurrent_calc"
              label="Số lượng cuộc gọi đồng thời"
            />
            <SelectController
              control={control}
              name="ratio"
              label="Số lượng cuộc gọi đổ về"
              options={RatioOption}
              isRequired
              styleContainer={{ pt: 4 }}
            />
            <SelectController
              isDisabled={!isEmpty(itemEdit)}
              control={control}
              name="company_id"
              label="Company"
              options={mappingOptionSelect(company?.data, 'name', '_id')}
              isRequired
              styleContainer={{ pt: 4 }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createHotlineAutodialerCampaignMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {isEmpty(itemEdit) ? 'Tạo' : 'Chỉnh sửa'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormCampaign;
