import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ModalType, TYPE_AUTOCALL } from 'constants/common';
import { toast } from 'components/Toast';
import { CreateHotlineListFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useCreateHotlineListMutation, useUpdatePhone, usecreatePhone } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { CookieStorage } from 'utils/cookie-storage';
import InputController from 'components/Form/InputController';
import { useQueryGetCompany } from 'services/company';
import { useQueryGetCampaign } from 'services/campaign';

const FormExportPhone = ({ companyDetail, isOpen, onClose, refetch }) => {
  const [companySelected, setCompanySelected] = useState();
  const cancelRef = React.useRef();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const createPhone = usecreatePhone();
  const updatePhone = useUpdatePhone();
  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(CreateHotlineListFormValidate),
  });
  const { data: campaigns } = useQueryGetCampaign({ companyId: companySelected?.value, type: TYPE_AUTOCALL.AUTO_DIALER }, { enabled: isLoggedIn && !!companySelected?.value });
  const { data: companies } = useQueryGetCompany({}, { enabled: isLoggedIn });
  const createHotlineList = useCreateHotlineListMutation();

  const handleSuccess = () => {
    toast.showMessageSuccess(`Tạo hotline list thành công`);
    refetch?.();
    onClose(ModalType.Import);
  };

  const onSubmit = (values) => {
    createHotlineList.mutate(
      {
        list_name: values.list_name,
        campaign_id: values.campaign_id?.value,
      },
      {
        onSuccess: () => handleSuccess(`Tạo hotline list thành công`),
      }
    );
  }

  useEffect(() => {
    if (!isEmpty(companyDetail)) {
      reset({
        phone: companyDetail.phone,
        id: companyDetail._id,
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Import);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Tạo hotline list</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              type="text"
              name="list_name"
              label="Tên List"
              isRequired
              styleContainer={{
                marginBottom: '16px',
              }}
            />
            <SelectController
              isRequired
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Chi nhánh"
              name="company_id"
              placeholder="Chọn chi nhánh"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(companies?.data, 'name', '_id')}
              onChange={(e) => {
                setCompanySelected(e);
                setValue('campaign_id', null)
              }}
            />
            <SelectController
              isRequired
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Chiến dịch"
              name="campaign_id"
              placeholder="Chọn chiến dịch"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(campaigns?.data, 'campaign_name', '_id')}
              isDisabled={!companySelected}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Import);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createPhone.isPending || updatePhone.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormExportPhone;
