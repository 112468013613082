import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Tooltip } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType, Roles } from 'constants/common';
import moment from 'moment';
import { toast } from 'components/Toast';
import { FaPhoneVolume } from 'react-icons/fa';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { RiHistoryFill } from 'react-icons/ri';
import { useCallHotline, useDeletePhone } from 'services/ips';
import { useUserState } from 'context/UserContext';
import { useHistory } from 'react-router-dom';
import { CiBoxList } from 'react-icons/ci';
import { BiPhoneCall } from 'react-icons/bi';
import { MdCall } from 'react-icons/md';
import { obfuscatePhoneNumber } from 'utils/helpers';

const SizeTable = ({ categorysData }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteIp = useDeletePhone();
  const callHotline = useCallHotline();
  const { userInfo } = useUserState();

  const columns = useMemo(
    () => [
      columnHelper.accessor('_id', {
        header: 'ID',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('phone', {
        header: 'Số điện thoại',
        cell: info => userInfo?.showPhone ? info.getValue() : (userInfo?.role === Roles.STAFF ? obfuscatePhoneNumber(info.getValue()) : info.getValue()),
      }),
      // columnHelper.accessor('code', {
      //   header: 'Code',
      //   cell: info => info.getValue() || '--',
      // }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
