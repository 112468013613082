import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { SettingExtensionFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useSettingExtensionMutation } from 'services/company';
import SelectController from 'components/Form/SelectController';
import { useQueryGetExtensionsAssign } from 'services/ips';
import { CookieStorage } from 'utils/cookie-storage';
import { mappingOptionSelect } from 'utils/mapping';

const SettingExtension = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const settingExtensionMutation = useSettingExtensionMutation();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SettingExtensionFormValidate),
  });
  const { data: extensions } = useQueryGetExtensionsAssign({}, { enabled: isLoggedIn });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Assign);
  };
  const handleError = error => {
    toast.showMessageError(error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Cập nhật cài đặt số máy chủ thất bại`);
  };
  const onSubmit = async values => {
    settingExtensionMutation.mutate(
      { userId: companyDetail?._id, extension: values?.extension?.label },
      {
        onSuccess: () => handleSuccess(`Tạo cài đặt số máy chủ thành công`),
        onError: error => handleError(error),
      }
    );
  };

  useEffect(() => {
    const extensionSelected = extensions?.data?.find(item => item.extension === companyDetail?.extensionId);
    if (!isEmpty(companyDetail) && extensionSelected) {
      reset({
        extension: {
            value: extensionSelected?.extension_uuid,
            label: extensionSelected?.extension,
        },
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Assign);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Cập nhật số máy chủ cho {companyDetail.username}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController
              label="Số máy chủ"
              name="extension"
              placeholder="Chọn số máy chủ"
              control={control}
              options={mappingOptionSelect(extensions?.data, 'extension', 'extension_uuid')}
              isRequired
              styleContainer={{ pt: '4' }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Assign);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={settingExtensionMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Cài đặt
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SettingExtension;
