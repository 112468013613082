import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, TagLabel, Tag, Menu, MenuButton, MenuList, MenuItem, Tooltip } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType, Roles, StatusHotLineHistory, StatusVoice } from 'constants/common';
import moment from 'moment';
import { toast } from 'components/Toast';
import { BiMessageDetail } from 'react-icons/bi';
import { useDeleteUserManagerMutation } from 'services/user';
import { formatSecondsToHHMMSS, obfuscatePhoneNumber } from 'utils/helpers';
import upperCase from 'lodash/upperCase';
import { FaHistory } from 'react-icons/fa';
import { useUserState } from 'context/UserContext';
import capitalize from 'lodash/capitalize';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteUserManagerMutation = useDeleteUserManagerMutation();
  const { userInfo } = useUserState();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };

  const handleDeleteSize = async user => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn tài khoản này không?');
    if (!confirmDelete) {
      return;
    }
    deleteUserManagerMutation.mutate(
      { id: user?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tài khoản thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Tài khoản không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      // columnHelper.accessor('user', {
      //   header: 'Người dùng',
      //   cell: info => {
      //     if (info.getValue()?.username && info.getValue()?.email) {
      //       return info.getValue()?.username + ' - ' + info.getValue()?.email;
      //     }
      //     return '--';
      //   },
      // }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('extension', {
        header: 'Số máy chủ',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('callstatus', {
        header: 'Trạng thái',
        cell: info =>
          info.getValue() ? (
            <Tag
              whiteSpace={'nowrap'}
              borderRadius="full"
              variant="solid"
              colorScheme={upperCase(info.getValue()) === StatusVoice.ANSWERED ? 'green' : 'gray'}
            >
              <TagLabel whiteSpace={'nowrap'}>{capitalize(StatusHotLineHistory[(info.getValue()?.replace(/-/g, "_"))])}</TagLabel>
            </Tag>
          ) : (
            <Tag
              whiteSpace={'nowrap'}
              borderRadius="full"
              variant="solid"
              colorScheme={'gray'}
            >
              <TagLabel whiteSpace={'nowrap'}>{'Không xác định'}</TagLabel>
            </Tag>
          ),
      }),
      columnHelper.accessor('phoneNumber', {
        header: 'Mobile',
        cell: info => userInfo?.showPhone ? info.getValue() : (userInfo?.role === Roles.STAFF ? obfuscatePhoneNumber(info.getValue()) : info.getValue()),
      }),
      columnHelper.accessor('hotline', {
        header: 'Hotline',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('billsec', {
        header: 'Thời lượng trả lời',
        cell: info => {
          const result = formatSecondsToHHMMSS(info.getValue() || 0);
          return result?.hours + ':' + result?.minutes + ':' + result?.seconds || '--';
        },
      }),
      columnHelper.accessor('duration', {
        header: 'Tổng thời gian',
        cell: info => {
          const result = formatSecondsToHHMMSS(info.getValue() || 0);
          return result?.hours + ':' + result?.minutes + ':' + result?.seconds || '--';
        },
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => info.getValue() ? moment(info.getValue()).format(FORMAT_DATE) : '--',
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            <Tooltip label="Xem chi tiết" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleRowClick(info?.row?.original, ModalType.Detail)}>
                <BiMessageDetail cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
            {info?.row?.original?.pathRecordFile && (
              <Menu>
                {({ isOpen }) => (
                  <>
                    <Tooltip label="Đoạn thoại" aria-label="A tooltip" rounded={'6px'}>
                      <MenuButton bg="transparent" isActive={isOpen} as={IconButton}>
                        <IconButton bg="transparent">
                          <FaHistory cursor="pointer" boxSize={4} />
                        </IconButton>
                      </MenuButton>
                    </Tooltip>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          info?.row?.original?.pathRecordFile && window.open(info?.row?.original?.pathRecordFile, '_blank').focus();
                        }}
                      >
                        Mở đoạn thoại
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          info?.row?.original?.pathRecordFile && window.open(info?.row?.original?.pathRecordFile, '_blank').focus();
                        }}
                      >
                        Tải xuống đoạn thoại
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            )}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
