import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Tooltip, Switch } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaRegEdit } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType, Roles } from 'constants/common';
import moment from 'moment';
import { toast } from 'components/Toast';
import { AiFillDelete } from 'react-icons/ai';
import { useActiveTemplateMutation, useDeleteTemplateMutation } from 'services/email-template';
import { useUserState } from 'context/UserContext';

const SizeTable = ({ categorysData, refetch, handleUpdateItem }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteTemplateMutation = useDeleteTemplateMutation();
  const activeTemplateMutation = useActiveTemplateMutation();
  const { userInfo } = useUserState();

  const handleDeleteSize = async email => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa mẫu email này không?');
    if (!confirmDelete) {
      return;
    }
    deleteTemplateMutation.mutate(
      { template_id: email?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa mẫu email thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa mẫu email không thành công');
        },
      }
    );
  };

  const handleActiveEmailTemplate = async _id => {
    activeTemplateMutation.mutate(
      { template_id: _id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Thay đổi trạng thái mẫu email thành công');
        },
        onError: () => {
          toast.showMessageError('Thay đổi trạng thái mẫu email không thành công');
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Tên',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('content', {
        header: 'Nội dung',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('active', {
        header: 'Trạng thái kích hoạt',
        cell: info => (
          <Switch
            size="md"
            defaultChecked={info.getValue()}
            onChange={e => handleActiveEmailTemplate(info?.row?.original?._id, 'Thay đổi trạng thái kích hoạt')}
          />
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Ngày cập nhật',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <>
            {[Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) && (
              <Flex alignItems="center" margin={'auto'}>
                <Tooltip label="Cập nhật" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleUpdateItem(info?.row?.original, ModalType.Add)}>
                    <FaRegEdit cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
                <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original)}>
                    <AiFillDelete color="red" cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
              </Flex>
            )}
          </>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    initialState: {
      columnVisibility: {
        action: ([Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role))
      }
    }
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
