import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { AssignGroupFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { CookieStorage } from 'utils/cookie-storage';
import { useQueryGroupListAssign } from 'services/group';
import { useAddGroupMutation } from 'services/ips';

const FormSendSms = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(AssignGroupFormValidate),
  });
  const { data: templates } = useQueryGroupListAssign({ company_id: companyDetail?.company?._id, ...(companyDetail?.campaign?.user?._id && {userId: companyDetail?.campaign?.user?._id}) }, { enabled: isLoggedIn && !!companyDetail?.company?._id });
  const addGroupMutation = useAddGroupMutation();

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.EmailBackup);
  };

  const onSubmit = async values => {
    const payload = {
      group_id: values.group_id?.value,
      list_id: companyDetail?._id,
    }
    addGroupMutation.mutate(
      payload,
      {
        onSuccess: () => handleSuccess(`Thêm nhóm thành công`),
      }
    );
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.EmailBackup);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Thêm nhóm cho hotline list</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Nhóm"
              name="group_id"
              placeholder="Chọn nhóm"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(templates?.data, 'name', '_id')}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.EmailBackup);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={addGroupMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Thêm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormSendSms;
