import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { QuotationFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useCreateQuotation, useUpdateQuotation } from 'services/quotation';

const FormQuotation = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createQuotation = useCreateQuotation();
  const updateQuotation = useUpdateQuotation();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(QuotationFormValidate),
  });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Cập nhật dịch vụ thất bại`);
  };
  const onSubmit = async values => {
    if (!isEmpty(companyDetail)) {
      updateQuotation.mutate(
        { ...values },
        {
          onSuccess: () => handleSuccess(`Cập nhật dịch vụ thành công`),
          onError: error => handleError(error),
        }
      );
      return;
    }
    createQuotation.mutate(
      { ...values },
      {
        onSuccess: () => handleSuccess(`Tạo dịch vụ thành công`),
        onError: error => handleError(error),
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(companyDetail)) {
      reset({
        title: companyDetail.title,
        price: companyDetail.price,
        description: companyDetail.description,
        id: companyDetail._id,
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">{isEmpty(companyDetail) ? 'Tạo' : 'Cập nhật'} thông tin dịch vụ</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="title"
              label="Tên dịch vụ"
              styleContainer={{
                marginBottom: '16px',
              }}
            />
            <InputController
              type="number"
              control={control}
              isRequired
              name="price"
              label="Giá"
              styleContainer={{
                marginBottom: '20px',
              }}
            />
            <InputController
              control={control}
              isRequired
              name="description"
              label="Mô tả"
              styleContainer={{
                marginBottom: '20px',
              }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createQuotation.isPending || updateQuotation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormQuotation;
