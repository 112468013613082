import React from 'react';
import { IoListCircleOutline } from 'react-icons/io5';
import { SiEclipsemosquitto, SiZalo } from "react-icons/si";
import { VscGroupByRefType } from "react-icons/vsc";
import { BiFoodMenu } from 'react-icons/bi';
import { MdGroupWork, MdOutlineConnectingAirports, MdOutlineListAlt , MdOutlineRecordVoiceOver, MdRecordVoiceOver, MdOutlineNoteAlt, MdPhone, MdPeopleOutline, MdCampaign, MdEmail } from "react-icons/md";
import { RiHistoryFill } from "react-icons/ri";
import { HiTemplate } from "react-icons/hi";
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { Roles } from 'constants/common';
import Company from 'views/Dashboard/Company';
import HistoriesPhone from 'views/Dashboard/Company/components/HistoriesPhone';
import HistoriesSms from 'views/Dashboard/Company/components/HistoriesSms';
import Zalo from 'views/Dashboard/Zalo/Members';
import Quotation from 'views/Dashboard/Quotation';
import IPS from 'views/Dashboard/IPS';
import { HomeIcon, PersonIcon } from 'components/Icons/Icons';
import Connect from 'views/Dashboard/Connect';
import Users from 'views/Dashboard/Users';
import HistoryHotline from 'views/Dashboard/HistoryHotline';
import Histories from 'views/Dashboard/Phone/components/Histories';
import HotlineTemplate from 'views/Dashboard/HotlineTemplate';
import HistoryHotlineAutoCall from 'views/Dashboard/HistoryHotlineAutoCall';
import Campaign from 'views/Dashboard/Campaign';
import ReportCompany from 'views/Dashboard/Company/components/Report';
import ReportExtension from 'views/Dashboard/Connect/components/Report';
import Dashboard from 'views/Dashboard/Dashboard';
import Group from 'views/Dashboard/Group';
import EmailTemplates from 'views/Dashboard/EmailTemplates';
import HotlineVoiceTemplate from 'views/Dashboard/HotlineVoiceTemplate';
import HotlineVoiceCampaign from 'views/Dashboard/HotlineVoiceCampaign';
import ListPhone from 'views/Dashboard/ListPhone';
import HistorySms from 'views/Dashboard/HistorySms';
import ListHotline from 'views/Dashboard/ListHotline';
import HotlineAutodialerCampaign from 'views/Dashboard/HotlineAutodialerCampaign';
import ListStaff from 'views/Dashboard/ListStaff';
import HistoryHotlineAutodialer from 'views/Dashboard/HistoryHotlineAutodialer';
import ListPhoneHotlineAutodialer from 'views/Dashboard/ListPhoneHotlineAutodialer';
import SMSCampaign from 'views/Dashboard/SMSCampaign';
import { TbManualGearbox, TbReport } from 'react-icons/tb';
import HotlineManual from 'views/Dashboard/HotlineManual';
import InforExtension from 'views/Dashboard/InforExtension';
import StatisticReport from 'views/Dashboard/StatisticReport';

var dashRoutes = [
  {
    path: '/dashboard',
    name: 'Thống kê',
    icon: <HomeIcon color="inherit" />,
    layout: '/admin',
    component: Dashboard,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER, Roles.STAFF],
  },
  {
    name: 'ZNS',
    category: 'zns',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
    ],
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    path: '/company/:id/histories-phone',
    name: 'Lịch sử cuộc gọi',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: HistoriesPhone,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/company/:id/histories-sms',
    name: 'Lịch sử sms',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: HistoriesSms,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/phone/:id/histories-hotline',
    name: 'Lịch sử cuộc gọi',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: Histories,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/company',
    name: 'Chi nhánh',
    icon: <VscGroupByRefType color="inherit" />,
    layout: '/admin',
    component: Company,
    role: [Roles.SUPER_ADMIN],
    groupName: '',
  },
  {
    path: '/report/:id/company',
    name: 'Báo cáo doanh nghiệp',
    icon: <VscGroupByRefType color="inherit" />,
    redirect: true,
    layout: '/admin',
    component: ReportCompany,
    role: [Roles.SUPER_ADMIN],
    groupName: '',
  },
  {
    path: '/zalo',
    name: 'Zalo',
    icon: <SiZalo color="inherit" />,
    layout: '/admin',
    component: Zalo,
    role: [Roles.SUPER_ADMIN],
    groupName: '',
  },
  {
    path: '/quotation',
    name: 'Báo giá',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Quotation,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/ips',
    name: 'Địa chỉ IP',
    icon: <SiEclipsemosquitto color="inherit" />,
    layout: '/admin',
    component: IPS,
    role: [Roles.SUPER_ADMIN],
    groupName: '',
  },
  // {
    //   path: '/setting-zalo',
    //   name: 'Setting',
    //   icon: <SiZalo color="inherit" />,
    //   layout: '/admin',
    //   component: SettingZalo,
    //   role: [Roles.ADMIN],
    //   groupName: '',
    // },
    {
      name: 'QUẢN LÝ',
      category: 'hotline',
      rtlName: 'صفحات',
      state: 'pageCollapse',
      views: [
      ],
      role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    },
    {
      path: '/users',
      name: 'Quản lý tổng đài viên',
      icon: <MdPeopleOutline color="inherit" />,
      layout: '/admin',
      component: Users,
      role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
      groupName: '',
    },
    {
      path: '/connect',
    name: 'Quản lý máy chủ',
    icon: <MdOutlineConnectingAirports color="inherit" />,
    layout: '/admin',
    component: Connect,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/group',
    name: 'Quản lý nhóm',
    icon: <MdGroupWork color="inherit" />,
    layout: '/admin',
    component: Group,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    name: 'GỬI SMS',
    category: 'sms',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
    ],
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
  },
  {
    path: '/email-templates',
    name: 'Mẫu SMS',
    icon: <MdEmail color="inherit" />,
    layout: '/admin',
    component: EmailTemplates,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    path: '/sms-campaign',
    name: 'Nhiệm vụ gọi',
    icon: <MdRecordVoiceOver color="inherit" />,
    layout: '/admin',
    component: SMSCampaign,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    path: '/histories-sms/:id',
    name: 'Lịch sử',
    redirect: true,
    icon: <RiHistoryFill color="inherit" />,
    layout: '/admin',
    component: HistorySms,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER, Roles.STAFF],
    groupName: '',
  },
  // {
  //   path: '/phone',
  //   name: 'Quản lý nhiệm vụ gọi',
  //   icon: <MdPhone color="inherit" />,
  //   layout: '/admin',
  //   component: Phone,
  //   role: [Roles.SUPER_ADMIN, Roles.ADMIN],
  //   groupName: '',
  // },
  {
    path: '/list-phone/:missionId',
    name: 'Quản lý số điện thoại của mission',
    icon: <MdPhone color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: ListPhone,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/report/:id/connect',
    name: 'Báo cáo kết nối',
    icon: <VscGroupByRefType color="inherit" />,
    redirect: true,
    layout: '/admin',
    component: ReportExtension,
    role: [Roles.SUPER_ADMIN],
    groupName: '',
  },
  // {
  //   path: '/report',
  //   name: 'Báo cáo hằng ngày',
  //   icon: <HiOutlineDocumentReport color="inherit" />,
  //   layout: '/admin',
  //   component: ReportDaily,
  //   role: [Roles.SUPER_ADMIN, Roles.ADMIN],
  //   groupName: '',
  // },
  {
    name: 'GỌI VOICE AI',
    category: 'hotline voice',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
    ],
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
  },
  // {
  //   path: '/record-file',
  //   name: 'Quản lý file record',
  //   icon: <FaFileAlt color="inherit" />,
  //   layout: '/admin',
  //   component: RecordFile,
  //   role: [Roles.SUPER_ADMIN, Roles.ADMIN],
  //   groupName: '',
  // },
  {
    path: '/hotline-voice-templates',
    name: 'Mẫu voice',
    icon: <MdOutlineRecordVoiceOver color="inherit" />,
    layout: '/admin',
    component: HotlineVoiceTemplate,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    path: '/hotline-voice-campaign',
    name: 'Nhiệm vụ gọi',
    icon: <MdRecordVoiceOver color="inherit" />,
    layout: '/admin',
    component: HotlineVoiceCampaign,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    path: '/histories-hotline-auto-call/:id',
    name: 'Lịch sử',
    icon: <MdOutlineNoteAlt color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: HistoryHotlineAutoCall,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    name: 'GỌI VĂN BẢN',
    category: 'hotline template',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
    ],
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
  },
  {
    path: '/hotline-templates',
    name: 'Mẫu văn bản',
    icon: <HiTemplate color="inherit" />,
    layout: '/admin',
    component: HotlineTemplate,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    path: '/hotline-campaign',
    name: 'Nhiệm vụ gọi',
    icon: <MdCampaign color="inherit" />,
    layout: '/admin',
    component: Campaign,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    path: '/histories-hotline/:id',
    name: 'Lịch sử',
    icon: <RiHistoryFill color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: HistoryHotline,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    name: 'GỌI TRỰC TIẾP',
    category: 'hotline autodialer',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
    ],
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
  },
  {
    path: '/hotline/:id',
    name: 'Chi tiết nhiệm vụ gọi',
    icon: <MdOutlineListAlt color="inherit" />,
    redirect: true,
    layout: '/admin',
    component: ListHotline,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/staff/:id',
    name: 'Danh sách staff',
    icon: <MdOutlineListAlt color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: ListStaff,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/hotline-autodialer',
    name: 'Nhiệm vụ gọi',
    icon: <MdRecordVoiceOver color="inherit" />,
    layout: '/admin',
    component: HotlineAutodialerCampaign,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    name: 'GỌI THỦ CÔNG',
    category: 'hotline autodialer',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
    ],
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.STAFF],
  },
  {
    path: '/hotline-manual',
    name: 'Lịch sử',
    icon: <TbManualGearbox color="inherit" />,
    layout: '/admin',
    component: HotlineManual,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.STAFF],
    groupName: '',
  },
  {
    path: '/list-phone-autodialer/:id',
    name: 'Danh sách số điện thoại',
    icon: <MdOutlineListAlt color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: ListPhoneHotlineAutodialer,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/histories-hotline-autodialer/:id',
    name: 'Lịch sử',
    icon: <RiHistoryFill color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: HistoryHotlineAutodialer,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN],
    groupName: '',
  },
  {
    path: '/statistic-hotline',
    name: 'Báo cáo thời lượng',
    icon: <TbReport color="inherit" />,
    layout: '/admin',
    component: StatisticReport,
    role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER],
    groupName: '',
  },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/profile',
        name: 'Profile',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        redirect: true,
        component: Profile,
        role: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER, Roles.STAFF],
        groupName: 'RESET_PASSWORD_ADMIN',
      },
      {
        path: '/infor-extension',
        name: 'Infor Extension',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        redirect: true,
        component: InforExtension,
        role: [Roles.STAFF],
        groupName: 'RESET_PASSWORD_ADMIN',
      },
      {
        path: '/sign-in',
        layout: '/auth',
        redirect: true,
        component: SignIn,
      },
      {
        path: '/sign-up',
        layout: '/auth',
        redirect: true,
        component: SignUp,
      },
    ],
  },
];

export default dashRoutes;
