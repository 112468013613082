import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ModalType, TYPE_AUTOCALL } from 'constants/common';
import { toast } from 'components/Toast';
import { VoiceTemplateFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useUpdatePhone, usecreatePhone } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { useVoiceTemplateAutoCallMutation } from 'services/user';
import { CookieStorage } from 'utils/cookie-storage';
import { useQueryGetCampaignAssign } from 'services/campaign';

const FormVoiceTemplateAutoCall = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const createPhone = usecreatePhone();
  const updatePhone = useUpdatePhone();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(VoiceTemplateFormValidate),
  });
  const { data: campaigns } = useQueryGetCampaignAssign({ company_id: companyDetail?.company?._id, type: TYPE_AUTOCALL.AUTO_CALL_VOICE }, { enabled: isLoggedIn && !!companyDetail?.company?._id });
  const voiceTemplateAutoCallMutation = useVoiceTemplateAutoCallMutation();

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Adjust);
  };

  const onSubmit = async values => {
    const payload = {
      campaign_id: values.campaign_id?.value,
      // missionId: companyDetail?._id,
      // type: TYPE_AUTOCALL.AUTO_CALL_VOICE
    }
    voiceTemplateAutoCallMutation.mutate(
      payload,
      {
        onSuccess: () => handleSuccess(`Gọi văn bản thành công`),
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(companyDetail)) {
      reset({
        phone: companyDetail.phone,
        id: companyDetail._id,
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Adjust);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Gọi mẫu</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Chiến dịch"
              name="campaign_id"
              placeholder="Chọn chiến dịch"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(campaigns?.data, 'campaign_name', '_id')}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Adjust);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createPhone.isPending || updatePhone.isPending} onClick={handleSubmit(onSubmit)}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormVoiceTemplateAutoCall;
