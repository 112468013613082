import { Box, Flex, IconButton, Table, Tag, TagLabel, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, Roles, ROOT_API, StatusHotLine, StatusVoice } from 'constants/common';
import moment from 'moment';
import { useUserState } from 'context/UserContext';
import { getMediaPathHttps, obfuscatePhoneNumber } from 'utils/helpers';
import upperCase from 'lodash/upperCase';
import capitalize from 'lodash/capitalize';
import { FaRegArrowAltCircleDown } from 'react-icons/fa';

const SizeTable = ({ categorysData }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();
  const columns = useMemo(
    () => [
      columnHelper.accessor('user', {
        header: 'Tài khoản gọi',
        cell: info => info.getValue()?.username || '--',
      }),
      columnHelper.accessor('extension', {
        header: 'Số máy chủ',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('hotline', {
        header: 'Số tổng đài',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('phoneNumber', {
        header: 'Số gọi',
        cell: info => (!userInfo?.showPhone && userInfo?.role === Roles.STAFF ? obfuscatePhoneNumber(info.getValue()) : info.getValue()),
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('billsec', {
        header: 'Thời lượng trả lời',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('duration', {
        header: 'Tổng thời gian',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('callstatus', {
        header: 'Trạng thái cuộc gọi',
        cell: info =>
          info.getValue() ? (
            <Tag
              whiteSpace={'nowrap'}
              borderRadius="full"
              variant="solid"
              colorScheme={upperCase(info.getValue()) === StatusVoice.ANSWERED ? 'green' : 'gray'}
            >
              <TagLabel whiteSpace={'nowrap'}>{capitalize(StatusHotLine[info.getValue()?.replace(/-/g, '_')])}</TagLabel>
            </Tag>
          ) : (
            <Tag whiteSpace={'nowrap'} borderRadius="full" variant="solid" colorScheme={'gray'}>
              <TagLabel whiteSpace={'nowrap'}>{'Không xác định'}</TagLabel>
            </Tag>
          ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            {info?.row?.original?.pathRecordFile && (
              <Tooltip label="Tải xuống file record" aria-label="A tooltip" rounded={'6px'}>
                <IconButton
                  bg="transparent"
                  onClick={() => {
                    window.open(getMediaPathHttps(ROOT_API + '/', (info?.row?.original?.pathFile || info?.row?.original?.pathRecordFile)), '_blank').focus();
                  }}
                >
                  <FaRegArrowAltCircleDown cursor="pointer" boxSize={4} />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
