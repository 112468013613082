import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { FORMAT_DATE_SHORT, ModalType, Roles, ROOT_API } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import FormCampaign from './components/FormCampaign';
import { useUserState } from 'context/UserContext';
import InputController from 'components/Form/InputController';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CallManual } from 'utils/validation';
import { useCallManualMutation, useQueryGetHotlineManual } from 'services/hotline-manual';
import { toast } from 'components/Toast';
import { downloadFile } from 'utils/helpers';
import axios from 'axios';
import { MdContactPhone } from 'react-icons/md';
import { Select } from 'chakra-react-select';
import { mappingOptionSelect } from 'utils/mapping';
import DatePicker from 'components/DatePicker/DatePicker';
import { useQueryGetGroups } from 'services/group';
import moment from 'moment';
import { useChangeStatusExtensionMutation, useQueryInforExtension } from 'services/user';
import { BsDot, BsFillPhoneVibrateFill } from 'react-icons/bs';
import { FiPhoneMissed } from 'react-icons/fi';
import { socket } from 'config/socket';
import { useQueryGetExtensions, useQueryGetExtensionsAssign } from 'services/ips';
import { useQueryGetCampaign } from 'services/campaign';

const initFilter = {
  companyId: null,
  groupId: null,
  campaign_id: null,
  extension: null,
  type: null,
};

function HotlineManual() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [hotlineFilter, setHotlineFilter] = useState(initFilter);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
  });
  const { userInfo } = useUserState();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(CallManual),
  });
  const callManualMutation = useCallManualMutation();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isFormModalOpen, onOpen: onOpenFormModal, onClose: onCloseFormModal } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenFormModal,
    }),
    [onOpenFormModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseFormModal,
    }),
    [onCloseFormModal]
  );

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const { data: campaigns, refetch } = useQueryGetHotlineManual(
    { ...filter, searchKeyword: filter.searchKeyword },
    { enabled: isLoggedIn }
  );
  const { data: groups } = useQueryGetGroups({}, { enabled: isLoggedIn });
  const { data: extensions } = useQueryGetExtensionsAssign({}, { enabled: isLoggedIn && userInfo?.role !== Roles.STAFF });
  const { data, refetch: refetchProfile } = useQueryInforExtension({}, { enabled: userInfo?.role === Roles.STAFF });
  const { data: campaignsHotline } = useQueryGetCampaign({}, { enabled: isLoggedIn });
  const changeStatusExtension = useChangeStatusExtensionMutation();

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      ...(hotlineFilter?.companyId && { companyId: hotlineFilter?.companyId?.value }),
      ...(hotlineFilter?.groupId && { groupId: hotlineFilter?.groupId?.value }),
      ...(hotlineFilter?.type && { type: hotlineFilter?.type?.value }),
      ...(hotlineFilter?.extension && { extension: hotlineFilter?.extension?.value }),
      ...(hotlineFilter?.campaign_id && { campaign_id: hotlineFilter?.campaign_id?.value }),
      ...(filterDate?.start && { date: moment(filterDate?.start).format(FORMAT_DATE_SHORT) }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setHotlineFilter(initFilter);
    setSearchTitle('');
    setFilterDate({
      start: null,
    });
  };

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
  };

  const onSubmit = async values => {
    callManualMutation.mutate(
      {
        phone: values?.phone,
      },
      {
        onSuccess: () => handleSuccess(`Gọi thủ công thành công`),
      }
    );
  };

  const handleExport = async () => {
    const res = await axios.get(ROOT_API + '/api/v1/hotline/export-history-call-manual', {
      headers: { 'Content-Type': 'application/json', xToken: CookieStorage.getAccessToken() },
      responseType: 'arraybuffer',
      params: {
        ...filter,
      },
    });
    if (res.status === 200) {
      downloadFile(res?.data, 'history-hotline-autodialer');
    }
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const handleChangeStatus = status => {
    changeStatusExtension.mutate(
      {
        status: status,
      },
      {
        onSuccess: res => {
          if(userInfo?.role === Roles.STAFF) refetchProfile();
          toast.showMessageSuccess('Thay đổi trạng thái máy gọi thành công.');
        },
      }
    );
  };

  const [callInfo, setCallInfo] = useState({
    startTime: 0,
    endTime: 0,
  });
  const [callDuration, setCallDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const endCall = () => {
    if(userInfo?.role === Roles.STAFF) refetchProfile();
    const endTime = Date.now();
    setCallInfo(prevState => ({ ...prevState, endTime }));
    setCallDuration({
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  };

  const getColor = (enabled, is_logged_in, is_oncall) => {
    if (is_oncall) return 'orange';
    if (!enabled) return 'gray';
    if (enabled) return '#CEDF9F';
    if (enabled && !is_logged_in) return 'red';
    if (enabled && is_logged_in) return 'green';
  };

  useEffect(() => {
    let interval = 0;
    socket.on('send_start_call', res => {
      if (userInfo?._id === res.user) {
        if(userInfo?.role === Roles.STAFF) refetchProfile();
        const startTime = Date.now();
        setCallInfo({ startTime, endTime: 0 });
        interval = setInterval(() => {
          const now = Date.now();
          const elapsedTime = now - startTime;

          const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
          const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

          setCallDuration({ hours, minutes, seconds });
        }, 1000);
      }
    });
    socket.on('send_end_call', res => {
      clearInterval(interval);
      endCall();
    });

    return () => clearInterval(interval);
  }, []);
  return (
    <Flex
      direction="column"
      pt={userInfo?.role === Roles.STAFF ? { base: '110px', md: '55px', lg: '40px' } : { base: '120px', md: '75px', lg: '100px' }}
    >
      {userInfo?.role === Roles.STAFF && (
        <Card p="16px" mb="24px" bg="#fff" w={{ base: '100%', md: '60%', lg: '80%' }}>
          <Text display={'flex'} gap={4} alignItems={'center'} flexWrap={'wrap'}>
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Máy gọi
            </Text>
            <MdContactPhone size={30} />
            <Flex
              ml={2}
              flexDirection="row"
              gap={4}
              alignItems={'end'}
              bg="blue.500"
              color="white"
              rounded={8}
              w="fit-content"
              px={4}
              py={3}
            >
              <Flex mr={5} flexDirection={'column'}>
                <Text mt={5}>Máy gọi: {data?.data?.extension}</Text>
                <Flex mt={4} gap={2} alignItems={'center'}>
                  <Text>Thời lượng:</Text>
                  <Text color="orange">
                    {callDuration.hours}:{callDuration.minutes}:{callDuration.seconds}
                  </Text>
                </Flex>
                <Text display={'flex'} alignItems={'center'} gap={2}>
                  <Text minW={'100px'}>Trạng thái:</Text>{' '}
                  <BsDot size={50} color={getColor(data?.data?.enabled, data?.data?.is_logged_in, data?.data?.is_oncall)} />
                </Text>
              </Flex>
              <Flex flexDirection={'column'} justifyContent={'center'} gap={1}>
                <IconButton bg="transparent" onClick={() => handleChangeStatus(false)}>
                  <FiPhoneMissed onClick={handleChangeStatus} cursor="pointer" size={20} />
                </IconButton>
                <Text>Thiết lập trạng thái bận</Text>
              </Flex>
              <Flex flexDirection={'column'} justifyContent={'center'} gap={1}>
                <IconButton bg="transparent" onClick={() => handleChangeStatus(true)}>
                  <BsFillPhoneVibrateFill cursor="pointer" size={20} />
                </IconButton>
                <Text>Thiết lập trạng thái trực tuyến</Text>
              </Flex>
            </Flex>
            <Flex flexDirection="column" height={'100%'} justifyContent={'start'} alignItems={'start'}>
              <Text fontWeight={600} pl={5}>
                Chú thích:
              </Text>
              <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                <BsDot size={50} color={'#CEDF9F'} />
                <Text>Trạng thái đã sẵn sàng</Text>
              </Flex>
              <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                <BsDot size={50} color={'green'} />
                <Text>Trạng thái trực tuyến đã kết nối</Text>
              </Flex>
              <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                <BsDot color={'red'} size={50} />
                <Text>Trạng thái trực tuyến chưa kết nối</Text>
              </Flex>
              <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                <BsDot color={'orange'} size={50} />
                <Text>Trạng thái đang có cuộc gọi</Text>
              </Flex>
              <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
                <BsDot color={'gray'} size={50} />
                <Text>Trạng thái bận</Text>
              </Flex>
            </Flex>
          </Text>
          <Flex boxShadow="lg" rounded="md" p={4} justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'10px'}>
            <Stack>
              <Flex gap={'20px'} alignItems={'end'}>
                <InputController type="number" control={control} name="phone" label="Số điện thoại" />
                <Button
                  colorScheme="blue"
                  ml={3}
                  // isLoading={createHotlineAutodialerCampaignMutation.isPending}
                  onClick={handleSubmit(onSubmit)}
                >
                  Gọi
                </Button>
              </Flex>
            </Stack>
          </Flex>
        </Card>
      )}
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử cuộc gọi
                </Text>
                {[Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) && (
                  <Flex alignItems={'center'} gap={4}>
                    <Flex alignItems="center">
                      <Button
                        bg="#3182ce"
                        color="#fff"
                        _hover={{ bg: '#67a1d7' }}
                        onClick={() => {
                          handleExport();
                        }}
                      >
                        Xuất file excel
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex direction={'column'} gap={'20px'} flexWrap={'wrap'}>
                    <Flex flexWrap={'wrap'} gap={'20px'}>
                      <FormControl w={'300px'}>
                        <FormLabel>Tìm kiếm</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl>
                      <FormControl w={'300px'}>
                        <FormLabel>Nhóm</FormLabel>
                        <Select
                          isClearable
                          menuShouldBlockScroll
                          value={hotlineFilter?.groupId}
                          onChange={e => {
                            setHotlineFilter({
                              ...hotlineFilter,
                              groupId: e,
                            });
                          }}
                          options={mappingOptionSelect(groups?.data, 'name', '_id')}
                        ></Select>
                      </FormControl>
                      {userInfo?.role !== Roles.STAFF && (
                        <FormControl w={'300px'}>
                          <FormLabel>Máy chủ</FormLabel>
                          <Select
                            isClearable
                            menuShouldBlockScroll
                            value={hotlineFilter?.extension}
                            onChange={e => {
                              setHotlineFilter({
                                ...hotlineFilter,
                                extension: e,
                              });
                            }}
                            options={mappingOptionSelect(extensions?.data, 'extension', '_id')}
                          ></Select>
                        </FormControl>
                      )}
                      <FormControl w={'300px'}>
                        <FormLabel>Thể loại</FormLabel>
                        <Select
                          isClearable
                          menuShouldBlockScroll
                          value={hotlineFilter?.type}
                          onChange={e => {
                            setHotlineFilter({
                              ...hotlineFilter,
                              type: e,
                            });
                          }}
                          options={[
                            {
                              label: 'Gọi thủ công',
                              value: 'CALL_MANUAL',
                            },
                            {
                              label: 'Gọi trực tuyến',
                              value: 'AUTO_VOICE_ANSWER',
                            },
                            {
                              label: 'Gọi voice AI',
                              value: 'AUTO_VOICE_FILE',
                            },
                            {
                              label: 'Gọi voice văn bản',
                              value: 'AUTO_VOICE_TEMPALTE',
                            },
                          ]}
                        ></Select>
                      </FormControl>
                      <FormControl w={'300px'}>
                        <FormLabel>Nhiệm vụ</FormLabel>
                        <Select
                          isClearable
                          menuShouldBlockScroll
                          value={hotlineFilter?.campaign_id}
                          onChange={e => {
                            setHotlineFilter({
                              ...hotlineFilter,
                              campaign_id: e,
                            });
                          }}
                          options={mappingOptionSelect(campaignsHotline?.data, 'campaign_name', '_id')}
                        ></Select>
                      </FormControl>
                      <FormControl display="flex" flexDirection="column" maxW="300px" mr="12px">
                        <FormLabel marginRight={0} fontSize={14} isTruncated>
                          Ngày
                        </FormLabel>
                        <DatePicker
                          styleInput={{
                            minWidth: '300px',
                          }}
                          selectedDate={filterDate.start}
                          onChange={date => onChangeDate('start')(date)}
                        />
                      </FormControl>
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={campaigns?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={campaigns?.pagination?.page}
              pageLength={campaigns?.pagination?.pageSize}
              totalRecords={campaigns?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isFormModalOpen && <FormCampaign itemEdit={sizeEditing} isOpen={isFormModalOpen} onClose={handleCloseModal} refetch={refetch} />}
    </Flex>
  );
}

export default HotlineManual;
