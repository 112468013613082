import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType, Roles } from 'constants/common';
import { toast } from 'components/Toast';
import { CreateEmailTemplateFormValidate, EditEmailTemplateFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import SelectController from 'components/Form/SelectController';
import { useUserState } from 'context/UserContext';
import { mappingOptionSelect } from 'utils/mapping';
import { CookieStorage } from 'utils/cookie-storage';
import { useQueryGetCompany } from 'services/company';
import { useCreateTemplateMutation, useUpdateTemplateMutation } from 'services/email-template';

const FormEmailTemplate = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const { userInfo } = useUserState();
  const createTemplateMutation = useCreateTemplateMutation();
  const updateTemplateMutation = useUpdateTemplateMutation();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(isEmpty(companyDetail) ? (userInfo?.role === Roles.SUPER_ADMIN ? CreateEmailTemplateFormValidate : EditEmailTemplateFormValidate) : EditEmailTemplateFormValidate),
  });
  const { data: company } = useQueryGetCompany({}, { enabled: CookieStorage.isAuthenticated() && userInfo?.role === Roles.SUPER_ADMIN });

  const handleSuccess = message => {  
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    if (isEmpty(companyDetail)) {
      createTemplateMutation.mutate(
        { ...values, company_id: values?.company_id?.value || userInfo?.company },
        {
          onSuccess: () => handleSuccess(`Tạo thông tin mẫu email thành công`),
        }
      );
      return;
    }
    updateTemplateMutation.mutate(
      { ...values, company_id: values?.company_id?.value, template_id: companyDetail?._id },
      {
        onSuccess: () => handleSuccess(`Cập nhập thông tin mẫu email thành công`),
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(companyDetail)) {
      let companySelected = null;
      if (userInfo?.role === Roles.SUPER_ADMIN) {
        companySelected = company?.data?.find(item => item._id === companyDetail?.company?._id);
      }
      reset({
        name: companyDetail.name,
        content: companyDetail.content,
        template_id: companyDetail._id,
        ...(!isEmpty(companySelected) && {
          company_id: {
            label: companySelected?.name,
            value: companySelected?._id,
          },
        }),
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">{isEmpty(companyDetail) ? 'Tạo' : 'Cập nhật'} thông tin mẫu email</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="name"
              label="Tên mẫu"
              styleContainer={{
                marginBottom: '16px',
              }}
            />
            <InputController
              type='textarea'
              control={control}
              isRequired
              name="content"
              label="Nội dung"
              styleContainer={{
                marginBottom: '16px',
              }}
            />
            {
              userInfo?.role === Roles.SUPER_ADMIN &&
              <SelectController
                isDisabled={!isEmpty(companyDetail)}
                styleBoxInput={{
                  marginBottom: '16px',
                }}
                isRequired
                label="Chi nhánh"
                name="company_id"
                placeholder="Chọn chi nhánh"
                control={control}
                options={mappingOptionSelect(company?.data, 'name', '_id')}
              />
            }
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createTemplateMutation.isPending || updateTemplateMutation.isPending} onClick={handleSubmit(onSubmit)}>
              {isEmpty(companyDetail) ? 'Tạo' : 'Cập nhập'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormEmailTemplate;
