import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType, Roles } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import ModalShowDetailMember from './components/ModalShowDetailMember';
import { useExportPhoneTemplate, useQueryGetListHotline } from 'services/ips';
import FormPhone from './components/FormPhone';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import FormExportPhone from './components/FormExportPhone';
import FormAutoCall from './components/FormAutoCall';
import FormVoiceTemplateAutoCall from './components/FormVoiceTemplateAutoCall';
import FormSendSms from './components/FormSendSms';
import FormImportPhone from './components/FormImportPhone';
import { useUserState } from 'context/UserContext';
import AssignUser from './components/AssignUser';

function ListHotline() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const { id } = useParams();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const { userInfo } = useUserState();
  
  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isFormExportModalOpen, onOpen: onOpenFormExportModal, onClose: onCloseFormExportModal } = useDisclosure();
  const { isOpen: isCreateModalAssignOpen, onOpen: onOpenCreateAssignModal, onClose: onCloseCreateAssignModal } = useDisclosure();
  const { isOpen: isCreateModalVoiceTemplateOpen, onOpen: onOpenCreateVoiceTemplateModal, onClose: onCloseCreateVoiceTemplateModal } = useDisclosure();
  const { isOpen: isFormAutocallModalOpen, onOpen: onOpenFormAutocallModal, onClose: onCloseFormAutocallModal } = useDisclosure();
  const { isOpen: isFormSendSMSModalOpen, onOpen: onOpenFormSendSMSModal, onClose: onCloseFormSendSMSModal } = useDisclosure();
  const { isOpen: isAssignUserOpen, onOpen: onOpenAssignUser, onClose: onCloseAssignUser } = useDisclosure();
  const {
    isOpen: isOpenViewMemberDetailModal,
    onOpen: onOpenViewMemberDetailModal,
    onClose: onCloseViewMemberDetalModal,
  } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Preview]: onOpenViewMemberDetailModal,
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.Assign]: onOpenCreateAssignModal,
      [ModalType.Adjust]: onOpenCreateVoiceTemplateModal,
      [ModalType.Import]: onOpenFormExportModal,
      [ModalType.ChangeStatus]: onOpenFormAutocallModal,
      [ModalType.EmailBackup]: onOpenFormSendSMSModal,
      [ModalType.Block]: onOpenAssignUser,
    }),
    [onOpenCreateModal, onOpenAssignUser, onOpenViewMemberDetailModal, onOpenFormSendSMSModal, onOpenCreateAssignModal, onOpenCreateVoiceTemplateModal, onOpenFormExportModal, onOpenFormAutocallModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.Assign]: onCloseCreateAssignModal,
      [ModalType.Preview]: onCloseViewMemberDetalModal,
      [ModalType.Import]: onCloseFormExportModal,
      [ModalType.ChangeStatus]: onCloseFormAutocallModal,
      [ModalType.Adjust]: onCloseCreateVoiceTemplateModal,
      [ModalType.EmailBackup]: onCloseFormSendSMSModal,
      [ModalType.Block]: onCloseAssignUser,
    }),
    [onCloseCreateModal, onCloseAssignUser, onCloseCreateAssignModal, onCloseViewMemberDetalModal, onCloseFormSendSMSModal, onCloseCreateVoiceTemplateModal, onCloseFormExportModal, onCloseFormAutocallModal]
  );

  const { data: members, refetch } = useQueryGetListHotline({ ...filter, campaign_id: id }, { enabled: isLoggedIn && !!id });
  const exportPhoneTemplate = useExportPhoneTemplate({}, { responseType: 'arraybuffer' });

  const handleExportTemplate = () => {
    exportPhoneTemplate.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'mission-call-template');
        toast.showMessageSuccess('Tải xuống nhiệm vụ gọi thành công');
      },
      onError: () => {
        toast.showMessageError('Tải xuống nhiệm vụ gọi thất bại');
      },
    });
  }

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };
  
  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setSearchTitle('');
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                Chi tiết nhiệm vụ gọi
                </Text>
                {/* {
                  [Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) &&
                  <Flex alignItems={'center'} gap={4}>
                    <Flex alignItems="center">
                      <Button
                        bg="#3182ce"
                        color="#fff"
                        _hover={{ bg: '#67a1d7' }}
                        onClick={() => {
                          // inputImportRef?.current?.click();
                          onOpenFormExportModal();
                        }}
                      >
                        Tạo hotline list
                      </Button>
                    </Flex> */}
                    {/* <Flex alignItems="center">
                      <Button
                        bg="#3182ce"
                        color="#fff"
                        _hover={{ bg: '#67a1d7' }}
                        onClick={handleExportTemplate}
                      >
                        Tải xuống template
                      </Button>
                    </Flex> */}
                  {/* </Flex>
                } */}
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm theo tên</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={members?.data || []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={members?.pagination?.page}
              pageLength={members?.pagination?.pageSize}
              totalRecords={members?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isOpenViewMemberDetailModal && (
        <ModalShowDetailMember isOpen={isOpenViewMemberDetailModal} onClose={handleCloseModal} memberDetail={sizeEditing} />
      )}
      {isCreateModalOpen && (
        <FormPhone companyDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isCreateModalAssignOpen && (
        <FormImportPhone companyDetail={sizeEditing} isOpen={isCreateModalAssignOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isCreateModalVoiceTemplateOpen && (
        <FormVoiceTemplateAutoCall companyDetail={sizeEditing} isOpen={isCreateModalVoiceTemplateOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
       {isFormSendSMSModalOpen && (
        <FormSendSms companyDetail={sizeEditing} isOpen={isFormSendSMSModalOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isFormExportModalOpen && (
        <FormExportPhone companyDetail={sizeEditing} isOpen={isFormExportModalOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isFormAutocallModalOpen && (
        <FormAutoCall companyDetail={sizeEditing} isOpen={isFormAutocallModalOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
       {isAssignUserOpen && (
        <AssignUser companyDetail={sizeEditing} isOpen={isAssignUserOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
    </Flex>
  );
}

export default ListHotline;
