import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { ModalType, StatusHotLineFilterOption } from 'constants/common';
import { toast } from 'components/Toast';
import { useUpdatePhone, usecreatePhone } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { RecallValidate } from 'utils/validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useActionRecallMutation } from 'services/campaign';

const FormRecall = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createPhone = usecreatePhone();
  const updatePhone = useUpdatePhone();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(RecallValidate),
  });
  const actionRecallMutation = useActionRecallMutation();

  const onSubmit = async (values) => {
    actionRecallMutation.mutate(
      { campaign_id: companyDetail?._id, status: values?.status?.map(item => item?.value) },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Gọi lại thành công');
          refetch?.();
          onClose(ModalType.ChangeStatus);
        },
        onError: () => {
          toast.showMessageError('Gọi lại không thành công');
        },
      }
    );
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.ChangeStatus);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Gọi lại</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController
              styleContainer={{
                marginTop: '10px',
              }}
              isMulti
              isRequired
              label="Trạng thái"
              name="status"
              placeholder="Chọn trạng thái"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={StatusHotLineFilterOption}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.ChangeStatus);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createPhone.isPending || updatePhone.isPending} onClick={handleSubmit(onSubmit)}>
              Gọi
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormRecall;
