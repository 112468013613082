import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { FORMAT_DATE, ModalType } from 'constants/common';
import moment from 'moment';

const DetailModal = ({ isOpen, onClose, companyDetail }) => {
  const cancelRef = React.useRef();
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Detail);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết cuộc gọi</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <label>Số máy chủ</label>
            <Input mb={2} value={companyDetail?.extension || '--'} readOnly />
            <label>Số điện thoại</label>
            <Input mb={2} value={companyDetail?.phone?.phone} readOnly />
            <label>Người dùng</label>
            <Input mb={2} value={companyDetail?.user?.username} readOnly />
            <label>Chi nhánh</label>
            <Input mb={2} value={companyDetail?.company?.name || '--'} readOnly />
            <label>Thời lượng</label>
            <Input mb={2} value={companyDetail?.duration} readOnly />
            <label>Địa chỉ IP</label>
            <Input mb={2} value={companyDetail?.ip} readOnly />
            <label>Trạng thái cuộc gọi</label>
            <Input mb={2} value={companyDetail?.callstatus} readOnly />
            <label>Hotline</label>
            <Input mb={2} value={companyDetail?.hotline} readOnly />
            <label>Đoạn ghi âm cuộc gọi</label>
            <Input mb={2} value={companyDetail?.pathRecordFile} readOnly />
            <label>Thời gian bắt đầu trả lời</label>
            <Input mb={2} value={companyDetail?.timeAnswer} readOnly />
            <label>Thời gian kết thúc cuộc gọi</label>
            <Input mb={2} value={companyDetail?.timeCdr} readOnly />
            <label>Thời gian đổ chuông</label>
            <Input mb={2} value={companyDetail?.timeRing} readOnly />
            <label>Ngày tạo</label>
            <Input mb={2} value={moment(companyDetail?.createdAt).format(FORMAT_DATE)} readOnly />
            <label>Ngày cập nhật</label>
            <Input mb={2} value={moment(companyDetail?.updatedAt).format(FORMAT_DATE)} readOnly />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Detail);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DetailModal;
