import React, { useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { ModalType, StatusHotLineFilterOption } from 'constants/common';
import { toast } from 'components/Toast';
import { useRecallAutodialerHotlineListMutation } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { RecallValidate } from 'utils/validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useActionCampaignAutodialerMutation } from 'services/campaign';
import { isEmpty } from 'lodash';

const FormRecall = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(RecallValidate),
  });
  const [loading, setLoading] = useState(false);
  const actionRecallMutation = useRecallAutodialerHotlineListMutation();
  const actionCampaignMutation = useActionCampaignAutodialerMutation();

  const onSubmit = async values => {
    setLoading(true);
    actionRecallMutation.mutate(
      { list_id: companyDetail?._id, status: values?.status?.map(item => item?.value) },
      {
        onSuccess: res => {
          if (!isEmpty(res?.data)) {
            actionCampaignMutation.mutate({ campaign_id: res?.data?.campaign?._id, type: 'start' });
            toast.showMessageSuccess('Gọi lại thành công');
            refetch?.();
            onClose(ModalType.Import);
          }
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        }
      }
    );
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Import);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Gọi lại</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController
              styleContainer={{
                marginTop: '10px',
              }}
              isMulti
              isRequired
              label="Trạng thái"
              name="status"
              placeholder="Chọn trạng thái"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={StatusHotLineFilterOption}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Import);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={loading} onClick={handleSubmit(onSubmit)}>
              Gọi
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormRecall;
