import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getZalo = params => request.get('/api/v1/zalo/get-list', { params });
export const getZaloSettings = params => request.get('/api/v1/zalo/settings', { params });
export const getZaloUrlByAppId = data => request.post('/api/v1/zalo-auth', data);
export const updateZaloOAMutation = data => request.post('/api/v1/zalo/update', data);
export const createZaloOAMutation = data => request.post('/api/v1/zalo/create', data);

// Mutation
export const useGetZaloUrlByAppId = () => useMutation({ mutationFn: getZaloUrlByAppId });
export const useCreateZaloOA = () => useMutation({ mutationFn: createZaloOAMutation });
export const useUpdateZaloOA = () => useMutation({ mutationFn: updateZaloOAMutation });

// Query
export const useQueryGetZalo = (params = {}, options = {}) => useQuery({ queryKey: ['getZalo', params], queryFn: () => getZalo(params), ...options });
export const useQueryGetZaloSettings = (params = {}, options = {}) => useQuery({ queryKey: ['getZaloSettings', params], queryFn: () => getZaloSettings(params), ...options });
