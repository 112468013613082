import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getCampaign = params => request.get('/api/v1/hotlineCampaign', { params });
export const getCampaignVoice = params => request.get('/api/v1/hotlineCampaign/auto-voices', { params });
export const getCampaignTemplate = params => request.get('/api/v1/hotlineCampaign/auto-templates', { params });
export const getCampaignAssign = params => request.get('/api/v1/hotlineCampaign/list-assign', { params });
export const deleteCampaign = data => request.post('/api/v1/hotlineCampaign/delete', data);
export const createCampaign = data => request.post('/api/v1/hotlineCampaign', data);
export const updateCampaign = data => request.post('/api/v1/hotlineCampaign/update', data);
export const updateCampaignSMS = data => request.post('/api/v1/hotlineCampaign/update-sms', data);
export const actionCampaign = data => request.post('/api/v1/hotlineCampaign/action', data);
export const actionCampaignAutodialer = data => request.post('/api/v1/hotlineCampaign/action-autodialer', data);
export const createCampaignSMS = data => request.post('/api/v1/hotlineCampaign/sms', data);
export const createHotlineVoiceCampaign = data => request.post('/api/v1/hotlineCampaign/voice-template', data);
export const createHotlineAutodialerCampaign = data => request.post('/api/v1/hotlineCampaign/autoDialer', data);
export const pauseStaffHotlineAutodialerCampaign = data => request.post('/api/v1/extensions/pause', data);
export const receiveStaffHotlineAutodialerCampaign = data => request.post('/api/v1/extensions/listen', data);
export const receiveCalltaffHotlineAutodialerCampaign = data => request.post('/api/v1/extensions/barge', data);
export const statusJoinStaffHotlineAutodialerCampaign = data => request.post('/api/v1/extensions/join', data);
export const actionRecallMutation = data => request.post('/api/v1/hotline/recall-auto-call', data);
export const exportPhone = (params, headers) => request.get('/api/v1/phone/export', { params, ...headers });

// Mutation
export const useActionRecallMutation = () => useMutation({ mutationFn: actionRecallMutation });
export const useActionExportPhoneMutation = (params, headers) => useMutation({ mutationFn: () => exportPhone(params, headers) });
export const useDeleteCampaignMutation = () => useMutation({ mutationFn: deleteCampaign });
export const useCreateCampaignMutation = () => useMutation({ mutationFn: createCampaign });
export const useUpdateCampaignMutation = () => useMutation({ mutationFn: updateCampaign });
export const useActionCampaignMutation = () => useMutation({ mutationFn: actionCampaign });
export const useActionCampaignAutodialerMutation = () => useMutation({ mutationFn: actionCampaignAutodialer });
export const useActionCampaignSmsMutation = () => useMutation({ mutationFn: updateCampaignSMS });
export const useCreateCampaignSMSMutation = () => useMutation({ mutationFn: createCampaignSMS });
export const useCreateHotlineVoiceCampaignMutation = () => useMutation({ mutationFn: createHotlineVoiceCampaign });
export const useCreateHotlineAutodialerCampaignMutation = () => useMutation({ mutationFn: createHotlineAutodialerCampaign });
export const usePauseStaffHotlineAutodialerCampaignMutation = () => useMutation({ mutationFn: pauseStaffHotlineAutodialerCampaign });
export const useStatusJoinHotlineAutodialerCampaignMutation = () => useMutation({ mutationFn: statusJoinStaffHotlineAutodialerCampaign });
export const useReceiveHotlineAutodialerCampaignMutation = () => useMutation({ mutationFn: receiveStaffHotlineAutodialerCampaign });
export const useReceiveCallHotlineAutodialerCampaignMutation = () => useMutation({ mutationFn: receiveCalltaffHotlineAutodialerCampaign });

// Query
export const useQueryGetCampaign = (params = {}, options = {}) => useQuery({ queryKey: ['getCampaign', params], queryFn: () => getCampaign(params), ...options });
export const useQueryGetCampaignVoice = (params = {}, options = {}) => useQuery({ queryKey: ['getCampaignVoice', params], queryFn: () => getCampaignVoice(params), ...options });
export const useQueryGetCampaignTemplate = (params = {}, options = {}) => useQuery({ queryKey: ['getCampaignTemplate', params], queryFn: () => getCampaignTemplate(params), ...options });
export const useQueryGetCampaignAssign = (params = {}, options = {}) => useQuery({ queryKey: ['getCampaignAssign', params], queryFn: () => getCampaignAssign(params), ...options });
