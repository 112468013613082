import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';

const InputCustom = forwardRef(({ value, onClick }, ref) => (
  <Input className="example-custom-input" onClick={onClick} ref={ref} value={value} />
));

const DatePickerController = ({
  name,
  label,
  control,
  styleContainer,
  styleBoxInput,
  isRequired,
  placeholder,
  options,
  isDisabled,
  extendsComponent,
  onChange,
  maxLimit,
  valueList,
  dateFormat,
  placeholderText,
  handleDisabledSelect,
  ...props
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <FormControl {...styleContainer} isRequired={isRequired} isInvalid={error && error?.message}>
            {label &&
              (!extendsComponent ? (
                <FormLabel minW="150px">{label}</FormLabel>
              ) : (
                <Flex>
                  <FormLabel>{label}</FormLabel>
                  <Box>{extendsComponent}</Box>
                </Flex>
              ))}
            <DatePicker
              name={name}
              placeholderText={placeholderText}
              selected={field.value}
              dateFormat={dateFormat || 'yyyy-MM-dd'}
              onChange={field.onChange}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              closeOnScroll={true}
              showTimeSelect
              timeFormat="HH:mm"
              disabledKeyboardNavigation
              customInput={<InputCustom />}
              ref={field?.current?.focus()}
              {...props}
              {...field}
            />
            <FormErrorMessage>{error && error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </>
  );
};

export default DatePickerController;
