import moment from 'moment-timezone';

export const isJsonString = str => {
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export function rangeDesc(start, end) {
  return Array(start - end + 1)
    .fill()
    .map((_, idx) => start - idx);
}

export const today = moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD');

export const formatDate = (date, format = 'MM/DD/YYYY') => moment(date).format(format);

export const getInitFilerChart = () => ({
  startDate: new Date(formatDate(moment(new Date()).subtract(6, 'days').add(1, 'day'))),
  endDate: new Date(formatDate(moment(new Date()).add(1, 'day'))),
});

export const downloadFile = (file, name) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getDomainUrl = url => {
  const matchResult = url?.match(/^https?:\/\/([a-zA-Z0-9.-]+)\.com$/);
  if (matchResult && matchResult.length === 2) {
    return matchResult[1];
  }
};

export const getUrlWebsite = domain => {
  return 'https://' + domain + '.com';
};

export const isObjectNameInArray = (targetName, array, key) => array?.some(element => element[key] === targetName);

export const convertToTitleCase = str => {
  const words = str.split('_');
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const convertedStr = capitalizedWords.join(' ');
  return convertedStr;
};

export function formatSecondsToHHMMSS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return {
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  };
}

export function obfuscatePhoneNumber(phoneNumber) {
  const digits = phoneNumber.replace(/\D/g, '');
  const obfuscatedDigits = digits.slice(0, 4) + '***' + digits.slice(-3);
  return obfuscatedDigits;
}

export function formatCurrency(number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export const getMediaPathHttps = (mediaDomain, path) => {
  if (path?.includes('https')) {
    return path;
  }

  return `${mediaDomain}${path}`;
};

export const convertToUTCDateString = (timestamp) => {
  const date = new Date(timestamp);
  const utcDateString = date.toISOString();
  const parts = utcDateString.split('T');
  return '2882-08-20T' + parts[1];
}

export const convertToDateTimestamp = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return null;
  }
  const timestamp = date.getTime();
  return timestamp;
}

export const formatTime = (startTime, endTime, listDaySelected) => {
  const schedules = [
    {
      monday_start_time: 28800000000000,
      monday_end_time: 75600000000000,
      tuesday_start_time: 28800000000000,
      tuesday_end_time: 75600000000000,
      wednesday_start_time: 28800000000000,
      wednesday_end_time: 75600000000000,
      thursday_start_time: 28800000000000,
      thursday_end_time: 75600000000000,
      friday_start_time: 28800000000000,
      friday_end_time: 75600000000000,
      saturday_start_time: 28800000000000,
      saturday_end_time: 75600000000000,
      sunday_start_time: 28800000000000,
      sunday_end_time: 75600000000000,
    },
  ];
  listDaySelected?.forEach(day => {
    schedules[0][`${day.value}_start_time`] = startTime;
    schedules[0][`${day.value}_end_time`] = endTime;
  });
  return schedules;
};

export function findMissingObjects(arr1, arr2, key) {
  return arr1.filter(obj1 => !arr2.some(obj2 => obj1[key] === obj2[key]));
}
