import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Tooltip, Text } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType, Roles, ROOT_API } from 'constants/common';
import moment from 'moment';
import { toast } from 'components/Toast';
import { DeleteIcon, EditIcon, PhoneIcon } from '@chakra-ui/icons';
import { useActionCampaignMutation, useActionExportPhoneMutation, useDeleteCampaignMutation } from 'services/campaign';
import { VscDebugStart } from 'react-icons/vsc';
import { FaFileDownload, FaRegStopCircle } from 'react-icons/fa';
import { MdAssignmentTurnedIn, MdCall, MdHistory, MdPhoneCallback } from 'react-icons/md';
import { CiBoxList, CiImport } from 'react-icons/ci';
import { useHistory } from 'react-router-dom';
import { useVoiceTemplateAutoCallMutation } from 'services/user';
import { useUserState } from 'context/UserContext';
import { downloadFile } from 'utils/helpers';
import { CookieStorage } from 'utils/cookie-storage';
import axios from 'axios';
import { BiMessageSquareDetail } from 'react-icons/bi';

const SizeTable = ({ categorysData, refetch, handleUpdateCategory }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteCampaignMutation = useDeleteCampaignMutation();
  const actionCampaignMutation = useActionCampaignMutation();
  const history = useHistory();
  const voiceTemplateAutoCallMutation = useVoiceTemplateAutoCallMutation();
  const { userInfo } = useUserState();

  const handleDeleteSize = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    deleteCampaignMutation.mutate(
      { campaign_id: campaign?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleStart = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn bắt đầu chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    actionCampaignMutation.mutate(
      { campaign_id: campaign?._id, type: 'start' },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Bắt đầu chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Bắt đầu chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleStop = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn dừng chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    actionCampaignMutation.mutate(
      { campaign_id: campaign?._id, type: 'stop' },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Dừng chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Dừng chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleCallVoiceTemplate = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn gọi mẫu này không?');
    if (!confirmDelete) {
      return;
    }
    const payload = {
      campaign_id: campaign?._id,
    };
    voiceTemplateAutoCallMutation.mutate(payload, {
      onSuccess: () => {
        toast.showMessageSuccess('Gọi văn bản thành công');
        refetch?.();
      },
    });
  };

  const handleDowloadListPhone = async campaign => {
    const res = await axios.get(ROOT_API + '/api/v1/phone/export', {
      headers: { 'Content-Type': 'application/json', xToken: CookieStorage.getAccessToken() },
      responseType: 'arraybuffer',
      params: {
        campaignId: campaign?._id,
      },
    });
    if (res.status === 200) {
      downloadFile(res?.data, 'list-phone');
      toast.showMessageSuccess('Tải danh sách số điện thoại thành công');
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('campaign_name', {
        header: 'Tên',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('total_phone', {
        header: 'Tổng số điện thoại',
        cell: info => (
          <Text fontSize="20px" color="blue.500">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('total_success', {
        header: 'Tổng số điện thoại thành công',
        cell: info => (
          <Text fontSize="20px" color="blue.500">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('total_failed', {
        header: 'Tổng số điện thoại thất bại',
        cell: info => (
          <Text fontSize="20px" color="tomato">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('processing', {
        header: 'Tiến trình',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('concurrent_calc', {
        header: 'Số lượng cuộc gọi đồng thời',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('timeRing', {
        header: 'Ngày tạo',
        cell: info => info.getValue() ? moment(info.getValue()).utc().format(FORMAT_DATE) : '--',
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            {userInfo?.role !== Roles.LEADER && (
              <Tooltip label="Phân bổ" aria-label="A tooltip" rounded={'6px'}>
                <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Assign)}>
                  <MdAssignmentTurnedIn cursor="pointer" boxSize={4} />
                </IconButton>
              </Tooltip>
            )}
            {([Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) ||
              userInfo?._id === info?.row?.original?.createBy?._id ||
              userInfo?._id === info?.row?.original?.user?._id) && (
              <>
                {info?.row?.original?.campaign_uuid && [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.LEADER].includes(userInfo?.role) && (
                  <>
                    {info?.row?.original?.status && 'STOP' && (
                      <Tooltip label="Bắt đầu" aria-label="A tooltip" rounded={'6px'}>
                        <IconButton bg="transparent" onClick={() => handleStart(info?.row?.original)}>
                          <VscDebugStart cursor="pointer" boxSize={4} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {info?.row?.original?.status === 'FINISHED' && (
                      <Tooltip label="Gọi lại" aria-label="A tooltip" rounded={'6px'}>
                        <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.ChangeStatus)}>
                          <MdPhoneCallback cursor="pointer" boxSize={4} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip label="Dừng" aria-label="A tooltip" rounded={'6px'}>
                      <IconButton bg="transparent" onClick={() => handleStop(info?.row?.original)}>
                        <FaRegStopCircle cursor="pointer" boxSize={4} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <Tooltip label="Tải danh sách số điện thoại" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleDowloadListPhone(info?.row?.original);
                    }}
                  >
                    <FaFileDownload cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
                <Tooltip label="Lịch sử" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton
                    bg="transparent"
                    onClick={() => {
                      history.push(`/admin/histories-hotline/${info?.row?.original?._id}`);
                    }}
                  >
                    <MdHistory cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
                {info?.row?.original?.status === 'PENDING' && (
                  <Tooltip label="Gọi" aria-label="A tooltip" rounded={'6px'}>
                    <IconButton bg="transparent" onClick={() => handleCallVoiceTemplate(info?.row?.original)}>
                      <PhoneIcon cursor="pointer" boxSize={4} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip label="Chi tiết" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Detail)}>
                <BiMessageSquareDetail cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
            {(([Roles.LEADER].includes(userInfo?.role) && userInfo?._id === info?.row?.original?.createBy?._id) ||
              [Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role)) && (
              <>
                {/* <Tooltip label="Import số điện thoại" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Import)}>
                    <CiImport cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip> */}
                {info?.row?.original?.status === 'PENDING' && (
                  <Tooltip label="Chỉnh sửa" aria-label="A tooltip" rounded={'6px'}>
                    <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Add)}>
                      <EditIcon cursor="pointer" boxSize={4} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original)}>
                    <DeleteIcon color="red" cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
