import { CONVERT_UPPERCASE_REGEX, KEY_REGEX, StatusHotLine, convertEnumToLabelOption } from "constants/common";

export const mappingCustomers = data =>
  data?.map(item => ({
    name: item?.name,
    email: item?.email,
    code: item?.code,
    id: item?._id,
  }));

export const mappingOptionSelect = (data, labelKey = 'name', valueKey = '_id') =>
  data?.map(item => ({
    label: item?.[labelKey],
    value: item?.[valueKey],
  }));

export const mappingChannel = data =>
  data?.map(item => ({
    name: item?.name,
    manager: item?.manager,
    customers: mappingOptionSelect(item?.customers),
    id: item?._id,
    isActive: item?.isActive,
  }));

export const mappingChannelFormData = data => ({
  ...(data?.id && { id: data?.id }),
  name: data?.name,
  customerIds: data?.customers?.map(customerItem => customerItem?.value).join(','),
});

export const mappingCampaign = data =>
  data?.map(item => ({
    id: item?._id,
    name: item?.name,
  }));

export const mappingCampaignFormData = data => ({
  ...(data?.id && { id: data?.id }),
  name: data?.name,
  channelIds: data?.channels?.map(item => item?.value).join(','),
});

export const mappingUsers = data =>
  data?.map(item => ({
    username: item?.username,
    role: item?.role,
    email: item?.email,
    id: item?._id,
  }));

export const mappingTemplate = data => data?.map(item => ({ id: item?._id, name: item?.name, content: item?.content }));

export const mappingOptionsFromArrString = (arrString) => {
  return (arrString).map(remove => ({
      label: convertEnumToLabelOption(remove),
      value: remove,
  }))
}

export const mappingOptionCategoryDirectLinkFormData = (arrString) => {
  return (arrString).map(remove => ({
      label: remove.toLowerCase().replace(CONVERT_UPPERCASE_REGEX, l => l.toUpperCase()),
      value: remove,
  }))
}

export function processCallStatus(callstatus) {
  // Khởi tạo mảng result với 7 phần tử
  const result = [
    { "_id": "cancel", "count": 0, "billsec": 0 },
    { "_id": "answered", "count": 0, "billsec": 0 },
    { "_id": "no_answered", "count": 0, "billsec": 0 },
    { "_id": "busy", "count": 0, "billsec": 0 },
    { "_id": "invalid_number", "count": 0, "billsec": 0 },
    { "_id": "not_available", "count": 0, "billsec": 0 },
  ];

  // Duyệt qua mảng callstatus và cập nhật các giá trị tương ứng trong mảng result
  callstatus.forEach(status => {
    const index = result.findIndex(item => item._id === status._id);
    if (index !== -1) {
      result[index].count = status.count;
      result[index].billsec = status.billsec;
    } else {
      // Nếu không tìm thấy _id trong mảng result, thêm vào phần tử mới
      result.push({
        "_id": status._id,
        "count": status.count,
        "billsec": status.billsec
      });
    }
  });

  // Sử dụng StatusHotLine để lấy tên hiển thị tương ứng với mỗi _id
  return result.map(status => ({
    "_id": status._id,
    "label": StatusHotLine[status._id] || status._id,
    "count": status.count,
    "billsec": status.billsec
  }));
}
