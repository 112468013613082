import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getQuotation = params => request.get('/api/v1/quotations', { params });
export const changeStatusQuotation = data => request.post('/api/v1/quotations/change-status', data);
export const deleteQuotation = data => request.post('/api/v1/quotations/delete', data);
export const createQuotation = data => request.post('/api/v1/quotations/create', data);
export const updateQuotation = data => request.post('/api/v1/quotations/update', data);

// Mutation
export const useChangeStatusQuotation = () => useMutation({ mutationFn: changeStatusQuotation });
export const useDeleteQuotation = () => useMutation({ mutationFn: deleteQuotation });
export const useCreateQuotation = () => useMutation({ mutationFn: createQuotation });
export const useUpdateQuotation = () => useMutation({ mutationFn: updateQuotation });

// Query
export const useQueryGetQuotation = (params = {}, options = {}) => useQuery({ queryKey: ['getQuotation', params], queryFn: () => getQuotation(params), ...options });
