import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListIp = params => request.get('/api/v1/ips', { params });
export const createIp = data => request.post('/api/v1/ips', data);
export const deleteIp = data => request.post('/api/v1/ips/delete', data);
export const dropCollectionIps = data => request.post('/api/v1/ip/drop-collection', data);
export const getListPhone = params => request.get('/api/v1/missions', { params });
export const getListPhoneMission = (id, params) => request.get(`/api/v1/phone/list-in-campagin/${id}`, { params});
export const getListPhoneHotlineAutodialer = params => request.get(`/api/v1/lists/lead`, { params });
export const getListPhoneAssign = params => request.get('/api/v1/phone/list-assign', { params });
export const createPhone = data => request.post('/api/v1/phone/import', data);
export const updatePhone = data => request.post('/api/v1/phone/update', data);
export const deletePhone = data => request.post('/api/v1/phone/delete', data);
export const callHotline = data => request.post('/api/v1/hotline/call', data);
export const autoCallHotline = data => request.post('/api/v1/hotline/auto-call', data);
export const createHotlineTemplate = data => request.post('/api/v1/hotlineTemplate', data);
export const createHotlineVoiceTemplate = data => request.post('/api/v1/hotlineTemplate/voice', data);
export const deleteHotlineTemplate = data => request.post('/api/v1/hotlineTemplate/delete', data);
export const historiesHotline = params => request.get('/api/v1/hotline', { params });
export const historiesHotlineAutodialer = params => request.get('/api/v1/hotline/history/auto-dialer', { params });
export const exportHistoriesHotlineAutodialer = (params, headers) => request.get('/api/v1/hotline/export-history/auto-dialer', { params, headers });
export const hotlineTemplate = params => request.get('/api/v1/hotlineTemplate', { params });
export const hotlineTemplateAssign = params => request.get('/api/v1/hotlineTemplate/list-template', { params });
export const historyHotlineAutoCall = params => request.get('/api/v1/hotline/history/auto-call-template', { params });
export const getExtensions = params => request.get('/api/v1/extensions', { params });
export const changeStatusExtension = data => request.post('/api/v1/extensions/update-mutiple', data);
export const getExtensionsAssign = params => request.get('/api/v1/extensions/list-assign', { params });
export const getHistorySms = params => request.get('/api/v1/hotline/history/send-sms', { params });
export const getFileRecord = params => request.get('/api/v1/hotline/record-file', { params });
export const getHotlineList = params => request.get('/api/v1/lists', { params });
export const createHotlineList = data => request.post('/api/v1/lists', data);
export const addGroupHotlineList = data => request.post('/api/v1/lists/add-group', data);
export const staffHotlineList = params => request.get('/api/v1/groups/list-staffs', { params });
export const deleteHotlineList = data => request.post('/api/v1/lists/delete', data);
export const recallAutodialerHotlineList = data => request.post('/api/v1/lists/recall-autodialer', data);
export const createRecordFile = data => request.post('/api/v1/hotline/create-record-file', data);
export const changeStatusRecordFile = data => request.post('/api/v1/hotline/publish-record-file', data);
export const uploadLoadFile = (data, config) =>
  request.post('/api/v1/hotline/upload-record-file', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const exportHotlineTemplate = (params, headers) => request.get('/api/v1/hotline/export-history', { params, ...headers });
export const exportHotlineAutoCallTemplate = (params, headers) =>
  request.get('/api/v1/hotline/export-history/auto-call', { params, ...headers });
export const exportPhoneTemplate = (params, headers) => request.get('/api/v1/phone/export-template', { params, ...headers });
export const importPhoneTemplate = (data, config) =>
  request.post('/api/v1/phone/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const importPhoneCampagin = (data, config) =>
  request.post('/api/v1/phone/import-for-campaign', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const importPhoneHotlineList = (data, config) =>
  request.post('/api/v1/lists/import-phone', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });

// Mutation
export const useCreateIp = () => useMutation({ mutationFn: createIp });
export const useChangeStatusExtensionMutation = () => useMutation({ mutationFn: changeStatusExtension });
export const useRecallAutodialerHotlineListMutation = () => useMutation({ mutationFn: recallAutodialerHotlineList });
export const useAddGroupMutation = () => useMutation({ mutationFn: addGroupHotlineList });
export const useDeleteHotlineList = () => useMutation({ mutationFn: deleteHotlineList });
export const useDeleteIp = () => useMutation({ mutationFn: deleteIp });
export const useCreateHotlineListMutation = () => useMutation({ mutationFn: createHotlineList });
export const useUpdatePhone = () => useMutation({ mutationFn: updatePhone });
export const useDeletePhone = () => useMutation({ mutationFn: deletePhone });
export const usecreatePhone = () => useMutation({ mutationFn: createPhone });
export const useCallHotline = () => useMutation({ mutationFn: callHotline });
export const useAutoCallHotline = () => useMutation({ mutationFn: autoCallHotline });
export const useChangeStatusRecordFile = () => useMutation({ mutationFn: changeStatusRecordFile });
export const useDropCollectionIps = () => useMutation({ mutationFn: dropCollectionIps });
export const useCreateRecordFile = () => useMutation({ mutationFn: createRecordFile });
export const useCreateRecordTemplate = () => useMutation({ mutationFn: createHotlineTemplate });
export const useCreateHotlineVoiceTemplate = () => useMutation({ mutationFn: createHotlineVoiceTemplate });
export const useDeleteHotlineTemplate = () => useMutation({ mutationFn: deleteHotlineTemplate });
export const useExportPhoneTemplate = (params, headers) => useMutation({ mutationFn: () => exportPhoneTemplate(params, headers) });
export const useExportHotlineAutoCallTemplate = (params, headers) =>
  useMutation({ mutationFn: () => exportHotlineAutoCallTemplate(params, headers) });
export const useExportHotlineTemplate = (params, headers) => useMutation({ mutationFn: () => exportHotlineTemplate(params, headers) });
export const useExportHistoriesHotlineAutodialer = (params, headers) => useMutation({ mutationFn: () => exportHistoriesHotlineAutodialer(params, headers) });

// Query
export const useQueryGetListIp = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListIp', params], queryFn: () => getListIp(params), ...options });
export const useQueryGetListFileRecord = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getFileRecord', params], queryFn: () => getFileRecord(params), ...options });
export const useQueryGetExtensions = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getExtensions', params], queryFn: () => getExtensions(params), ...options });
export const useQueryGetExtensionsAssign = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getExtensionsAssign', params], queryFn: () => getExtensionsAssign(params), ...options });
export const useQueryGetListPhone = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListPhone', params], queryFn: () => getListPhone(params), ...options });
export const useQueryGetListPhoneAssign = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListPhoneAssign', params], queryFn: () => getListPhoneAssign(params), ...options });
export const useQueryGetHistoriesHotline = (params = {}, options = {}) =>
  useQuery({ queryKey: ['historiesHotline', params], queryFn: () => historiesHotline(params), ...options });
export const useQueryGetHistoriesHotlineAutodialer = (params = {}, options = {}) =>
  useQuery({ queryKey: ['historiesHotlineAutodialer', params], queryFn: () => historiesHotlineAutodialer(params), ...options });
export const useQueryGetHotlineTemplates = (params = {}, options = {}) =>
  useQuery({ queryKey: ['hotlineTemplate', params], queryFn: () => hotlineTemplate(params), ...options });
export const useQueryGetHistoryHotlineAutocall = (params = {}, options = {}) =>
  useQuery({ queryKey: ['historyHotlineAutoCall', params], queryFn: () => historyHotlineAutoCall(params), ...options });
export const useQueryGetHotlineTemplateAssign = (params = {}, options = {}) =>
  useQuery({ queryKey: ['hotlineTemplateAssign', params], queryFn: () => hotlineTemplateAssign(params), ...options });
export const useQueryGetListPhoneMission = (id, params = {}, options = {}) =>
  useQuery({ queryKey: ['getListPhoneMission', params], queryFn: () => getListPhoneMission(id, params), ...options });
export const useQueryGetListHistorySms = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getHistorySms', params], queryFn: () => getHistorySms(params), ...options });
export const useQueryGetListHotline = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getHotlineList', params], queryFn: () => getHotlineList(params), ...options });
export const useQueryGetStaffListHotline = (params = {}, options = {}) =>
  useQuery({ queryKey: ['staffHotlineList', params], queryFn: () => staffHotlineList(params), ...options });
export const useQueryGetPhoneHotlineAutodialer = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListPhoneHotlineAutodialer', params], queryFn: () => getListPhoneHotlineAutodialer(params), ...options });
