import {
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
  } from '@chakra-ui/react';
  import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
  import { useMemo, useState } from 'react';
  import isEmpty from 'lodash/isEmpty';
import { StatusHotLine } from 'constants/common';
  
  const ReportTable = ({ categorysData }) => {
    const [sorting, setSorting] = useState([]);
    const columnHelper = createColumnHelper();
  
    const columns = useMemo(
      () => [
        columnHelper.accessor('_id', {
          header: 'Trạng thái',
          cell: info => StatusHotLine[(info.getValue()?.replace(/-/g, "_"))] || '--',
        }),
        columnHelper.accessor('billsec', {
          header: 'Thời gian gọi',
          cell: info => info.getValue(),
        }),
        columnHelper.accessor('count', {
          header: 'Số lượng đã gọi',
          cell: info => info.getValue(),
        }),
      ],
      [categorysData]
    );
  
    const table = useReactTable({
      data: categorysData || [],
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: true,
    });
  
    return (
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id} w="120px">
                  {header.isPlaceholder ? null : (
                    <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={7}>
                Không có dữ liệu
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    );
  };
  
  export default ReportTable;
  