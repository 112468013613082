import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getHotlineManual = params => request.get('/api/v1/hotline/history/call-manual', { params });
export const callManual = data => request.post('/api/v1/hotline/call-manual', data);
export const exportHistoryManual = (params, headers) => request.get('/api/v1/hotline/export-history-call-manual', { params, headers });

// Mutation
export const useCallManualMutation = () => useMutation({ mutationFn: callManual });
export const useExportHistoryManual = () => useMutation({ mutationFn: exportHistoryManual });

// Query
export const useQueryGetHotlineManual = (params = {}, options = {}) => useQuery({ queryKey: ['getHotlineManual', params], queryFn: () => getHotlineManual(params), ...options });
