import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useClipboard,
  Flex,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { FORMAT_DATE, ModalType } from 'constants/common';
import moment from 'moment';
import { useGetZaloUrlByAppId } from 'services/zalo';

const ModalShowDetailMember = ({ isOpen, onClose, memberDetail }) => {
  const cancelRef = React.useRef();
  const getZaloUrlByAppId = useGetZaloUrlByAppId();
  const [urlZalo, setUrlZalo] = React.useState('');
  const { onCopy, hasCopied } = useClipboard(urlZalo);

  const handleGetUrlZalo = () => {
    getZaloUrlByAppId.mutate(
      {
        app_id: memberDetail?.app_id,
      },
      {
        onSuccess: res => {
          setUrlZalo(res?.url);
        },
      }
    );
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết Zalo</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <label>App ID</label>
            <Input mb={2} value={memberDetail?.app_id || '--'} readOnly />
            <label>Tên Chi nhánh</label>
            <Input mb={2} value={memberDetail?.company?.name} readOnly />
            <label>Mô tả Chi nhánh</label>
            <Input mb={2} value={memberDetail?.company?.description} readOnly />
            <label>Secret Key</label>
            <Input mb={2} value={memberDetail?.secret_key || '--'} readOnly />
            <label>Địa chỉ</label>
            <Input mb={2} value={memberDetail?.state || '--'} readOnly />
            <label>Ngày tạo</label>
            <Input mb={2} value={moment(memberDetail?.createdAt).format(FORMAT_DATE)} readOnly />
            <label>Ngày cập nhật</label>
            <Input mb={2} value={moment(memberDetail?.updatedAt).format(FORMAT_DATE)} readOnly />
            <Button mt={6} ref={cancelRef} onClick={handleGetUrlZalo} colorScheme="blue">
              Lấy URL
            </Button>
            {urlZalo && (
              <Flex alignItems={'end'} gap={3} mt={2}>
                <Input mt={3} value={urlZalo} readOnly />
                <Button
                  onClick={() => {
                    onCopy(urlZalo);
                  }}
                >
                  {hasCopied ? 'Copied!' : 'Copy'}
                </Button>
                <a href={urlZalo} target="_blank">
                  <Button
                  >
                    Open
                  </Button>
                </a>
              </Flex>
            )}
          </AlertDialogBody>
          <AlertDialogFooter mt={5}>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Preview);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ModalShowDetailMember;
