import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType, Roles, TYPE_AUTOCALL } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import FormCampaign from './components/FormCampaign';
import { useQueryGetCampaign } from 'services/campaign';
import { useUserState } from 'context/UserContext';
import { MdContactPhone } from 'react-icons/md';
import InputController from 'components/Form/InputController';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CallManual } from 'utils/validation';
import { Select } from 'chakra-react-select';
import { mappingOptionSelect } from 'utils/mapping';
import DatePicker from 'components/DatePicker/DatePicker';
import { useQueryGetGroups } from 'services/group';
import { useQuerGetUsers } from 'services/user';
import FormImportPhone from '../ListHotline/components/FormImportPhone';
import FormVoiceTemplateAutoCall from '../ListHotline/components/FormVoiceTemplateAutoCall';
import FormSendSms from '../ListHotline/components/FormSendSms';
import FormExportPhone from '../ListHotline/components/FormExportPhone';
import FormAutoCall from '../ListHotline/components/FormAutoCall';
import AssignUser from '../ListHotline/components/AssignUser';
import { useQueryGetListHotline } from 'services/ips';
import FormRecall from './components/Recall';

const initFilter = {
  companyId: null,
  status: null,
  extension: null,
};

function HotlineAutodialerCampaign() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [hotlineFilter, setHotlineFilter] = useState(initFilter);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const { userInfo } = useUserState();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(CallManual),
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isFormModalOpen, onOpen: onOpenFormModal, onClose: onCloseFormModal } = useDisclosure();
  const { isOpen: isFormExportModalOpen, onOpen: onOpenFormExportModal, onClose: onCloseFormExportModal } = useDisclosure();
  const { isOpen: isCreateModalAssignOpen, onOpen: onOpenCreateAssignModal, onClose: onCloseCreateAssignModal } = useDisclosure();
  const { isOpen: isCreateModalVoiceTemplateOpen, onOpen: onOpenCreateVoiceTemplateModal, onClose: onCloseCreateVoiceTemplateModal } = useDisclosure();
  const { isOpen: isFormAutocallModalOpen, onOpen: onOpenFormAutocallModal, onClose: onCloseFormAutocallModal } = useDisclosure();
  const { isOpen: isFormSendSMSModalOpen, onOpen: onOpenFormSendSMSModal, onClose: onCloseFormSendSMSModal } = useDisclosure();
  const { isOpen: isAssignUserOpen, onOpen: onOpenAssignUser, onClose: onCloseAssignUser } = useDisclosure();
  const { isOpen: isRecallOpen, onOpen: onOpenRecall, onClose: onCloseRecall } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenFormModal,
      [ModalType.Assign]: onOpenCreateAssignModal,
      [ModalType.Adjust]: onOpenCreateVoiceTemplateModal,
      [ModalType.Import]: onOpenFormExportModal,
      [ModalType.ChangeStatus]: onOpenFormAutocallModal,
      [ModalType.EmailBackup]: onOpenFormSendSMSModal,
      [ModalType.Import]: onOpenRecall,
      [ModalType.Block]: onOpenAssignUser,
    }),
    [onOpenFormModal, onOpenRecall, onOpenAssignUser, onOpenFormSendSMSModal, onOpenCreateAssignModal, onOpenCreateVoiceTemplateModal, onOpenFormExportModal, onOpenFormAutocallModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseFormModal,
      [ModalType.Assign]: onCloseCreateAssignModal,
      [ModalType.Import]: onCloseFormExportModal,
      [ModalType.ChangeStatus]: onCloseFormAutocallModal,
      [ModalType.Adjust]: onCloseCreateVoiceTemplateModal,
      [ModalType.EmailBackup]: onCloseFormSendSMSModal,
      [ModalType.Block]: onCloseAssignUser,
      [ModalType.Import]: onCloseRecall,
    }),
    [onCloseFormModal, onCloseRecall, onCloseAssignUser, onCloseCreateAssignModal, onCloseFormSendSMSModal, onCloseCreateVoiceTemplateModal, onCloseFormExportModal, onCloseFormAutocallModal]
  );

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const { data: campaigns, refetch } = useQueryGetListHotline(
    { ...filter, searchKeyword: filter.searchKeyword },
    { enabled: isLoggedIn }
  );
  // const { data: groups } = useQueryGetGroups({}, { enabled: isLoggedIn });
  // const { data: members } = useQuerGetUsers({}, { enabled: isLoggedIn });

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      ...(hotlineFilter?.companyId && { companyId: hotlineFilter?.companyId?.value }),
      ...(hotlineFilter?.status && { status: hotlineFilter?.status?.value }),
      ...(hotlineFilter?.extension && { extension: hotlineFilter?.extension?.label }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setHotlineFilter(initFilter);
    setSearchTitle('');
  };

  const onSubmit = async values => {
    callManualMutation.mutate(
      {
        phone: values?.phone,
      },
      {
        onSuccess: () => handleSuccess(`Gọi thủ công thành công`),
      }
    );
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const handleExport = () => {};
  return (
    <Flex
      direction="column"
      pt={userInfo?.role === Roles.STAFF ? { base: '70px', md: '15px', lg: '0px' } : { base: '120px', md: '75px', lg: '100px' }}
    >
      {userInfo?.role === Roles.STAFF && (
        <Card p="16px" mb="24px" bg="#fff" w={{ base: '100%', md: '60%', lg: '80%' }}>
          <Text display={'flex'} gap={4} alignItems={'center'} fontSize="xl" color={textColor} fontWeight="bold">
            <Text>Máy gọi</Text>
            <MdContactPhone size={30} />
          </Text>
          <Flex boxShadow="lg" rounded="md" p={4} justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'10px'}>
            <Stack>
              <Flex gap={'20px'} alignItems={'end'}>
                <InputController type="number" control={control} name="phone" label="Số điện thoại" />
                <Button
                  colorScheme="blue"
                  ml={3}
                  // isLoading={createHotlineAutodialerCampaignMutation.isPending}
                  onClick={handleSubmit(onSubmit)}
                >
                  Gọi
                </Button>
              </Flex>
            </Stack>
          </Flex>
        </Card>
      )}
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Quản lý chiến dịch gọi trực tiếp
                </Text>
                <Flex gap={4}>
                  {(userInfo?.role === Roles.SUPER_ADMIN || userInfo?.role === Roles.ADMIN || userInfo?.role === Roles.LEADER) && (
                    <>
                      <Button bg="blue.500" color="white" maxH="40px" alignSelf={'end'} onClick={() => onOpenFormModal()}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tạo
                        </Text>
                      </Button>
                      {/* <Button bg="blue.500" color="white" maxH="40px" alignSelf={'end'} onClick={handleExport}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Xuất file excel
                        </Text>
                      </Button> */}
                    </>
                  )}
                </Flex>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex direction={'column'} gap={'20px'} flexWrap={'wrap'}>
                    <Flex flexWrap={'wrap'} gap={'20px'}>
                      <FormControl w={'300px'}>
                        <FormLabel>Tìm kiếm</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl>
                      {/* <FormControl w={'300px'}>
                        <FormLabel>Nhóm</FormLabel>
                        <Select
                          isClearable
                          menuShouldBlockScroll
                          value={hotlineFilter?.groupId}
                          onChange={e => {
                            setHotlineFilter({
                              ...hotlineFilter,
                              groupId: e,
                            });
                          }}
                          options={mappingOptionSelect(groups?.data, 'name', '_id')}
                        ></Select>
                      </FormControl>
                      <Flex alignItems={'center'} gap={5}>
                        <FormControl display="flex" flexDirection="column" maxW="300px" mr="12px">
                          <FormLabel marginRight={0} fontSize={14} isTruncated>
                            Ngày
                          </FormLabel>
                          <DatePicker
                            styleInput={{
                              minWidth: '300px',
                            }}
                            selectedDate={filterDate.start}
                            onChange={date => onChangeDate('start')(date)}
                          />
                        </FormControl>
                      </Flex> */}
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={campaigns?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={campaigns?.pagination?.page}
              pageLength={campaigns?.pagination?.pageSize}
              totalRecords={campaigns?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isFormModalOpen && <FormCampaign itemEdit={sizeEditing} isOpen={isFormModalOpen} onClose={handleCloseModal} refetch={refetch} />}
      {isCreateModalAssignOpen && (
        <FormImportPhone companyDetail={sizeEditing} isOpen={isCreateModalAssignOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isCreateModalVoiceTemplateOpen && (
        <FormVoiceTemplateAutoCall companyDetail={sizeEditing} isOpen={isCreateModalVoiceTemplateOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
       {isFormSendSMSModalOpen && (
        <FormSendSms companyDetail={sizeEditing} isOpen={isFormSendSMSModalOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isFormExportModalOpen && (
        <FormExportPhone companyDetail={sizeEditing} isOpen={isFormExportModalOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isFormAutocallModalOpen && (
        <FormAutoCall companyDetail={sizeEditing} isOpen={isFormAutocallModalOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
       {isAssignUserOpen && (
        <AssignUser companyDetail={sizeEditing} isOpen={isAssignUserOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isRecallOpen && <FormRecall companyDetail={sizeEditing} isOpen={isRecallOpen} onClose={handleCloseModal} refetch={refetch} />}
    </Flex>
  );
}

export default HotlineAutodialerCampaign;
