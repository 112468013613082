import { Box, Table, Tag, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { CodeError, Roles } from 'constants/common';
import { formatDate, obfuscatePhoneNumber } from 'utils/helpers';
import { useUserState } from 'context/UserContext';

const SizeTable = ({ categorysData }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const columns = useMemo(
    () => [
      columnHelper.accessor('_id', {
        header: 'Mã tin nhắn',
        cell: info => info.getValue() || '',
      }),
      columnHelper.accessor('phone', {
        header: 'Số điện thoại',
        cell: info => userInfo?.showPhone ? info.getValue() : (userInfo?.role === Roles.STAFF ? obfuscatePhoneNumber(info.getValue()) : info.getValue()),
      }),
      columnHelper.accessor('brand', {
        header: 'Brand',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('result', {
        header: 'Trạng thái tin nhắn đã gửi',
        cell: info =>
          Number(info.getValue()?.status) === 1 ? (
            <Tag variant="solid" colorScheme="teal">
              THÀNH CÔNG
            </Tag>
          ) : (
            (
              <Tag variant="solid" colorScheme="red">
                THẤT BẠI
              </Tag>
            ) || ''
          ),
      }),
      columnHelper.accessor('result', {
        header: 'Trạng thái tin nhắn đã nhận',
        cell: info => {
          if (!info.getValue()?.receivedts)
            return (
              <Tag variant="solid" colorScheme="gray">
                CHƯA NHẬN
              </Tag>
            );
          if (Number(info.getValue()?.status) === 1)
            return (
              <Tag variant="solid" colorScheme="teal">
                THÀNH CÔNG
              </Tag>
            );
          return (
            <Tag variant="solid" colorScheme="red">
              THẤT BẠI
            </Tag>
          );
        },
      }),
      columnHelper.accessor('errorcode', {
        header: 'Nguyên nhân lỗi',
        cell: info => {
          return CodeError[Number(info.getValue())] || '--';
        },
      }),
      columnHelper.accessor('createdAt', {
        header: 'Gửi lúc',
        cell: info => formatDate(info.getValue(), 'DD-MM-YYYY HH:mm:ss'),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
