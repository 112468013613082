import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Switch,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType, Roles, RolesUserLeaderOption, RolesUserOption, RolesUserSuperAdminOption } from 'constants/common';
import { toast } from 'components/Toast';
import { UserFormValidate, UserFormValidateUpdate, UserSuperAdminFormValidate, UserSuperAdminFormValidateUpdate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useQueryGetCompany } from 'services/company';
import { CookieStorage } from 'utils/cookie-storage';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { useCreateUserManagerMutation, useUpdateUserMutation } from 'services/user';
import { useUserState } from 'context/UserContext';
import { useQueryGetGroups } from 'services/group';

const FormQuotation = ({ companyDetail, isOpen, onClose, refetch }) => {
  const { userInfo } = useUserState();
  const cancelRef = React.useRef();
  const createUser = useCreateUserManagerMutation();
  const updateUser = useUpdateUserMutation();
  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(
      userInfo?.role === Roles.SUPER_ADMIN
        ? isEmpty(companyDetail)
          ? UserSuperAdminFormValidate
          : UserSuperAdminFormValidateUpdate
        : isEmpty(companyDetail)
        ? UserFormValidate
        : UserFormValidateUpdate
    ),
  });
  const { data: company } = useQueryGetCompany({}, { enabled: CookieStorage.isAuthenticated() && userInfo?.role === Roles.SUPER_ADMIN });
  const { data: groups } = useQueryGetGroups({}, { enabled: CookieStorage.isAuthenticated() && watch('role')?.value === Roles.STAFF });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || error);
  };
  const onSubmit = async values => {
    if (!isEmpty(companyDetail)) {
      updateUser.mutate(
        {
          username: values?.username,
          // email: values?.domainEmail,
          role: values?.role?.value,
          showPhone: values?.showPhone,
          password: values?.password,
          passwordConf: values?.passwordConf,
          companyId: userInfo?.role === Roles.SUPER_ADMIN ? values?.companyId?.value : userInfo?.company,
          ...(values?.groupId && { groupId: values?.groupId?.map(item => item.value) })
        },
        {
          onSuccess: () => handleSuccess(`Cập nhật tổng đài viên thành công`),
        }
      );
      return;
    }
    createUser.mutate(
      {
        ...values,
        companyId: userInfo?.role === Roles.SUPER_ADMIN ? values?.companyId?.value : userInfo?.company,
        role: values?.role?.value,
        ...(values?.groupId && { groupId: values?.groupId?.map(item => item.value) })
      },
      {
        onSuccess: () => handleSuccess(`Tạo tổng đài viên thành công`),
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(companyDetail)) {
      const companySelected = company?.data?.find(item => item._id === companyDetail?.company?._id);
      const roleSelected = RolesUserSuperAdminOption?.find(item => item.value === companyDetail?.role);
      const groupSelected = companyDetail?.groups?.map(item => ({
        label: item?.name,
        value: item?._id,
      }));
      reset({
        username: companyDetail.username,
        // domainEmail: companyDetail.email,
        companyId: {
          label: companySelected?.name,
          value: companySelected?._id,
        },
        showPhone: companyDetail.showPhone,
        role: roleSelected,
        id: companyDetail._id,
        groupId: groupSelected,
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">
            {isEmpty(companyDetail) ? 'Tạo' : 'Cập nhật'} thông tin tổng đài viên
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              isDisabled={!isEmpty(companyDetail)}
              control={control}
              isRequired
              name="username"
              label="Tên tài khoản"
              styleContainer={{
                marginBottom: '16px',
              }}
            />
            {/* <InputController
              control={control}
              isRequired
              name="domainEmail"
              label="Email"
              styleContainer={{
                marginBottom: '16px',
              }}
            /> */}
            <SelectController
              isDisabled={!isEmpty(companyDetail)}
              styleBoxInput={{
                marginBottom: '16px',
              }}
              isRequired
              label="Chức vụ"
              name="role"
              placeholder="Chọn chức vụ"
              control={control}
              options={
                userInfo?.role === Roles.SUPER_ADMIN
                  ? RolesUserSuperAdminOption
                  : userInfo?.role === Roles.LEADER
                  ? RolesUserLeaderOption
                  : RolesUserOption
              }
            />
            {userInfo?.role === Roles.SUPER_ADMIN && (
              <SelectController
                isDisabled={!isEmpty(companyDetail)}
                styleBoxInput={{
                  marginBottom: '16px',
                }}
                isRequired
                label="Chi nhánh"
                name="companyId"
                placeholder="Chọn chi nhánh"
                control={control}
                options={mappingOptionSelect(company?.data, 'name', '_id')}
              />
            )}
            {watch('role')?.value === Roles.STAFF && (
              <SelectController
                isMulti
                handleDisabledSelect={() => {
                  return watch('groupId')?.length >= 2;
                }}
                styleBoxInput={{
                  marginBottom: '16px',
                }}
                isRequired
                label="Nhóm"
                name="groupId"
                placeholder="Chọn nhóm"
                control={control}
                options={mappingOptionSelect(groups?.data, 'name', '_id')}
              />
            )}
            <InputController
              control={control}
              isRequired={isEmpty(companyDetail)}
              name="password"
              label="Mật khẩu"
              styleContainer={{
                marginBottom: '16px',
              }}
            />
            <InputController
              control={control}
              isRequired={isEmpty(companyDetail)}
              name="passwordConf"
              label="Xác nhận mật khẩu"
              styleContainer={{
                marginBottom: '16px',
              }}
            />
            <Flex flexDirection={'column'}>
              <Text>Hiển thị số điện thoại</Text>
              <Controller
                control={control}
                name="showPhone"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Switch onChange={e => onChange(e.target.checked)} onBlur={onBlur} isChecked={value} />
                )}
              ></Controller>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createUser.isPending || updateUser.isPending} onClick={handleSubmit(onSubmit)}>
              {isEmpty(companyDetail) ? 'Tạo' : 'Cập nhật'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormQuotation;
