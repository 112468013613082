import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Tooltip } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import { MdAssignmentTurnedIn } from 'react-icons/md';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType } from 'constants/common';
import moment from 'moment';
import { toast } from 'components/Toast';
import { AiFillDelete } from 'react-icons/ai';
import { useDeleteGroupMutation } from 'services/group';

const SizeTable = ({ categorysData, refetch, handleUpdateItem }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteGroupMutation = useDeleteGroupMutation();

  const handleDeleteSize = async group => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa nhóm này không?');
    if (!confirmDelete) {
      return;
    }
    deleteGroupMutation.mutate(
      { groupId: group?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa nhóm thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa nhóm không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Tên nhóm',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('user', {
        header: 'Leader',
        cell: info => info.getValue()?.username || '--',
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('staffs', {
        header: 'Nhân viên',
        cell: info => info.getValue() ? info.getValue()?.map(item => item.username).join(', ') : '--',
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Ngày cập nhật',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            {/* <Tooltip label="Phân bổ nhân viên" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleUpdateItem(info?.row?.original, ModalType.Assign)}>
                <MdAssignmentTurnedIn cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip> */}
            <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original)}>
                <AiFillDelete color="red" cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
