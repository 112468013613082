import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { HotlineType, ModalType, Roles, TYPE_AUTOCALL } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { useQueryGetHotlineTemplates } from 'services/ips';
import FormHotlineTemplate from './components/FormHotlineTemplate';
import { useUserState } from 'context/UserContext';

const initFilter = {
  companyId: null,
  status: null,
  extension: null,
};

function HotlineTemplate() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [hotlineFilter, setHotlineFilter] = useState(initFilter);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isDetailModalOpen, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();
  const { isOpen: isFormModalOpen, onOpen: onOpenFormModal, onClose: onCloseFormModal } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Detail]: onOpenDetailModal,
      [ModalType.Add]: onOpenFormModal,
    }),
    [onOpenDetailModal, onOpenFormModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Detail]: onCloseDetailModal,
      [ModalType.Add]: onCloseFormModal,
    }),
    [onCloseDetailModal, onCloseFormModal]
  );

  const { data: hotlines, refetch } = useQueryGetHotlineTemplates(
    { ...filter, searchKeyword: filter.searchKeyword, type: TYPE_AUTOCALL.AUTO_CALL_TEMPLATE },
    { enabled: isLoggedIn }
  );

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      ...(hotlineFilter?.companyId && { companyId: hotlineFilter?.companyId?.value }),
      ...(hotlineFilter?.status && { status: hotlineFilter?.status?.value }),
      ...(hotlineFilter?.extension && { extension: hotlineFilter?.extension?.label }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setHotlineFilter(initFilter);
    setSearchTitle('');
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Quản lý gọi văn bản
                </Text>
                {(userInfo?.role === Roles.SUPER_ADMIN || userInfo?.role === Roles.ADMIN) && (
                  <Button bg="blue.500" color="white" maxH="40px" alignSelf={'end'} onClick={() => onOpenFormModal()}>
                    <Text fontSize="md" fontWeight="normal" cursor="pointer">
                      Tạo
                    </Text>
                  </Button>
                )}
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex direction={'column'} gap={'20px'} flexWrap={'wrap'}>
                    <Flex flexWrap={'wrap'} gap={'20px'}>
                      <FormControl w={'300px'}>
                        <FormLabel>Tìm kiếm</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl>
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={hotlines?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={hotlines?.pagination?.page}
              pageLength={hotlines?.pagination?.pageSize}
              totalRecords={hotlines?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isFormModalOpen && <FormHotlineTemplate isOpen={isFormModalOpen} onClose={onCloseFormModal} refetch={refetch} />}
    </Flex>
  );
}

export default HotlineTemplate;
