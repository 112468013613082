import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getCompany = params => request.get('/api/v1/companies', { params });
export const deleteCompanyCategory = data => request.post('/api/v1/companies/delete', data);
export const updateCompanyCategory = data => request.post('/api/v1/companies/update', data);
export const createCompanyMutation = data => request.post('/api/v1/companies/create', data);
export const settingExtension = data => request.post('/api/v1/user/setting-extension', data);
export const changeStatusExtension = data => request.post('/api/v1/extensions/change-status', data);
export const assignCompanyExtension = data => request.post('/api/v1/extensions/assign', data);
export const createExtension = data => request.post('/api/v1/extensions/create', data);
export const getHistoryPhone = params => request.get('/api/v1/voice-otp/histories', {params});
export const getHistorySMS = params => request.get('/api/v1/zalo/histories', {params});

// Mutation
export const useDeleteCompanyMutation = () => useMutation({ mutationFn: deleteCompanyCategory });
export const useCreateExtensionMutation = () => useMutation({ mutationFn: createExtension });
export const useUpdateCompanyMutation = () => useMutation({ mutationFn: updateCompanyCategory });
export const useCreateCompanyMutation = () => useMutation({ mutationFn: createCompanyMutation });
export const useSettingExtensionMutation = () => useMutation({ mutationFn: settingExtension });
export const useAssignCompanyExtensionMutation = () => useMutation({ mutationFn: assignCompanyExtension });
export const useChangeStatusExtensionMutation = () => useMutation({ mutationFn: changeStatusExtension });

// Query
export const useQueryGetCompany = (params = {}, options = {}) => useQuery({ queryKey: ['getCompany', params], queryFn: () => getCompany(params), ...options });
export const useQueryGetHistoryPhone = (params, options = {}) => useQuery({ queryKey: ['getHistoryPhone', params], queryFn: () => getHistoryPhone(params), ...options });
export const useQueryGetHistorySms = (params, options = {}) => useQuery({ queryKey: ['getHistorySMS', params], queryFn: () => getHistorySMS(params), ...options });
