import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { AssignPhoneFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useUpdatePhone, usecreatePhone } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { useQueryStaffs } from 'services/user';
import { CookieStorage } from 'utils/cookie-storage';
import { useAssignStaffGroupMutation } from 'services/group';

const FormAssignPhoneUser = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const createPhone = usecreatePhone();
  const updatePhone = useUpdatePhone();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AssignPhoneFormValidate),
  });
  const { data: listUser } = useQueryStaffs({ companyId:companyDetail?.company?._id }, { enabled: isLoggedIn && !!companyDetail?.company?._id });
  const assignStaffGroupMutation = useAssignStaffGroupMutation();

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Assign);
  };

  const onSubmit = async values => {
    const payload = {
      staffIds: values.staffIds?.map(phone => phone?.value),
      groupId: companyDetail?._id,
    };
    assignStaffGroupMutation.mutate(payload, {
      onSuccess: () => handleSuccess(`Phân bổ nhân viên cho nhóm thành công`),
    });
  };

  useEffect(() => {
    if (!isEmpty(companyDetail)) {
      reset({
        id: companyDetail._id,
        staffIds: companyDetail?.staffs?.map(item => {
          return {
            value: item._id,
            label: item.username,
          }
        }),
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Assign);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Phân bổ nhân viên cho nhóm</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController
              isMulti
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Nhân viên"
              name="staffIds"
              placeholder="Chọn nhân viên"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(listUser?.data, 'username', '_id')}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Assign);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createPhone.isPending || updatePhone.isPending} onClick={handleSubmit(onSubmit)}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormAssignPhoneUser;
