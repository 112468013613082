export const ErrorForm = {
  Required: 'Trường này là bắt buộc',
  EmailInvalid: 'Email không đúng định dạng',
  MaximumPhoneLength: 'Số phone bao gồm 10 chữ số',
  MaximumUsernameLength: 'Mật khẩu từ 5 đến 30 ký tự',
  MaximumUsernameLength: 'Mật khẩu từ 5 đến 30 ký tự',
  MaximumLength: 'Trường này phải từ 5 đến 20 ký tự',
  MaximumLength: 'Trường này phải từ 8 đến 20 ký tự',
  CodeNumberLength: 'Code number must be from 8 to 20 symbol',
  PasswordInvalid: 'Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt',
  PasswordNotSame: 'Mật khẩu xác nhận phải giống với mật khẩu mới',
  MinMoney: amount => `Số tiền phải lớn hơn hoặc bằng ${amount}`,
  MaxMoney: amount => `Số tiền phải nhỏ hơn hoặc bằng ${amount}`,
  MaxLength: length => `Bạn được nhập tối đa ${length} ký tự`,
  FieldInvalid: 'Giá trị không hợp lệ',
  PhoneInvalid: 'Số điện thoại không hợp lệ',
};

export const ErrorApi = {
  TEMPALATE_EXISTS: 'Template đã tồn tại',
  ['token expired']: 'Phiên đăng nhập đã hết hạn',
  WRONG_USERNAME_OR_PASSWORD: 'Tên đăng nhập hoặc mật khẩu sai',
  THE_USERNAME_OR_EMAIL_EXISTED: 'Tài khoản này đã tồn tại',
  YOUR_INFORMATION_IS_WAITING_APPROVAL_OR_APPROVED: 'Thông tin của bạn đang chờ phê duyệt hoặc được phê duyệt',
  ["Wrong_Password."]: 'Mật khẩu hiện tại chưa đúng',
  ['Wrong username or password.']: 'Tên đăng nhập hoặc mật khẩu chưa đúng',
  THE_WEBSITE_AD_UNIT_IS_INVALID: 'Đơn vị quảng cáo web không hợp lệ',
  THE_WEBSITE_URL_IS_INVALID: 'Địa chỉ website không hợp lệ',
  WEBSITE_ALREADY_EXISTS: 'Trang web này đã tồn tại',
  WRONG_PASSWORD: 'Mật khẩu không đúng',
  USERNAME_INVALID_FORMAT: 'Tên đăng nhập không hợp lệ',
  PHONE_EXISTS: 'Số điện thoại này đã tồn tại',
  IP_NOT_EXISTS: 'Địa chỉ ip này không tồn tại',
  IP_NOT_ALLOW: 'Địa chỉ ip này không cho phép',
  PHONE_NOT_ASSIGN_FOR_USER: 'Số điện thoại này chưa được gán cho tài khoản này',
  TEMPLATE_NOT_ACTIVE: 'Template chưa hoạt động',
  HAS_NOT_PERMISSION: 'Không có quyền truy cập vào chức năng này',
  EXTENSION_IS_USING: 'Số máy chủ này đang được sử dụng',
  PHONE_NOT_FOUND: 'Số điện thoại không tồn tại',
  RECORD_FILE_NOT_ACTIVE: 'File này chưa được kích hoạt',
  COMPANY_NOT_FOUND: 'Chi nhánh không tồn tại',
  GROUP_NOT_FOUND: 'Nhóm không tồn tại',
  THE_USER_NAME_INCLUDE_LOWERCASE_CHARACTER_NUMBER_MIN_6_MAX_20_CHARACTER: 'Tên người dùng phải bao gồm ít nhất 6 ký tự và tối đa 20 ký tự',
  HOTLINE_CAMPAIGN_NOT_ACTIVE: "Nhiệm vụ gọi chưa được kích họạt",
  HOTLINE_CAMPAIGN_NOT_FOUND: 'Nhiệm vụ gọi không tồn tại',
  CREATE_CAMPAIGN_FAILED: 'Tạo nhiệm vụ gọi văn bản thất bại',
  EXTENSION_NOT_ENABLED: 'Máy gọi chưa kích hoạt',
  QUOTATION_NOT_ACTIVE: 'Giá cước chưa kích hoạt',
  TEMPALATE_IS_USING_CAN_NOT_DELETE: 'Mẫu đang được sử dụng không thể xóa',
  // STAFF_IN_GROUP_FAILED_EXTENSION: 'Nhân viên trong nhóm không thể xóa',
  LIST_PHONE_EMPTY: 'Danh sách số điện thoại không được để trống',
  PHONE_EMPTY: 'Danh sách số điện thoại không được để trống',
  CAMPAIGN_NAME_IS_EXISTS: 'Tên chiến dịch đã tồn tại',
};
