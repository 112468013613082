import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FaRegListAlt } from "react-icons/fa";
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { formatDate } from 'utils/helpers';
import { useDeleteCompanyMutation } from 'services/company';
import { useHistory } from 'react-router-dom';

const SizeTable = ({ users, refetch, handleUpdateCategory }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteCompanyMutation = useDeleteCompanyMutation();
  const history = useHistory();

  const handleDeleteSize = async company => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa chi nhánh này không?');
    if (!confirmDelete) {
      return;
    }
    deleteCompanyMutation.mutate(
      { id: company?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa chi nhánh thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa chi nhánh không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Tên',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('slug', {
        header: 'Slug',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('fee', {
        header: 'Giá cước',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('balance', {
        header: 'Số dư',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('description', {
        header: 'Mô tả',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDate(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Ngày cập nhật',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDate(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <Tooltip label="Cập nhật" aria-label="A tooltip" rounded={'6px'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  // handleResetPassword(info?.row?.original);
                  handleUpdateCategory(info?.row?.original, ModalType.Add);
                }}
              >
                <EditIcon cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
            <Tooltip label="Báo cáo" aria-label="A tooltip" rounded={'6px'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                history.push(`/admin/report/${info?.row?.original?._id}/company`);
                }}
              >
                <FaRegListAlt color="red.400" boxSize={4} />
              </IconButton>
            </Tooltip>
            <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleDeleteSize(info?.row?.original);
                }}
              >
                <DeleteIcon color="red.400" boxSize={4} />
              </IconButton>
            </Tooltip>
            {/* <Menu>
              {({ isOpen }) => (
                <>
                  <Tooltip label="Lịch sử" aria-label="A tooltip" rounded={'6px'}>
                    <MenuButton bg="transparent" isActive={isOpen} as={IconButton}>
                      <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Block)}>
                        <FaHistory cursor="pointer" boxSize={4} />
                      </IconButton>
                    </MenuButton>
                  </Tooltip>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        history.push(`/admin/company/${info?.row?.original?._id}/histories-phone`);
                      }}
                    >
                      Lịch sử cuộc gọi
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push(`/admin/company/${info?.row?.original?._id}/histories-sms`);
                      }}
                    >
                      Lịch sử sms
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu> */}
          </Flex>
        ),
      }),
    ],
    [users]
  );

  const table = useReactTable({
    data: users || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
