import React, { useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Text,
} from '@chakra-ui/react';
import { set, useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { FileAudioValid, ModalType, Roles } from 'constants/common';
import { toast } from 'components/Toast';
import { HotlineVoiceTemplateFormValidate, HotlineVoiceTemplateFormValidateAdmin } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadLoadFile, useCreateHotlineVoiceTemplate } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { useQueryGetCompany } from 'services/company';
import { CookieStorage } from 'utils/cookie-storage';
import { mappingOptionSelect } from 'utils/mapping';
import { useUserState } from 'context/UserContext';
import { RiAsterisk } from 'react-icons/ri';
import { IoMdCloseCircle, IoMdCloudUpload } from 'react-icons/io';

const FormHotlineTemplate = ({ isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const { userInfo } = useUserState();
  const createHotlineVoiceTemplate = useCreateHotlineVoiceTemplate();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(userInfo?.role === Roles.SUPER_ADMIN ? HotlineVoiceTemplateFormValidate : HotlineVoiceTemplateFormValidateAdmin),
  });
  const isLoggedIn = CookieStorage.isAuthenticated();
  const [file, setFile] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorFile, setErrorFile] = useState('');
  const inputImportRef = useRef();

  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    if(!file) {
      setErrorFile('Vui lòng chọn file')
      return;
    };
    createHotlineVoiceTemplate.mutate(
      {
        ...file,
        ...values,
        company_id: userInfo?.role === Roles.SUPER_ADMIN ? values?.company_id?.value : userInfo?.company,
        ...(values?.max_timeout && { max_timeout: Number(values?.max_timeout) }),
      },
      {
        onSuccess: () => handleSuccess(`Tạo gọi mẫu voice thành công`),
      }
    );
  };

  const onUploadFile = async (file) => {
    const formData = new FormData();
    formData.append('recordFile', file);
    const response = await uploadLoadFile(formData);
    return response?.data;
  };

  const handleUploadFile =  async (e) => {
    const fileSelected = e.target.files[0];
    const typeFile = fileSelected?.name?.split('.')?.pop();
    if (FileAudioValid.includes(typeFile) && fileSelected) {
      const res = await onUploadFile(fileSelected)
      setFileUpload(fileSelected);
      setFile(res);
      setErrorFile(null);
      return;
    }
    setErrorFile('Chỉ hỗ trợ file .mp3');
  }
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Tạo thông tin gọi mẫu voice</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="template_name"
              label="Tên mẫu"
              styleContainer={{
                marginBottom: '12px',
              }}
            />
            {userInfo?.role === Roles.SUPER_ADMIN && (
              <SelectController
                control={control}
                name="company_id"
                label="Chi nhánh"
                options={mappingOptionSelect(company?.data, 'name', '_id')}
                isRequired
                styleContainer={{ pt: 4 }}
              />
            )}
            <InputController
              control={control}
              isRequired
              name="max_timeout"
              label="Số lần lặp lại"
              styleContainer={{
                marginBottom: '0px',
                marginTop: '10px',
              }}
            />
            <Flex alignItems={'center'} gap={1} mt={4}>
              <Text>File</Text>
              <RiAsterisk color="red" size={8} />
            </Flex>
            <input
              type="file"
              hidden
              ref={inputImportRef}
              onChange={e => {
               handleUploadFile(e);
              }}
            />
            <Button
              mt={1}
              bg="#3182ce"
              color="#fff"
              _hover={{ bg: '#67a1d7' }}
              onClick={() => {
                inputImportRef?.current?.click();
              }}
            >
              <IoMdCloudUpload />
              <Text ml={1}>Tải file lên</Text>
            </Button>
            {fileUpload && (
              <Flex mt={4} alignItems={'center'} gap={2}>
                <Text>{fileUpload?.name}</Text>
                <IoMdCloseCircle onClick={() => {
                  setFile(null)
                  setFileUpload(null)
                }} cursor={'pointer'} />
              </Flex>
            )}
            {!file && isSubmit && (
              <Text mt={1} color="red.500" fontSize={13}>
                Trường này là bắt buộc
              </Text>
            )}
            {errorFile && (
              <Text mt={1} color="red.500" fontSize={13}>
                {errorFile}
              </Text>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createHotlineVoiceTemplate.isPending} onClick={handleSubmit(onSubmit)}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormHotlineTemplate;
