import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  UnorderedList,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { CampaignAutoTemplateStatus, daysOfWeek, FORMAT_DATE, ModalType } from 'constants/common';
import moment from 'moment';
import { findMissingObjects, formatDate } from 'utils/helpers';

const DetailModalCampaign = ({ isOpen, onClose, companyDetail }) => {
  const cancelRef = React.useRef();

  let matchingDays = [];
  let startTime = null;
  let endTime = null;
  if (companyDetail?.schedules?.[0]) {
    matchingDays = daysOfWeek.filter(day => {
      const startTimeKey = `${day.value}_start_time`;
      const endTimeKey = `${day.value}_end_time`;
      if (companyDetail?.schedules?.[0]?.[startTimeKey] !== 28800000000000) {
        startTime = companyDetail?.schedules?.[0]?.[startTimeKey];
      }
      if (companyDetail?.schedules?.[0]?.[endTimeKey] !== 75600000000000) {
        endTime = companyDetail?.schedules?.[0]?.[endTimeKey];
      }
      return (
        companyDetail?.schedules?.[0]?.[startTimeKey] !== 28800000000000 || companyDetail?.schedules?.[0]?.[endTimeKey] !== 75600000000000
      );
    });
  }
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Detail);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết nhiệm vụ cuộc gọi</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text fontWeight={600}>Tên nhiệm vụ</Text>
            <Input mb={2} value={companyDetail?.campaign_name || '--'} readOnly />
            <Text fontWeight={600}>Chi nhánh</Text>
            <Input mb={2} value={companyDetail?.company?.name || '--'} readOnly />
            <Text fontWeight={600}>Số lượng cuộc gọi đồng thời</Text>
            <Input mb={2} value={companyDetail?.concurrent_calc} readOnly />
            <Text fontWeight={600}>Lịch trình</Text>
            <Flex flexDirection={"column"} justifyContent={'space-around'} gap={10} py={5}>
              <Flex justifyContent={"space-between"}>
                <Text fontWeight={600} fontStyle={'italic'} pr={5}>
                  Các ngày có thời gian bắt đầu từ {formatDate(new Date(startTime), 'h:mm A')} và kết thúc {formatDate(new Date(endTime), 'h:mm A')}
                </Text>
                <UnorderedList>
                  {matchingDays.map((day, index) => (
                    <ListItem key={index}>{day.label}</ListItem>
                  ))}
                </UnorderedList>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Text fontWeight={600} fontStyle={'italic'} pr={5}>
                  Các ngày có thời gian bắt đầu và kết thúc mặc định
                </Text>
                <UnorderedList>
                  {findMissingObjects(daysOfWeek, matchingDays, 'value').map((day, index) => (
                    <ListItem key={index}>{day.label}</ListItem>
                  ))}
                </UnorderedList>
              </Flex>
            </Flex>
            <Text fontWeight={600}>Mẫu cuộc gọi</Text>
            <Input mb={2} value={companyDetail?.hotlineTemplate?.template_name || '--'} readOnly />
            <Text fontWeight={600}>Tiến trình</Text>
            <Input mb={2} value={companyDetail?.processing || '--'} readOnly />
            <Text fontWeight={600}>Trạng thái</Text>
            <Input mb={2} value={CampaignAutoTemplateStatus[companyDetail?.status]} readOnly />
            <Text fontWeight={600}>Ngày tạo</Text>
            <Input mb={2} value={moment(companyDetail?.createdAt).format(FORMAT_DATE)} readOnly />
            <Text fontWeight={600}>Ngày cập nhật</Text>
            <Input mb={2} value={moment(companyDetail?.updatedAt).format(FORMAT_DATE)} readOnly />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Detail);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DetailModalCampaign;
