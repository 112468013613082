import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType, Roles } from 'constants/common';
import { toast } from 'components/Toast';
import { CreateGroupFormValidate, CreateGroupFormValidateAdmin, CreateGroupFormValidateLeader } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useUpdatePhone } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { useUserState } from 'context/UserContext';
import { mappingOptionSelect } from 'utils/mapping';
import { CookieStorage } from 'utils/cookie-storage';
import { useQueryGetCompany } from 'services/company';
import { useQueryListLeader } from 'services/user';
import { useCreateGroupMutation } from 'services/group';

const FormPhone = ({ companyDetail, isOpen, onClose, refetch }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const cancelRef = React.useRef();
  const { userInfo } = useUserState();
  const createGroupMutation = useCreateGroupMutation();
  const updatePhone = useUpdatePhone();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(userInfo?.role === Roles.SUPER_ADMIN ? CreateGroupFormValidate : (userInfo?.role === Roles.ADMIN ? CreateGroupFormValidateAdmin : CreateGroupFormValidateLeader)),
  });
  const { data: company } = useQueryGetCompany({}, { enabled: CookieStorage.isAuthenticated() && userInfo?.role === Roles.SUPER_ADMIN });
  const { data: listLeader } = useQueryListLeader(
    {
      companyId: selectedCompany?.value || userInfo?.company,
    },
    { enabled: CookieStorage.isAuthenticated() && (!!selectedCompany?.value || !!userInfo?.company) && userInfo?.role !== Roles.LEADER }
  );

  const handleSuccess = message => {  
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    if (isEmpty(companyDetail)) {
      createGroupMutation.mutate(
        { ...values, companyId: values?.companyId?.value, userId: (userInfo?.role === Roles.LEADER ? userInfo?._id : values?.userId?.value) },
        {
          onSuccess: () => handleSuccess(`Tạo nhóm thành công`),
        }
      );
      return;
    }
    updatePhone.mutate(
      { ...values },
      {
        onSuccess: () => handleSuccess(`Cập nhập nhóm thành công`),
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(companyDetail)) {
      let companySelected = null;
      if (userInfo?.role === Roles.SUPER_ADMIN) {
        companySelected = company?.data?.find(item => item._id === companyDetail?.company?._id);
      }
      reset({
        phone: companyDetail.phone,
        id: companyDetail._id,
        ...(!isEmpty(companySelected) && {
          company_id: {
            label: companySelected?.name,
            value: companySelected?._id,
          },
        }),
      });
    }
  }, [companyDetail]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">{isEmpty(companyDetail) ? 'Tạo' : 'Cập nhật'} thông tin nhóm</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="name"
              label="Tên nhóm"
              styleContainer={{
                marginBottom: '16px',
              }}
            />
            {
              userInfo?.role === Roles.SUPER_ADMIN &&
              <SelectController
                isDisabled={!isEmpty(companyDetail)}
                styleBoxInput={{
                  marginBottom: '16px',
                }}
                isRequired
                label="Chi nhánh"
                name="companyId"
                placeholder="Chọn chi nhánh"
                control={control}
                options={mappingOptionSelect(company?.data, 'name', '_id')}
                onChange={e => {
                  setSelectedCompany(e);
                }}
              />
            }
            {
              userInfo?.role !== Roles.LEADER &&
              <SelectController
                isDisabled={!!userInfo?.company ? false : !selectedCompany}
                styleBoxInput={{
                  marginBottom: '16px',
                }}
                isRequired
                label="Leader"
                name="userId"
                placeholder="Chọn leader"
                control={control}
                options={mappingOptionSelect(listLeader?.data, 'username', '_id')}
              />
            }
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createGroupMutation.isPending || updatePhone.isPending} onClick={handleSubmit(onSubmit)}>
              {'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormPhone;
