import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { FORMAT_DATE_SHORT, ModalType, Roles, ROOT_API } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { useExportHistoriesHotlineAutodialer, useQueryGetExtensionsAssign, useQueryGetHistoriesHotlineAutodialer } from 'services/ips';
import DetailModal from './components/DetailModal';
import { useParams } from 'react-router-dom';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import axios from 'axios';
import { useQueryGetStatisticReportHotline } from 'services/statistic';
import { Select } from 'chakra-react-select';
import DatePicker from 'components/DatePicker/DatePicker';
import { mappingOptionSelect } from 'utils/mapping';
import { useUserState } from 'context/UserContext';
import { useQueryGetGroups } from 'services/group';
import { useQueryGetCompany } from 'services/company';
import moment from 'moment';

const initFilter = {
  companyId: null,
  status: null,
  extension: null,
  groupId: null,
};

function StatisticReport() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [hotlineFilter, setHotlineFilter] = useState(initFilter);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
  });
  const { userInfo } = useUserState();

  const { id } = useParams();
  const exportHistoriesHotlineAutodialer = useExportHistoriesHotlineAutodialer(
    {
      campaign_id: id,
    },
    { responseType: 'arraybuffer' }
  );
  const { data: extensions } = useQueryGetExtensionsAssign({}, { enabled: isLoggedIn && userInfo?.role !== Roles.STAFF });
  const { data: hotlines, refetch } = useQueryGetStatisticReportHotline(
    { ...filter, searchKeyword: filter.searchKeyword },
    { enabled: isLoggedIn }
  );
  const { data: groups } = useQueryGetGroups({}, { enabled: isLoggedIn });
  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isDetailModalOpen, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Detail]: onOpenDetailModal,
    }),
    [onOpenDetailModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Detail]: onCloseDetailModal,
    }),
    [onCloseDetailModal]
  );

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      ...(hotlineFilter?.companyId && { companyId: hotlineFilter?.companyId?.value }),
      ...(hotlineFilter?.extension && { extension: hotlineFilter?.extension?.value }),
      ...(hotlineFilter?.groupId && { groupId: hotlineFilter?.groupId?.value }),
      ...(filterDate?.start && { date: moment(filterDate?.start).format(FORMAT_DATE_SHORT) }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setHotlineFilter(initFilter);
    setFilterDate({
      start: null,
    });
    setSearchTitle('');
  };

  const onDownloadTemplate = async () => {
    const res = await axios.get(
      ROOT_API +
        '/api/v1/hotline/export-history-all?' +
        (hotlineFilter?.companyId?.value ? `companyId=${hotlineFilter?.companyId?.value}` : "") +
        (hotlineFilter?.extension?.value ? `&extension=${hotlineFilter?.extension?.value}` : "") +
        (hotlineFilter?.groupId?.value ? `&groupId=${hotlineFilter?.groupId?.value}` : "") +
        (filterDate?.start ? `&date=${moment(filterDate?.start).format(FORMAT_DATE_SHORT)}` : ""),
      {
        headers: { 'Content-Type': 'application/json', xToken: CookieStorage.getAccessToken() },
        responseType: 'arraybuffer',
      }
    );
    if (res.status === 200) {
      downloadFile(res?.data, 'export-history-all');
    }
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Báo cáo thời lượng cuộc gọi
                </Text>
                <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onDownloadTemplate}>
                  Export báo cáo
                </Button>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex direction={'column'} gap={'20px'} flexWrap={'wrap'}>
                    <Flex flexWrap={'wrap'} gap={'20px'}>
                      {/* <FormControl w={'300px'}>
                        <FormLabel>Tìm kiếm</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl> */}
                      {userInfo?.role === Roles.SUPER_ADMIN && (
                        <FormControl w={'300px'}>
                          <FormLabel>Chi nhánh</FormLabel>
                          <Select
                            isClearable
                            menuShouldBlockScroll
                            value={hotlineFilter?.companyId}
                            onChange={e => {
                              setHotlineFilter({
                                ...hotlineFilter,
                                companyId: e,
                              });
                            }}
                            options={mappingOptionSelect(company?.data, 'name', '_id')}
                          ></Select>
                        </FormControl>
                      )}
                      <FormControl w={'300px'}>
                        <FormLabel>Nhóm</FormLabel>
                        <Select
                          isClearable
                          menuShouldBlockScroll
                          value={hotlineFilter?.groupId}
                          onChange={e => {
                            setHotlineFilter({
                              ...hotlineFilter,
                              groupId: e,
                            });
                          }}
                          options={mappingOptionSelect(groups?.data, 'name', '_id')}
                        ></Select>
                      </FormControl>
                      {userInfo?.role !== Roles.STAFF && (
                        <FormControl w={'300px'}>
                          <FormLabel>Máy chủ</FormLabel>
                          <Select
                            isClearable
                            menuShouldBlockScroll
                            value={hotlineFilter?.extension}
                            onChange={e => {
                              setHotlineFilter({
                                ...hotlineFilter,
                                extension: e,
                              });
                            }}
                            options={mappingOptionSelect(extensions?.data, 'extension', '_id')}
                          ></Select>
                        </FormControl>
                      )}
                      <FormControl display="flex" flexDirection="column" maxW="300px" mr="12px">
                        <FormLabel marginRight={0} fontSize={14} isTruncated>
                          Ngày
                        </FormLabel>
                        <DatePicker
                          styleInput={{
                            minWidth: '300px',
                          }}
                          selectedDate={filterDate.start}
                          onChange={date => onChangeDate('start')(date)}
                        />
                      </FormControl>
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={hotlines?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={hotlines?.pagination?.page}
              pageLength={hotlines?.pagination?.pageSize}
              totalRecords={hotlines?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isDetailModalOpen && <DetailModal companyDetail={sizeEditing} isOpen={isDetailModalOpen} onClose={handleCloseModal} />}
    </Flex>
  );
}

export default StatisticReport;
