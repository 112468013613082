import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { FileExcelValid, ModalType, Roles } from 'constants/common';
import { toast } from 'components/Toast';
import { CampaignSMSSuperAdminFormValidate, CampaignSMSSuperAdminFormValidateAdmin } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectController from 'components/Form/SelectController';
import { useQueryGetCompany } from 'services/company';
import { CookieStorage } from 'utils/cookie-storage';
import { mappingOptionSelect } from 'utils/mapping';
import { useActionCampaignSmsMutation, useCreateCampaignSMSMutation } from 'services/campaign';
import { useUserState } from 'context/UserContext';
import { isEmpty } from 'lodash';
import { importPhoneCampagin } from 'services/ips';
import { useQueryGetTemplateAssign } from 'services/email-template';

const FormCampaign = ({ itemEdit, isOpen, onClose, refetch }) => {
  const { userInfo } = useUserState();
  const cancelRef = React.useRef();
  const createCampaignSMSMutation = useCreateCampaignSMSMutation();
  const updateCampaignSMSMutation = useActionCampaignSmsMutation();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(
      userInfo?.role === Roles.SUPER_ADMIN ? CampaignSMSSuperAdminFormValidate : CampaignSMSSuperAdminFormValidateAdmin
    ),
  });
  const isLoggedIn = CookieStorage.isAuthenticated();
  const inputImportRef = useRef();
  const [errorFile, setErrorFile] = useState('');
  const [file, setFile] = useState(null);
  const [companySelect, setCompanySelect] = useState(null);

  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });
  const { data: templates } = useQueryGetTemplateAssign(
    { company_id: itemEdit?.company?._id || userInfo?.company || companySelect?.value },
    { enabled: isLoggedIn && (!!itemEdit?.company?._id || !!userInfo?.company || !!companySelect?.value) }
  );

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    if (isEmpty(itemEdit)) {
      if (!file) {
        setErrorFile('Vui lòng chọn file số điện thoại');
        return;
      }
      createCampaignSMSMutation.mutate(
        {
          campaign_name: values?.campaign_name,
          company_id: values?.company_id?.value || userInfo?.company,
          emailTemplateId: values?.emailTemplateId?.value,
        },
        {
          onSuccess: async res => {
            if (file && res.data?._id) {
              const formData = new FormData();
              formData.append('phoneFile', file);
              formData.append('campaign_id', res.data?._id);
              await importPhoneCampagin(formData)
                .then(() => {
                  console.log("Successfully");
                })
                .catch(() => {
                  toast.showMessageError('Tải lên số điện thoại thất bại');
                  return;
                });
            }
            handleSuccess(`Tạo sms chiến dịch thành công`);
          },
        }
      );
      return;
    }
    updateCampaignSMSMutation.mutate(
      {
        campaign_name: values?.campaign_name,
        campaign_id: itemEdit?._id,
      },
      {
        onSuccess: () => handleSuccess(`Cập nhật sms chiến dịch thành công`),
      }
    );
  };

  const handleImportPhone = async e => {
    if (e?.target?.files?.[0]) {
      const phoneFile = e.target.files[0];
      const extensionFile = phoneFile?.name?.split('.')?.pop();
      if (FileExcelValid.includes(extensionFile)) {
        setFile(phoneFile);
        setErrorFile('');
        return;
      }
      setFile(null);
      setErrorFile('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
      return;
    }
    setFile(null);
    setErrorFile('Vui lòng choọn file số điện thoại');
  };

  useEffect(() => {
    if (!isEmpty(itemEdit)) {
      const res = templates?.data?.find(item => item._id === itemEdit?.mailTemplate);
      reset({
        campaign_name: itemEdit?.campaign_name,
        company_id: {
          value: itemEdit?.company?._id,
          label: itemEdit?.company?.name,
        },
        emailTemplateId: {
          value: res?._id,
          label: res?.name,
        },
      });
    }
  }, [itemEdit, templates?.data]);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">{isEmpty(itemEdit) ? 'Tạo' : 'Cập nhật'} thông tin sms chiến dịch</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController control={control} isRequired name="campaign_name" label="Tên chiến dịch" />
            {userInfo?.role === Roles.SUPER_ADMIN && (
              <SelectController
                isDisabled={!isEmpty(itemEdit)}
                control={control}
                name="company_id"
                label="Chi nhánh"
                onChange={e => setCompanySelect(e)}
                options={mappingOptionSelect(company?.data, 'name', '_id')}
                styleContainer={{ pt: 4 }}
              />
            )}
            <SelectController
              isDisabled={!isEmpty(itemEdit)}
              styleContainer={{
                marginTop: '10px',
              }}
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Template"
              name="emailTemplateId"
              placeholder="Chọn mẫu"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(templates?.data, 'name', '_id')}
            />
            {isEmpty(itemEdit) && (
              <>
                <Box mt={5} display={'flex'} alignItems={'center'} gap={5}>
                  <Text>File số điện thoại:</Text>
                  <Text>{file?.name}</Text>
                  <Button
                    onClick={() => {
                      inputImportRef?.current?.click();
                    }}
                  >
                    Chọn file
                  </Button>
                  <input type="file" hidden ref={inputImportRef} onChange={handleImportPhone} />
                </Box>
                {errorFile && (
                  <Text color="red.500" fontSize={12} mt={2}>
                    {errorFile}
                  </Text>
                )}
              </>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createCampaignSMSMutation.isPending} onClick={handleSubmit(onSubmit)}>
              {isEmpty(itemEdit) ? 'Tạo' : 'Cập nhật'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormCampaign;
