import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType, Roles, StatusHotLineOption } from 'constants/common';
// import CreateCategoryModal from './components/CreateCategoryModal';
import Pagination from 'components/Pagination/Pagination';
import { Select } from 'chakra-react-select';
import HistoryTable from './HistoryTable';
import { useParams } from 'react-router-dom';
import { useQueryGetExtensionsAssign, useQueryGetHistoriesHotline } from 'services/ips';
import DetailModal from 'views/Dashboard/HistoryHotline/components/DetailModal';
import { useUserState } from 'context/UserContext';
import { mappingOptionSelect } from 'utils/mapping';
import { useQueryGetCompany } from 'services/company';

function Histories() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
    companyId: null,
    extension: null,
    status: null,
  });
  const { isOpen: isDetailModalOpen, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();
  const { userInfo } = useUserState();

  const openModal = useMemo(
    () => ({
      [ModalType.Detail]: onOpenDetailModal,
    }),
    [onOpenDetailModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Detail]: onCloseDetailModal,
    }),
    [onCloseDetailModal]
  );
  const params = useParams();
  const { id } = params || {};

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: members } = useQueryGetHistoriesHotline(
    { ...filter, searchKeyword: filter.searchKeyword, phone: id },
    { enabled: isLoggedIn }
  );
  const { data: extensions } = useQueryGetExtensionsAssign(
    {},
    { enabled: isLoggedIn && (userInfo?.role === Roles.ADMIN || userInfo?.role === Roles.SUPER_ADMIN) }
  );
  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });

  const handleShowMemberDetail = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      ...(filterDate?.companyId && { companyId: filterDate?.companyId?.value }),
      ...(filterDate?.status && { status: filterDate?.status?.value }),
      ...(filterDate?.extension && { extension: filterDate?.extension?.label }),
      searchKeyword: searchTitle,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setSearchTitle('');
    setFilterDate({
      start: null,
      end: null,
      companyId: null,
      extension: null,
      status: null,
    });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử hotline của số {id}
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap='wrap'>
                    <FormControl w='300px'>
                      <FormLabel>Tìm kiếm lịch sử hotline</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    {userInfo?.role === Roles.SUPER_ADMIN && (
                      <>
                        <FormControl w={'300px'}>
                          <FormLabel>Chi nhánh</FormLabel>
                          <Select
                            isClearable
                            menuShouldBlockScroll
                            value={filterDate?.companyId}
                            onChange={e => {
                              setFilterDate({
                                ...filterDate,
                                companyId: e,
                              });
                            }}
                            options={mappingOptionSelect(company?.data, 'name', '_id')}
                          ></Select>
                        </FormControl>
                      </>
                    )}
                    {(userInfo?.role === Roles.ADMIN || userInfo?.role === Roles.SUPER_ADMIN) && (
                      <FormControl w={'300px'}>
                        <FormLabel>Số máy chủ</FormLabel>
                        <Select
                          isClearable
                          menuShouldBlockScroll
                          value={filterDate?.extension}
                          onChange={e => {
                            setFilterDate({
                              ...filterDate,
                              extension: e,
                            });
                          }}
                          options={mappingOptionSelect(extensions?.data, 'extension', 'extension_uuid')}
                        ></Select>
                      </FormControl>
                    )}
                    <FormControl w={'300px'}>
                      <FormLabel>Trạng thái</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        value={filterDate?.status}
                        onChange={e => {
                          setFilterDate({
                            ...filterDate,
                            status: e,
                          });
                        }}
                        options={StatusHotLineOption}
                      ></Select>
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <HistoryTable tableData={members?.data || []} handleShowMemberDetail={handleShowMemberDetail} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={members?.pagination?.page}
              pageLength={members?.pagination?.pageSize}
              totalRecords={members?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isDetailModalOpen && <DetailModal companyDetail={sizeEditing} isOpen={isDetailModalOpen} onClose={handleCloseModal} />}
    </Flex>
  );
}

export default Histories;
