import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalType } from 'constants/common';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { ZaloFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import isEmpty from 'lodash/isEmpty';
import { mappingOptionSelect } from 'utils/mapping';
import { useCreateZaloOA, useUpdateZaloOA } from 'services/zalo';

const FormZaloModal = ({ company, memberDetail, isOpen, onClose, refetch }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(ZaloFormValidate),
  });

  const updateZaloOA = useUpdateZaloOA();
  const createZaloOA = useCreateZaloOA();

  const onSubmit = values => {
    if (memberDetail?._id) {
      updateZaloOA.mutate(
        {
          ...values,
          companyId: values?.companyId?.value,
        },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Cập nhật zalo thành công');
            onClose(ModalType.Add);
            refetch();
          },
        }
      );
      return;
    }
    createZaloOA.mutate(
      {
        ...values,
        companyId: values?.companyId?.value,
      },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tạo zalo thành công');
          onClose(ModalType.Add);
          refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(memberDetail)) {
      const companyUpdate = company?.find(item => item._id === memberDetail?.company?._id)
      reset({
        companyId: {
          label: companyUpdate?.name,
          value: companyUpdate?._id,
        },
        app_id: memberDetail.app_id,
        secret_key: memberDetail.secret_key,
        zaloId: memberDetail._id,
      });
    }
  }, [memberDetail]);
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{!isEmpty(memberDetail) ? 'Cập nhật' : 'Tạo'} zalo</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <SelectController
              control={control}
              name="companyId"
              label="Chọn Chi nhánh"
              options={mappingOptionSelect(company, 'name', '_id')}
              isRequired
              styleContainer={{ pt: 4 }}
            />
            <InputController control={control} name="app_id" label="App ID" isRequired styleContainer={{ pt: 4 }} />
            <InputController control={control} name="secret_key" label="Secret Key" isRequired styleContainer={{ pt: 4 }} />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={createZaloOA.isPending || updateZaloOA?.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default FormZaloModal;
