import { ROOT_API, Roles } from "constants/common";

export const formatImageUrl = url => {
    if (url.includes('https')) {
      return url;
    } 
    return ROOT_API.concat('/', url);
  };

  export const checkAccessScreen = ({ userRoles, groupName, groupPermission }) => {
    if (userRoles.includes(Roles.SUPER_ADMIN) || !groupName) return true;
  
    const groupIndex = groupPermission?.findIndex(item => item === groupName);
  
    return groupIndex > -1 ? true : false;
  };
