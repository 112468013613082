import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ViewIcon } from '@chakra-ui/icons';
import { formatDate } from 'utils/helpers';
import { GAME_TYPE_MISSION, ModalType } from 'constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BiCommentDetail } from 'react-icons/bi';
import moment from 'moment';

const HistoryTable = ({ tableData, handleUpdateCategory, refetch, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();

  // const handleRowClick = (ticket, type) => {
  //   handleUpdateCategory(ticket, type);
  // };

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên tài khoản',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('level', {
        header: 'Cấp độ (Nhà cái)',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('kkLevel', {
        header: 'Cấp độ HTTĐ',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('gameType', {
        header: 'Loại game',
        cell: info => GAME_TYPE_MISSION[info.getValue()],
      }),
      columnHelper.accessor('totalValidBetAmount', {
        header: 'DTC trong ngày',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('totalRechargeAmount', {
        header: 'Tổng nạp trong ngày',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('date', {
        header: 'Ngày nạp/cược',
        cell: info => formatDate(info.getValue(), "DD-MM-YYYY"),
      }),
    ],
    [tableData]
  );

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default HistoryTable;
