import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Tooltip, FormControl, Switch } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE } from 'constants/common';
import { MdOutlineAddIcCall, MdOutlinePauseCircleFilled, MdOutlinePhoneCallback } from 'react-icons/md';
import { IoIosSwitch } from 'react-icons/io';
import moment from 'moment';
import { toast } from 'components/Toast';
import { usePauseStaffHotlineAutodialerCampaignMutation, useReceiveCallHotlineAutodialerCampaignMutation, useReceiveHotlineAutodialerCampaignMutation, useStatusJoinHotlineAutodialerCampaignMutation } from 'services/campaign';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const SizeTable = ({ categorysData, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const pauseStaffHotlineAutodialerCampaignMutation = usePauseStaffHotlineAutodialerCampaignMutation();
  const receiveHotlineAutodialerCampaignMutation = useReceiveHotlineAutodialerCampaignMutation();
  const receiveCallHotlineAutodialerCampaignMutation = useReceiveCallHotlineAutodialerCampaignMutation();
  const statusJoinHotlineAutodialerCampaignMutation = useStatusJoinHotlineAutodialerCampaignMutation();
  let query = useLocation();

  const handleDeleteSize = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn dừng staff này không?');
    if (!confirmDelete && queryString.parse(query?.search)?.campaign_id) {
      return;
    }
    pauseStaffHotlineAutodialerCampaignMutation.mutate(
      { userId: campaign?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Dừng staff thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Dừng staff không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleReceive = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn nghe cuộc gọi này không?');
    if (!confirmDelete) {
      return;
    }
    receiveHotlineAutodialerCampaignMutation.mutate(
      { userId: campaign?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Nghe cuộc gọi thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Nghe cuộc gọi không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleReceiveCall = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn nghe gọi cuộc gọi này không?');
    if (!confirmDelete) {
      return;
    }
    receiveCallHotlineAutodialerCampaignMutation.mutate(
      { userId: campaign?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Nghe gọi cuộc gọi thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Nghe gọi cuộc gọi không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleStatusSize = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn chuyển trạng thái join của staff này không?');
    if (!confirmDelete && queryString.parse(query?.search)?.campaign_id) {
      return;
    }
    statusJoinHotlineAutodialerCampaignMutation.mutate(
      { userId: campaign?._id, campaign_id: queryString.parse(query?.search)?.campaign_id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Chuyển trạng thái join của staff thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Chuyển trạng thái join của staff không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('extensionId', {
        header: 'Số máy chủ',
        cell: info => info.getValue() || '--',
      }),
      // columnHelper.accessor('groups', {
      //   header: 'Nhóm',
      //   cell: info =>
      //     info
      //       .getValue()
      //       ?.map(item => item.name)
      //       .join(', ') || '--',
      // }),
      // columnHelper.accessor('permission', {
      //   header: 'Quyền truy cập',
      //   cell: info =>
      //     info
      //       .getValue()
      //       ?.map(item => item)
      //       .join(', ') || '--',
      // }),
      columnHelper.accessor('statusList', {
        header: 'Trạng thái',
        cell: info => {
          return (
            <FormControl display="flex" alignItems="center">
              <Switch
                isChecked={info?.row?.original?.campaign_id === queryString.parse(query?.search)?.campaign_id ? info.getValue() : false}
              />
            </FormControl>
          );
        },
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            {info?.row?.original?.callId && (
              <>
                <Tooltip label="Nghe" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleReceive(info?.row?.original)}>
                    <MdOutlinePhoneCallback cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
                <Tooltip label="Nghe gọi" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleReceiveCall(info?.row?.original)}>
                    <MdOutlineAddIcCall cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip label="Chuyển trạng thái join" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleStatusSize(info?.row?.original)}>
                <IoIosSwitch cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
            <Tooltip label="Dừng staff" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original)}>
                <MdOutlinePauseCircleFilled cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
