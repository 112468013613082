import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Tooltip, Text } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { CampaignAutoTemplateStatus, FORMAT_DATE, ModalType, Roles, ROOT_API } from 'constants/common';
import moment from 'moment';
import { toast } from 'components/Toast';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useActionCampaignAutodialerMutation, useDeleteCampaignMutation } from 'services/campaign';
import { FaFileDownload, FaHistory, FaRegStopCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useUserState } from 'context/UserContext';
import { MdListAlt } from 'react-icons/md';
import { VscCallIncoming, VscDebugStart } from 'react-icons/vsc';
import { useRecallAutodialerHotlineListMutation } from 'services/ips';
import axios from 'axios';
import { CookieStorage } from 'utils/cookie-storage';
import { downloadFile } from 'utils/helpers';

const SizeTable = ({ categorysData, refetch, handleUpdateCategory }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteCampaignMutation = useDeleteCampaignMutation();
  const history = useHistory();
  const { userInfo } = useUserState();
  const recallHotline = useRecallAutodialerHotlineListMutation();
  const actionCampaignMutation = useActionCampaignAutodialerMutation();

  const handleDeleteSize = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    deleteCampaignMutation.mutate(
      { campaign_id: campaign?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleDowloadListPhone = async campaign => {
    const res = await axios.get(ROOT_API + '/api/v1/lists/export-lead', {
      headers: { 'Content-Type': 'application/json', xToken: CookieStorage.getAccessToken() },
      responseType: 'arraybuffer',
      params: {
        list_id: campaign?._id,
      },
    });
    if (res.status === 200) {
      downloadFile(res?.data, 'list-phone');
      toast.showMessageSuccess('Tải danh sách số điện thoại thành công');
    }
  };

  const handleStart = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn bắt đầu chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    actionCampaignMutation.mutate(
      { campaign_id: campaign?.campaign?._id, type: 'start' },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Bắt đầu chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Bắt đầu chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleStop = async campaign => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn dừng chiến dịch này không?');
    if (!confirmDelete) {
      return;
    }
    actionCampaignMutation.mutate(
      { campaign_id: campaign?.campaign?._id, type: 'stop' },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Dừng chiến dịch thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Dừng chiến dịch không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('list_name', {
        header: 'Tên chiến dịch',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('campaign', {
        header: 'Số lượng cuộc gọi đồng thời',
        cell: info => info.getValue()?.concurrent_calc || '--',
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('group', {
        header: 'Nhóm',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('campaign', {
        header: 'Phân bổ',
        cell: info => info.getValue()?.user?.username || '--',
      }),
      columnHelper.accessor('campaign', {
        header: 'Số cuộc thất bại',
        cell: info => (
          <Text fontSize="20px" color="tomato">
            {info?.row?.original?.campaign?.total_failed}
          </Text>
        ),
      }),
      columnHelper.accessor('campaign', {
        header: 'Số cuộc gọi thành công',
        cell: info => (
          <Text fontSize="20px" color="blue.500">
            {info?.row?.original?.campaign?.total_success}
          </Text>
        ),
      }),
      columnHelper.accessor('campaign', {
        header: 'Tiến trình',
        cell: info => (
          info.getValue()?.processing ? info.getValue()?.processing : `${info.getValue()?.total_success + info.getValue()?.total_failed}/${info.getValue()?.total_phone}`
        ),
      }),
      columnHelper.accessor('campaign', {
        header: 'Trạng thái',
        cell: info => (info.getValue() ? CampaignAutoTemplateStatus[info.getValue()?.status] : '--'),
      }),
      columnHelper.accessor('active', {
        header: 'Trạng thái kích hoạt',
        cell: info => (info.getValue() ? 'Đã kích hoạt' : 'Chưa kích hoạt'),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            {/* <Tooltip label="Chi tiết" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => history.push('/admin/hotline/' + info?.row?.original?._id)}>
                <FaList cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip> */}
            <Flex alignItems="center">
              {/* {userInfo?.role !== Roles.LEADER && (
                <Tooltip label="Phân bổ" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Block)}>
                    <MdAssignmentTurnedIn cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
              )} */}
              {([Roles.SUPER_ADMIN, Roles.ADMIN].includes(userInfo?.role) ||
                userInfo?._id === (info?.row?.original?.createBy?._id || info?.row?.original?.campaign?.createBy?._id) ||
                userInfo?._id === (info?.row?.original?.user?._id || info?.row?.original?.campaign?.user?._id)) && (
                <>
                  {info?.row?.original?.group && (
                    <Tooltip label="Danh sách nhân viên" aria-label="A tooltip" rounded={'6px'}>
                      <IconButton
                        bg="transparent"
                        onClick={() => {
                          history.push(`/admin/staff/${info?.row?.original?.group?._id}?campaign_id=${info?.row?.original?.campaign?._id}`);
                        }}
                      >
                        <MdListAlt cursor="pointer" boxSize={4} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip label="Lịch sử" aria-label="A tooltip" rounded={'6px'}>
                    <IconButton
                      bg="transparent"
                      onClick={() => history.push(`/admin/histories-hotline-autodialer/${info?.row?.original?.campaign?._id}`)}
                    >
                      <FaHistory cursor="pointer" boxSize={4} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip label="Tải danh sách số điện thoại" aria-label="A tooltip" rounded={'6px'}>
                    <IconButton
                      bg="transparent"
                      onClick={() => {
                        handleDowloadListPhone(info?.row?.original);
                      }}
                    >
                      <FaFileDownload cursor="pointer" boxSize={4} />
                    </IconButton>
                  </Tooltip>
                  {/* {[Roles.ADMIN, Roles.SUPER_ADMIN, Roles.LEADER].includes(userInfo?.role) && (
                  <Tooltip label="Import số điện thoại" aria-label="A tooltip" rounded={'6px'}>
                    <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Assign)}>
                      <MdCall cursor="pointer" boxSize={4} />
                    </IconButton>
                  </Tooltip>
                )} */}
                  {/* {[Roles.ADMIN, Roles.SUPER_ADMIN].includes(userInfo?.role) && ( */}
                  <>
                    {/* <Tooltip label="Thêm group" aria-label="A tooltip" rounded={'6px'}>
                      <IconButton bg="transparent" onClick={() => handleUpdateCategory(info?.row?.original, ModalType.EmailBackup)}>
                        <FaPlusCircle cursor="pointer" boxSize={4} />
                      </IconButton>
                    </Tooltip> */}
                    {!isEmpty(info?.row?.original?.group) && info?.row?.original?.campaign?.status === 'FINISHED' && (
                      <Tooltip label="Gọi lại" aria-label="A tooltip" rounded={'6px'}>
                        <IconButton
                          bg="transparent"
                          onClick={() => handleUpdateCategory(info?.row?.original, ModalType.Import)}
                        >
                          <VscCallIncoming cursor="pointer" boxSize={4} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {info?.row?.original?.campaign_uuid && info?.row?.original?.group?._id && (
                      <>
                        {(info?.row?.original?.campaign?.status === 'STOP' || info?.row?.original?.campaign?.status === 'PENDING') && (
                          <Tooltip label="Bắt đầu" aria-label="A tooltip" rounded={'6px'}>
                            <IconButton bg="transparent" onClick={() => handleStart(info?.row?.original)}>
                              <VscDebugStart cursor="pointer" boxSize={4} />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip label="Dừng" aria-label="A tooltip" rounded={'6px'}>
                          <IconButton bg="transparent" onClick={() => handleStop(info?.row?.original)}>
                            <FaRegStopCircle cursor="pointer" boxSize={4} />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {/* <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
                      <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original)}>
                        <AiFillDelete color="red" cursor="pointer" boxSize={4} />
                      </IconButton>
                    </Tooltip> */}
                  </>
                  {/* )} */}
                </>
              )}
            </Flex>
            {(([Roles.LEADER].includes(userInfo?.role) && userInfo?._id === info?.row?.original?.createBy?._id) ||
              [Roles.ADMIN, Roles.SUPER_ADMIN].includes(userInfo?.role)) && (
              <>
                <Tooltip label="Cập nhật" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleUpdateCategory({
                    ...info?.row?.original?.campaign,
                    company: info?.row?.original?.company,
                    group: info?.row?.original?.group,
                  }, ModalType.Add)}>
                    <EditIcon cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
                <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
                  <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original?.campaign)}>
                    <DeleteIcon color="red" cursor="pointer" boxSize={4} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
