import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getEmailTemplate = params => request.get('/api/v1/templates', { params });
export const deleteCampaign = data => request.post('/api/v1/hotlineCampaign/delete', data);
export const createTemplates = data => request.post('/api/v1/templates', data);
export const updateTemplates = data => request.post('/api/v1/templates/update', data);
export const listTemplateAssign = params => request.get('/api/v1/templates/list-assign', { params });
export const deleteTemplates = data => request.post('/api/v1/templates/delete', data);
export const activeTemplates = data => request.post('/api/v1/templates/active', data);
export const sendSms = data => request.post('/api/v1/hotline/send-sms', data);

// Mutation
export const useDeleteTemplateMutation = () => useMutation({ mutationFn: deleteTemplates });
export const useCreateTemplateMutation = () => useMutation({ mutationFn: createTemplates });
export const useUpdateTemplateMutation = () => useMutation({ mutationFn: updateTemplates });
export const useActiveTemplateMutation = () => useMutation({ mutationFn: activeTemplates });
export const useSendSmsMutation = () => useMutation({ mutationFn: sendSms });

// Query
export const useQueryGetEmailTemplate = (params = {}, options = {}) => useQuery({ queryKey: ['getEmailTemplate', params], queryFn: () => getEmailTemplate(params), ...options });
export const useQueryGetTemplateAssign = (params = {}, options = {}) => useQuery({ queryKey: ['listTemplateAssign', params], queryFn: () => listTemplateAssign(params), ...options });
