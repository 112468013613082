import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ModalType, Roles } from 'constants/common';
import { toast } from 'components/Toast';
import { CreateExtensionFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { useCreateExtensionMutation, useQueryGetCompany } from 'services/company';
import { useUserState } from 'context/UserContext';
import { CookieStorage } from 'utils/cookie-storage';
import InputController from 'components/Form/InputController';

const FormCreateExtension = ({ isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const { userInfo } = useUserState();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(CreateExtensionFormValidate),
  });
  const createExtensionMutation = useCreateExtensionMutation();
  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    const payload = {
      extension: values?.extension,
      company_id: values.company_id?.value,
    };
    createExtensionMutation.mutate(payload, {
      onSuccess: () => handleSuccess(`Tạo số máy chủ thành công`),
    });
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Tạo số máy chủ</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="extension"
              label="Số lượng máy chủ"
              styleContainer={{
                marginBottom: '12px',
              }}
            />
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Chi nhánh"
              name="company_id"
              placeholder="Chọn chi nhánh"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(company?.data, 'name', '_id')}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createExtensionMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormCreateExtension;
