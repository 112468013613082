import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType, Roles } from 'constants/common';
// import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import ModalShowDetailMember from './components/ModalShowDetailMember';
import { useChangeStatusExtensionMutation, useQueryGetExtensions } from 'services/ips';
import FormAssignPhoneUser from './components/FormAssignPhoneUser';
import { useUserState } from 'context/UserContext';
import FormCreateExtension from './components/FormCreateExtension';
import { FiServer } from 'react-icons/fi';
import { Select } from 'chakra-react-select';
import { mappingOptionSelect } from 'utils/mapping';
import { useQueryGetGroups } from 'services/group';
import DatePicker from 'components/DatePicker/DatePicker';
import { useQuerGetUsers } from 'services/user';
import { toast } from 'components/Toast';

const initFilter = {
  companyId: null,
  groupId: null,
  userId: null,
};

function Connect() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [idMember, setIdMember] = useState();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const { userInfo } = useUserState();
  const [searchTitle, setSearchTitle] = useState('');
  const [hotlineFilter, setHotlineFilter] = useState(initFilter);
  const [filterDate, setFilterDate] = useState({
    start: null,
  });
  const [extensionSelected, setExtensionSelected] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const changeStatusExtensionMutation = useChangeStatusExtensionMutation();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isCreateModalAssignOpen, onOpen: onOpenCreateAssignModal, onClose: onCloseCreateAssignModal } = useDisclosure();
  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const {
    isOpen: isOpenViewMemberDetailModal,
    onOpen: onOpenViewMemberDetailModal,
    onClose: onCloseViewMemberDetalModal,
  } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Preview]: onOpenViewMemberDetailModal,
      [ModalType.Assign]: onOpenCreateAssignModal,
      [ModalType.Add]: onOpenCreateModal,
    }),
    [onOpenViewMemberDetailModal, onOpenCreateAssignModal, onOpenCreateModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Assign]: onCloseCreateAssignModal,
      [ModalType.Preview]: onCloseViewMemberDetalModal,
      [ModalType.Add]: onCloseCreateModal,
    }),
    [onCloseCreateAssignModal, onCloseViewMemberDetalModal, onCloseCreateModal]
  );

  const { data: extensions, refetch } = useQueryGetExtensions({ ...filter }, { enabled: isLoggedIn });
  const { data: groups } = useQueryGetGroups({}, { enabled: isLoggedIn });
  const { data: members } = useQuerGetUsers({}, { enabled: isLoggedIn });

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
    setIdMember(null);
  };

  const getColor = item => {
    if (!item?.enabled)
      return {
        title: 'Chưa sẵn sàng',
        colorIcon: 'gray',
      };
    if (item?.is_oncall)
      return {
        title: 'Đang có cuộc gọi',
        colorIcon: 'orange',
      };
    if (item?.enabled && !item?.is_logged_in)
      return {
        title: 'Chưa kết nối',
        colorIcon: 'red',
      };
    if (item?.enabled && item?.is_logged_in)
      return {
        title: 'Đã kết nối',
        colorIcon: 'green',
      };
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      ...(hotlineFilter?.userId && { userId: hotlineFilter?.userId?.value }),
      ...(hotlineFilter?.groupId && { groupId: hotlineFilter?.groupId?.value }),
      ...(filterDate?.start && { date: moment(filterDate?.start).startOf('day').toISOString() }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 50,
    });
    setHotlineFilter(initFilter);
    setSearchTitle('');
    setFilterDate({
      start: null,
    });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    setExtensionSelected([]);
    setCheckedAll(false);
  };

  const handleActionExtensionStatus = value => {
    let payload = null;
    if (checkedAll) {
      payload = {
        status: value,
        type: 'all',
        extensionIds: extensionSelected,
      };
    } else {
      payload = {
        extensionIds: extensionSelected,
        status: value,
      };
    }
    changeStatusExtensionMutation.mutate(payload, {
      onSuccess: () => handleSuccess('Thay đổi trạng thái cho máy chủ thành công'),
    });
  };

  const handleExport = () => {};
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Quản lý máy chủ
                </Text>
                <Flex gap={4}>
                  <Button
                    variant="primary"
                    px={5}
                    maxH="40px"
                    alignSelf={'end'}
                    onClick={() => handleActionExtensionStatus(true)}
                    isDisabled={extensionSelected.length === 0}
                    isLoading={changeStatusExtensionMutation.isPending}
                  >
                    <Text fontSize="md" fontWeight="normal" cursor="pointer">
                      Sẵn sàng
                    </Text>
                  </Button>
                  <Button
                    variant="primary"
                    px={5}
                    maxH="40px"
                    alignSelf={'end'}
                    onClick={() => handleActionExtensionStatus(false)}
                    isDisabled={extensionSelected.length === 0}
                    isLoading={changeStatusExtensionMutation.isPending}
                  >
                    <Text fontSize="md" fontWeight="normal" cursor="pointer">
                      Chưa Sẵn sàng
                    </Text>
                  </Button>
                  {userInfo?.role === Roles.SUPER_ADMIN && (
                    <Button variant="primary" px={5} maxH="40px" alignSelf={'end'} onClick={onOpenCreateModal}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Tạo mới
                      </Text>
                    </Button>
                  )}
                  <Button variant="primary" px={5} maxH="40px" alignSelf={'end'} onClick={handleExport}>
                    <Text fontSize="md" fontWeight="normal" cursor="pointer">
                      Xuất file excel
                    </Text>
                  </Button>
                </Flex>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={'wrap'}>
                    <FormControl width={'300px'}>
                      <FormLabel>Tìm kiếm</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <FormControl w={'300px'}>
                      <FormLabel>Nhóm</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        value={hotlineFilter?.groupId}
                        onChange={e => {
                          setHotlineFilter({
                            ...hotlineFilter,
                            groupId: e,
                          });
                        }}
                        options={mappingOptionSelect(groups?.data, 'name', '_id')}
                      ></Select>
                    </FormControl>
                    <FormControl w={'300px'}>
                      <FormLabel>Nhân viên</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        value={hotlineFilter?.userId}
                        onChange={e => {
                          setHotlineFilter({
                            ...hotlineFilter,
                            userId: e,
                          });
                        }}
                        options={mappingOptionSelect(members?.data, 'username', '_id')}
                      ></Select>
                    </FormControl>
                    <Flex alignItems={'center'} gap={5}>
                      <FormControl display="flex" flexDirection="column" w="300px" mr="12px">
                        <FormLabel marginRight={0} fontSize={14} isTruncated>
                          Ngày
                        </FormLabel>
                        <DatePicker selectedDate={filterDate.start} onChange={date => onChangeDate('start')(date)} />
                      </FormControl>
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                    {/* <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="normal" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button> */}
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Checkbox
            isChecked={checkedAll}
            onChange={e => {
              if (e.target.checked) {
                setCheckedAll(true);
                setExtensionSelected(extensions?.data?.map(item => item._id));
              } else {
                setCheckedAll(false);
                setExtensionSelected([]);
              }
            }}
          >
            Chọn tất cả
          </Checkbox>
          <Grid mt={2} flexWrap={'wrap'} templateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)' }} gap={6}>
            {extensions?.data?.map(item => (
              <Tooltip
                label={
                  <GridItem
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={2}
                    w="100%"
                    rounded={4}
                    p={3}
                    key={item._id}
                  >
                    <Flex gap={5}>
                      <Flex flexDirection="column">
                        <Flex alignItems={'center'} gap={2}>
                          <Text fontStyle={'italic'} isTruncated>
                            Nhóm 1: {item?.user?.groups?.[0]?.name}
                          </Text>
                        </Flex>
                        <Flex alignItems={'center'} gap={2}>
                          <Text fontStyle={'italic'} isTruncated>
                            Nhóm 2: {item?.user?.groups?.[1]?.name}
                          </Text>
                        </Flex>
                        <Flex alignItems={'center'} gap={2}>
                          <Text fontStyle={'italic'} isTruncated>
                            Trạng thái:
                          </Text>
                          <Text isTruncated>{getColor(item).title}</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </GridItem>
                }
                aria-label="A tooltip"
                rounded={'6px'}
              >
                <GridItem
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={2}
                  w="100%"
                  border="1px solid #ccc"
                  rounded={4}
                  p={3}
                  key={item._id}
                >
                  <Flex gap={5}>
                    <FiServer color={getColor(item).colorIcon} size={20} height={'100%'} />
                    <Flex flexDirection="column">
                      <Flex alignItems={'center'} gap={2}>
                        <Text fontStyle={'italic'}>Máy chủ:</Text>
                        <Text isTruncated>{item.extension}</Text>
                      </Flex>
                      <Flex alignItems={'center'} gap={2}>
                        <Text fontStyle={'italic'} isTruncated>
                          Chi nhánh:
                        </Text>
                        <Text isTruncated>{item?.company?.name}</Text>
                      </Flex>
                      <Flex alignItems={'center'} gap={2}>
                        <Text fontStyle={'italic'} isTruncated>
                          Trạng thái:
                        </Text>
                        <Text isTruncated>{getColor(item).title}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Checkbox
                    name={item._id}
                    display={'flex'}
                    alignItems={'start'}
                    h={'100%'}
                    onChange={e => {
                      if (e.target.checked) {
                        setExtensionSelected([...extensionSelected, item._id]);
                      } else {
                        setExtensionSelected(extensionSelected.filter(item => item !== e.target.name));
                      }
                    }}
                    isChecked={extensionSelected.includes(item._id)}
                  />
                </GridItem>
              </Tooltip>
            ))}
          </Grid>
          <Text fontStyle={'italic'} mt={10}>
            Chú thích:
          </Text>
          <Flex alignItems={'center'} gap={4} mt={3}>
            <Flex alignItems={'center'} gap={2}>
              <FiServer color={'red'} size={20} height={'100%'} />
              <Text isTruncated>Chưa kết nối (Chưa kết nối Mirosip)</Text>
            </Flex>
            <Flex alignItems={'center'} gap={2}>
              <FiServer color={'gray'} size={20} height={'100%'} />
              <Text isTruncated>Chưa sẵn sàng</Text>
            </Flex>
            <Flex alignItems={'center'} gap={2}>
              <FiServer color={'orange'} size={20} height={'100%'} />
              <Text isTruncated>Đang có cuộc gọi</Text>
            </Flex>
            <Flex alignItems={'center'} gap={2}>
              <FiServer color={'green'} size={20} height={'100%'} />
              <Text isTruncated>Đã kết nối (Đã kết nối Mirosip)</Text>
            </Flex>
          </Flex>
          <Flex justifyContent={'flex-end'} mt={4}>
            <Pagination
              page={extensions?.pagination?.page}
              pageLength={extensions?.pagination?.pageSize}
              totalRecords={extensions?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isOpenViewMemberDetailModal && (
        <ModalShowDetailMember isOpen={isOpenViewMemberDetailModal} onClose={handleCloseModal} memberDetail={sizeEditing} />
      )}
      {isCreateModalAssignOpen && (
        <FormAssignPhoneUser companyDetail={sizeEditing} isOpen={isCreateModalAssignOpen} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isCreateModalOpen && <FormCreateExtension isOpen={isCreateModalOpen} onClose={handleCloseModal} refetch={refetch} />}
    </Flex>
  );
}

export default Connect;
