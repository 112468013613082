import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getProfile = () => request.get('/api/v1/user/profile');
export const updateProfile = async data => {
  return await request.post('/api/v1/user/change-information', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const register = data => request.post('/api/v1/user/register', data);
export const createUserManager = data => request.post('/api/v1/user/new-user', data);
export const deleteUserManager = data => request.post('/api/v1/user/delete', data);
export const changePassword = data => request.post('/api/v1/user/change-pw', data);
export const changePasswordVersionV2 = data => request.post('/api/v1/user/change-pw', data);
export const login = data => request.post('/api/v1/user/login', data);
export const bookmarker = (params) => request.get('/api/v1/setting/book-markers', { params });
export const users = (params) => request.get('/api/v1/user/users', { params });
export const staffs = (params) => request.get('/api/v1/user/list-staffs', { params });
export const createBookmarker = data => request.post('/api/v1/setting/book-markers', data);
export const deleteBookmarker = data => request.post('/api/v1/setting/book-markers/delete', data);
export const createUser = data => request.post('/api/v1/user/register', data);
export const updateUser = data => request.post('/api/v1/user/update', data);
export const resetPassword = data => request.post('/api/v1/user/reset-password', data);
export const deleteUser = data => request.post('/api/v1/user/delete', data);
export const assignPermission = data => request.post('/api/v1/user/assign-permissions', data);
export const assignPhoneUser = data => request.post('/api/v1/phone/assign-multiple', data);
export const templateAutoCall = data => request.post('/api/v1/hotline/auto-call', data);
export const voiceTemplateAutoCall = data => request.post('/api/v1/hotline/auto-call-template', data);
export const assignCampaignLeader = data => request.post('/api/v1/hotlineCampaign/assign', data);
export const exportTempleteMember = (params, headers) => request.get('/api/v1/mission-member/member-histories', {params, ...headers});
export const listLeader = (params) => request.get('/api/v1/user/list-leaders', { params });
export const inforExtension = (params) => request.get('/api/v1/extensions/profile', { params });
export const changeStatusExtension = data => request.post('/api/v1/extensions/user-change-status', data);

// Mutation
export const useRegisterMutation = () => useMutation({ mutationFn: register });
export const useCreateUserManagerMutation = () => useMutation({ mutationFn: createUserManager });
export const useDeleteUserManagerMutation = () => useMutation({ mutationFn: deleteUserManager });
export const useLoginMutation = () => useMutation({ mutationFn: login });
export const useChangePasswordMutation = () => useMutation({ mutationFn: changePassword });
export const useChangePasswordV2Mutation = () => useMutation({ mutationFn: changePasswordVersionV2 });
export const useUpdateProfileMutation = () => useMutation({ mutationFn: updateProfile });
export const useCreateBookmarkerMutation = () => useMutation({ mutationFn: createBookmarker });
export const useDeleteBookmarkerMutation = () => useMutation({ mutationFn: deleteBookmarker });
export const useCreateUserMutation = () => useMutation({ mutationFn: createUser });
export const useUpdateUserMutation = () => useMutation({ mutationFn: updateUser });
export const useDeleteUserMutation = () => useMutation({ mutationFn: deleteUser });
export const useAssignPermissionMutation = () => useMutation({ mutationFn: assignPermission });
export const useAssignPhoneUserMutation = () => useMutation({ mutationFn: assignPhoneUser });
export const useResetPasswordUserMutation = () => useMutation({ mutationFn: resetPassword });
export const useVoiceTemplateAutoCallMutation = () => useMutation({ mutationFn: voiceTemplateAutoCall });
export const useTemplateAutoCallMutation = () => useMutation({ mutationFn: templateAutoCall });
export const useChangeStatusExtensionMutation = () => useMutation({ mutationFn: changeStatusExtension });
export const useAssignLeaderCampaignMutation = () => useMutation({ mutationFn: assignCampaignLeader });
export const useExportCoinTemplateMemberMutation = (params, headers) => useMutation({ mutationFn: () => exportTempleteMember(params, headers) });

// Query
export const useQueryUserProfile = (options = {}) => useQuery({ queryKey: ['getUserProfile'], queryFn: () => getProfile(), ...options });
export const useQueryListLeader = (params = {}, options = {}) => useQuery({ queryKey: ['listLeader', params], queryFn: () => listLeader(params), ...options });
export const useQuerGetUsers = (params, options = {}) => useQuery({ queryKey: ['getUsers', params], queryFn: () => users(params), ...options });
export const useQueryBookMarker = (params, options = {}) => useQuery({ queryKey: ['getBookMarker', params], queryFn: () => bookmarker(params), ...options });
export const useQueryStaffs = (params, options = {}) => useQuery({ queryKey: ['staffs', params], queryFn: () => staffs(params), ...options });
export const useQueryInforExtension = (params, options = {}) => useQuery({ queryKey: ['inforExtension', params], queryFn: () => inforExtension(params), ...options });
