import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getStatistic = (params) => request.get('/api/v1/statistics', { params });
export const register = data => request.post('/api/v1/user/register', data);
export const getStatisticReportCompany = (params) => request.get('/api/v1/hotline/statistics/company', { params });
export const getStatisticReportExtension = (params) => request.get('/api/v1/hotline/statistics/extension', { params });
export const getStatisticReportInDay = (params) => request.get('/api/v1/hotline/statistics/in-day', { params });
export const getStatisticReportDashboard = (params) => request.get('/api/v1/hotline/statistics/dashboard', { params });
export const getStatisticReportHotline = (params) => request.get('/api/v1/hotline/statistics/all', { params });

// Mutation
export const useRegisterMutation = () => useMutation({ mutationFn: register });

// Query
export const useQueryGetStatistic = (params = {}, options = {}) => useQuery({ queryKey: ['getStatistic', params], queryFn: () => getStatistic(params), ...options });
export const useQueryGetStatisticReportCompany = (params = {}, options = {}) => useQuery({ queryKey: ['getStatisticReportCompany', params], queryFn: () => getStatisticReportCompany(params), ...options });
export const useQueryGetStatisticReportExtension = (params = {}, options = {}) => useQuery({ queryKey: ['getStatisticReportExtension', params], queryFn: () => getStatisticReportExtension(params), ...options });
export const useQueryGetStatisticReportInDay = (params = {}, options = {}) => useQuery({ queryKey: ['getStatisticReportInDay', params], queryFn: () => getStatisticReportInDay(params), ...options });
export const useQueryGetStatisticReportDashboard = (params = {}, options = {}) => useQuery({ queryKey: ['getStatisticReportDashboard', params], queryFn: () => getStatisticReportDashboard(params), ...options });
export const useQueryGetStatisticReportHotline = (params = {}, options = {}) => useQuery({ queryKey: ['getStatisticReportHotline', params], queryFn: () => getStatisticReportHotline(params), ...options });
