import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { FormAutoCallValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAutoCallHotline, useQueryGetListPhone, useUpdatePhone, usecreatePhone } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { CookieStorage } from 'utils/cookie-storage';
import { useQueryGetCampaign } from 'services/campaign';

const FormAutoCall = ({ companyDetail, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const createPhone = usecreatePhone();
  const updatePhone = useUpdatePhone();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(FormAutoCallValidate),
  });
  const { data: campaigns } = useQueryGetCampaign({}, { enabled: isLoggedIn });
  const autoCallHotline = useAutoCallHotline();
  const { data: phones } = useQueryGetListPhone({}, { enabled: isLoggedIn });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.ChangeStatus);
  };

  const onSubmit = async values => {
    const payload = {
      phones: values.phones?.map(phone => phone?.label),
      campaign_id: values.campaign_id?.value,
    };
    autoCallHotline.mutate(payload, {
      onSuccess: () => handleSuccess(`Gán số điện thoại cho chiến dịch thành công`),
    });
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.ChangeStatus);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Gán số điện thoại tự động trả lời</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController
              isMulti
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Số điện thoại"
              name="phones"
              placeholder="Chọn Số điện thoại"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(phones?.data, 'phone', '_id')}
            />
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Chiến dịch"
              name="campaign_id"
              placeholder="Chọn chiến dịch"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={mappingOptionSelect(campaigns?.data, 'campaign_name', '_id')}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.ChangeStatus);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createPhone.isPending || updatePhone.isPending} onClick={handleSubmit(onSubmit)}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormAutoCall;
