import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdjustCoinOption, ModalType } from 'constants/common';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import NumericInputController from 'components/Form/NumericInputController';
import { AdjustCoinFormValidate } from 'utils/validation';
import { useAdjustCoinMutation } from 'services/purchase-history';
import { toast } from 'components/Toast';

const AdjustCoinModal = ({ memberDetail, isOpen, onClose, refetch }) => {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(AdjustCoinFormValidate),
  });

  const adjustCoinMutation = useAdjustCoinMutation();

  const onSubmit = values => {
    if (memberDetail?._id) {
      adjustCoinMutation.mutate(
        {
          ...values,
          memberId: memberDetail?._id,
          type: values.type.value,
        },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Điều chỉnh coin thành công');
            onClose(ModalType.Adjust);
            refetch();
          },
        }
      );
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Adjust);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">Bù/trừ Coin cho hội viên</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Flex gap="4px">
            <Box>Số coin hiện tại:</Box>
            <Box>{memberDetail?.coin}</Box>
          </Flex>
          <form>
            <SelectController
              control={control}
              name="type"
              label="Loại điều chỉnh"
              options={AdjustCoinOption}
              isRequired
              styleContainer={{ pt: 4 }}
            />
            <NumericInputController control={control} name="coin" label="Số coin" isRequired styleContainer={{ pt: 4 }} />
            <InputController control={control} name="note" label="Lý do" styleContainer={{ pt: 4 }} type="textarea" />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Adjust);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={adjustCoinMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AdjustCoinModal;
