import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { FORMAT_DATE, ModalType } from 'constants/common';
import moment from 'moment';
import { useGetZaloUrlByAppId } from 'services/zalo';

const ModalShowDetailMember = ({ isOpen, onClose, memberDetail }) => {
  const cancelRef = React.useRef();
  const getZaloUrlByAppId = useGetZaloUrlByAppId();
  const [urlZalo, setUrlZalo] = React.useState('');

  const handleGetUrlZalo = () => {
    getZaloUrlByAppId.mutate(
      {
        app_id: memberDetail?.app_id,
      },
      {
        onSuccess: res => {
          setUrlZalo(res?.url);
        },
      }
    );
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết dịch vụ</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <label>Tên dịch vụ</label>
            <Input mb={2} value={memberDetail?.title || '--'} readOnly />
            <label>Slug</label>
            <Input mb={2} value={memberDetail?.slug} readOnly />
            <label>Mô tả dịch vụ</label>
            <Input mb={2} value={memberDetail?.description} readOnly />
            <label>Giá</label>
            <Input mb={2} value={memberDetail?.price || '--'} readOnly />
            <label>Trạng thái phát hành</label>
            <Input mb={2} value={memberDetail?.isPublished ? "Đã phát hành" : "Chưa phát hành"} readOnly />
            <label>Ngày tạo</label>
            <Input mb={2} value={moment(memberDetail?.createdAt).format(FORMAT_DATE)} readOnly />
            <label>Ngày cập nhật</label>
            <Input mb={2} value={moment(memberDetail?.updatedAt).format(FORMAT_DATE)} readOnly />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Preview);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ModalShowDetailMember;
