import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { formatSecondsToHHMMSS } from 'utils/helpers';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('_id', {
        header: 'Số máy chủ',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('count', {
        header: 'Số lượng cuộc gọi',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('totalDuration', {
        header: 'Thời gian tích lũy',
        cell: info => {
          const result = formatSecondsToHHMMSS(info.getValue() || 0);
          return result?.hours + ':' + result?.minutes + ':' + result?.seconds || '--';
        },
      }),
      columnHelper.accessor('totalBillsec', {
        header: 'Số phút tính phí',
        cell: info => {
          const result = formatSecondsToHHMMSS(info.getValue() || 0);
          return result?.hours + ':' + result?.minutes + ':' + result?.seconds || '--';
        },
      }),
      columnHelper.accessor('totalSuccess', {
        header: 'Số cuộc gọi thành công',
        cell: info => info.getValue() || 0,
      }),
      columnHelper.accessor('totalFailed', {
        header: 'Số cuộc gọi thất bại',
        cell: info => info.getValue() || 0,
      }),
      columnHelper.accessor('totalFailed', {
        header: 'Thời lượng trung bình',
        cell: info => Math.round(info?.row?.original?.totalDuration / info?.row?.original?.count, 4),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={10}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
