import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType, Roles, SpeedTTSOption, VoiceTTS, VoiceTTSOption } from 'constants/common';
import { toast } from 'components/Toast';
import { HotlineTemplateFormValidate, HotlineTemplateSuperAdminFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateRecordTemplate } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { useQueryGetCompany } from 'services/company';
import { CookieStorage } from 'utils/cookie-storage';
import { mappingOptionSelect } from 'utils/mapping';
import { useUserState } from 'context/UserContext';

const FormHotlineTemplate = ({ isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const { userInfo } = useUserState();
  const createRecordTemplate = useCreateRecordTemplate();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(userInfo?.role === Roles.SUPER_ADMIN ? HotlineTemplateSuperAdminFormValidate : HotlineTemplateFormValidate),
  });
  const isLoggedIn = CookieStorage.isAuthenticated();
  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    createRecordTemplate.mutate(
      {
        ...values,
        company_id: userInfo?.role === Roles.SUPER_ADMIN ? values?.company_id?.value : userInfo?.company,
        speed_tts: Number(values?.speed_tts?.value),
        voice_tts: values?.voice_tts?.value,
        ...(values?.max_timeout && { max_timeout: Number(values?.max_timeout) }),
      },
      {
        onSuccess: () => handleSuccess(`Tạo gọi văn bản thành công`),
      }
    );
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Tạo thông tin gọi văn bản</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="template_name"
              label="Tên mẫu"
              styleContainer={{
                marginBottom: '12px',
              }}
            />
            <InputController
              control={control}
              isRequired
              name="content"
              label="Nội dung"
              styleContainer={{
                marginBottom: '12px',
              }}
            />
            <InputController
              control={control}
              isRequired
              name="exit_sound"
              label="Nội dung kết thúc cuộc gọi"
              styleContainer={{
                marginBottom: '0px',
              }}
            />
            <SelectController
              control={control}
              name="voice_tts"
              label="Voice TTS"
              options={VoiceTTSOption}
              isRequired
              styleContainer={{ pt: 4 }}
            />
            <SelectController
              control={control}
              name="speed_tts"
              label="Speed TTS"
              options={SpeedTTSOption}
              isRequired
              styleContainer={{ pt: 4 }}
            />
            {userInfo?.role === Roles.SUPER_ADMIN && (
              <SelectController
                control={control}
                name="company_id"
                label="Company"
                options={mappingOptionSelect(company?.data, 'name', '_id')}
                isRequired
                styleContainer={{ pt: 4 }}
              />
            )}
            <InputController
              control={control}
              isRequired
              name="max_timeout"
              label="Số lần lặp lại"
              styleContainer={{
                marginBottom: '0px',
                marginTop: '10px',
              }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createRecordTemplate.isPending} onClick={handleSubmit(onSubmit)}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormHotlineTemplate;
