import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Tooltip, Switch } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType, Roles, RolesUserSuperAdmin } from 'constants/common';
import moment from 'moment';
import { useChangeStatusQuotation } from 'services/quotation';
import { toast } from 'components/Toast';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { useDeleteUserManagerMutation } from 'services/user';
import { IoMdSettings } from 'react-icons/io';
import { useUserState } from 'context/UserContext';

const SizeTable = ({ categorysData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const changeStatusProductMutation = useChangeStatusQuotation();
  const deleteUserManagerMutation = useDeleteUserManagerMutation();
  const { userInfo } = useUserState();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };

  const handleDeleteSize = async user => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn tài khoản này không?');
    if (!confirmDelete) {
      return;
    }
    deleteUserManagerMutation.mutate(
      { id: user?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tài khoản thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Tài khoản không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên tài khoản',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('groups', {
        header: 'Nhóm',
        cell: info => info.getValue()?.map(item => item?.name)?.join(', ') || '--',
      }),
      columnHelper.accessor('extensionId', {
        header: 'Số máy chủ',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('role', {
        header: 'Role',
        cell: info => RolesUserSuperAdmin[info.getValue()],
      }),
      columnHelper.accessor('showPhone', {
        header: 'Hiển thị số điện thoại',
        cell: info => <Switch isChecked={info.getValue()} />,
      }),
      columnHelper.accessor('company', {
        header: 'Chi nhánh',
        cell: info => info.getValue()?.name,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            {/* <Tooltip label="Cài đặt số máy chủ" aria-label="A tooltip" rounded={'6px'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleRowClick(info?.row?.original, ModalType.Assign);
                }}
              >
                <IoMdSettings cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip> */}
            <Tooltip label="Cập nhật" aria-label="A tooltip" rounded={'6px'}>
              <IconButton bg="transparent" onClick={() => handleRowClick(info?.row?.original, ModalType.Add)}>
                <AiFillEdit cursor="pointer" boxSize={4} />
              </IconButton>
            </Tooltip>
            {userInfo?.role === Roles.SUPER_ADMIN && (
              <Tooltip label="Xóa" aria-label="A tooltip" rounded={'6px'}>
                <IconButton bg="transparent" onClick={() => handleDeleteSize(info?.row?.original)}>
                  <AiFillDelete color="red" cursor="pointer" boxSize={4} />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
