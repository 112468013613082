import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getGroups = params => request.get('/api/v1/groups', { params });
export const createGroup = data => request.post('/api/v1/groups', data);
export const deleteGroup = data => request.post('/api/v1/groups/delete', data);
export const assignStaffGroup = data => request.post('/api/v1/groups/add-staff', data);
export const groupListAssign = params => request.get('/api/v1/groups/list-assign', { params });

// Mutation
export const useCreateGroupMutation = () => useMutation({ mutationFn: createGroup });
export const useDeleteGroupMutation = () => useMutation({ mutationFn: deleteGroup });
export const useAssignStaffGroupMutation = () => useMutation({ mutationFn: assignStaffGroup });

// Query
export const useQueryGetGroups = (params = {}, options = {}) => useQuery({ queryKey: ['getGroups', params], queryFn: () => getGroups(params), ...options });
export const useQueryGroupListAssign = (params = {}, options = {}) => useQuery({ queryKey: ['groupListAssign', params], queryFn: () => groupListAssign(params), ...options });
