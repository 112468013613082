import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType } from 'constants/common';
import SizeTable from './components/Table';
import CreateCompanyModal from './components/CreateAdminModal';
import Pagination from 'components/Pagination/Pagination';
import UpdatePassword from './components/UpdatePassword';
import AssignPermission from './components/AssignPermission';
import { useUserState } from 'context/UserContext';
import { useQueryGetCompany } from 'services/company';

function Admin() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isUpdatePasswordModalOpen, onOpen: onOpenUpdatePasswordModal, onClose: onCloseUpdatePasswordModal } = useDisclosure();
  const {
    isOpen: isAssignPermissionModalOpen,
    onOpen: onOpenAssignPermissionModal,
    onClose: onCloseAssignPermissionModal,
  } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.ResetPassword]: onOpenUpdatePasswordModal,
      [ModalType.EmailBackup]: onOpenAssignPermissionModal,
    }),
    [onOpenCreateModal, onOpenUpdatePasswordModal, onOpenAssignPermissionModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.ResetPassword]: onCloseUpdatePasswordModal,
      [ModalType.EmailBackup]: onCloseAssignPermissionModal,
    }),
    [onCloseCreateModal, onCloseUpdatePasswordModal, onCloseAssignPermissionModal]
  );

  const { data: company, refetch } = useQueryGetCompany({ ...filter }, { enabled: CookieStorage.isAuthenticated() });

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setSearchTitle('');
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Quản lý chi nhánh
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm chi nhánh</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <Button maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" px={3} fontWeight="normal" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" px={3} fontWeight="normal" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
            <Flex>
              {/* <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadTemplate}>
                Tải template
              </Button>
              <input type="file" hidden ref={inputImportRef} onChange={handleImportProduct} />
                <Button
                  bg="#3182ce"
                  color="#fff"
                  margin="0 16px"
                  _hover={{ bg: '#67a1d7' }}
                  onClick={() => {
                    inputImportRef?.current?.click();
                  }}
                >
                  Import danh mục
                </Button> */}
                <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                  <Text fontSize="md" fontWeight="bold" cursor="pointer">
                    Thêm
                  </Text>
                </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable userInfo={userInfo} users={company?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={company?.pagination?.page}
              pageLength={company?.pagination?.pageSize}
              totalRecords={company?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isCreateModalOpen && (
        <CreateCompanyModal companyDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
      )}
      {isUpdatePasswordModalOpen && (
        <UpdatePassword userDetail={sizeEditing} isOpen={isUpdatePasswordModalOpen} onClose={handelCloseModal} />
      )}
      {isAssignPermissionModalOpen && (
        <AssignPermission userDetail={sizeEditing} isOpen={isAssignPermissionModalOpen} onClose={handelCloseModal} refetch={refetch} />
      )}
    </Flex>
  );
}

export default Admin;
