import { Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, Input, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import { useExportHotlineTemplate, useQueryGetListHistorySms, useQueryGetListPhone } from 'services/ips';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import { Select } from 'chakra-react-select';
import { mappingOptionSelect } from 'utils/mapping';
import { useParams } from 'react-router-dom';
import { Roles } from 'constants/common';
import { useUserState } from 'context/UserContext';

const initFilter = {
  companyId: null,
  status: null,
  extension: null,
};

function HistorySms() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [campaign, setCampaign] = useState(null);
  const [hotlineFilter, setHotlineFilter] = useState(initFilter);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const exportHotlineTemplate = useExportHotlineTemplate({}, { responseType: 'arraybuffer' });
  const { id } = useParams();
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: hotlines, refetch } = useQueryGetListHistorySms(
    { ...filter, searchKeyword: filter.searchKeyword, campaign_id: id },
    { enabled: isLoggedIn && !!id }
  );
  const { data: campaigns } = useQueryGetListPhone({}, { enabled: isLoggedIn });

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      campaign_id: campaign?.value,
      ...(hotlineFilter?.companyId && { companyId: hotlineFilter?.companyId?.value }),
      ...(hotlineFilter?.status && { status: hotlineFilter?.status?.value }),
      ...(hotlineFilter?.extension && { extension: hotlineFilter?.extension?.label }),
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setHotlineFilter(initFilter);
    setSearchTitle('');
    setCampaign(null);
  };

  const onDownloadTemplate = () => {
    // exportHotlineTemplate.mutate(undefined, {
    //   onSuccess: response => {
    //     downloadFile(response, 'hotline-template');
    //     toast.showMessageSuccess('Tải dữ liệu hotline thành công');
    //   },
    //   onError: () => {
    //     toast.showMessageError('Tải dữ liệu hotline thất bại');
    //   },
    // });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử gửi sms
                </Text>
                {
                  userInfo?.role !== Roles.STAFF && (
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onDownloadTemplate}>
                    Xuất file excel
                  </Button>
                  )
                }
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex direction={'column'} gap={'20px'} flexWrap={'wrap'}>
                    <Flex flexWrap={'wrap'} gap={'20px'}>
                      <FormControl w={'300px'}>
                        <FormLabel>Tìm kiếm theo số điện thoại</FormLabel>
                        <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                      </FormControl>
                      {/* {userInfo?.role === Roles.SUPER_ADMIN && (
                        <>
                          <FormControl w={'300px'}>
                            <FormLabel>Chi nhánh</FormLabel>
                            <Select
                              isClearable
                              menuShouldBlockScroll
                              value={hotlineFilter?.companyId}
                              onChange={e => {
                                setHotlineFilter({
                                  ...hotlineFilter,
                                  companyId: e,
                                });
                              }}
                              options={mappingOptionSelect(company?.data, 'name', '_id')}
                            ></Select>
                          </FormControl>
                        </>
                      )}
                      {(userInfo?.role === Roles.ADMIN || userInfo?.role === Roles.SUPER_ADMIN) && (
                        <FormControl w={'300px'}>
                          <FormLabel>Extension</FormLabel>
                          <Select
                            isClearable
                            menuShouldBlockScroll
                            value={hotlineFilter?.extension}
                            onChange={e => {
                              setHotlineFilter({
                                ...hotlineFilter,
                                extension: e,
                              });
                            }}
                            options={mappingOptionSelect(extensions?.data, 'extension', 'extension_uuid')}
                          ></Select>
                        </FormControl>
                      )} */}
                      {/* <FormControl w={'300px'}>
                        <FormLabel>Nhiệm vụ gọi</FormLabel>
                        <Select
                          placeholder="Chọn nhiệm vụ gọi"
                          isClearable
                          menuShouldBlockScroll
                          value={campaign}
                          onChange={e => {
                            setCampaign(e);
                          }}
                          options={mappingOptionSelect(campaigns?.data, 'mission_name', '_id')}
                        ></Select>
                      </FormControl> */}
                      <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Cài lại
                        </Text>
                      </Button>
                      <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="normal" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={hotlines?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={hotlines?.pagination?.page}
              pageLength={hotlines?.pagination?.pageSize}
              totalRecords={hotlines?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HistorySms;
